import ThemeEnum from '@/enums/themeEnum';

const isDev = process.env.NODE_ENV === 'development';
/**
 * 系统性配置
 * */
export default class ProjectSetting {
  // AES对称加密接密-密钥
  static APP_AES_KEY = 'LhEU1';

  // logo图片配置
  static APP_IMAGE = '';

  // 开启加载页面，这里配置的是开发环境没得，方便开发
  static APP_LODING = !isDev;

  //app 名称
  static APP_NAME = 'BTCEX';

  // 是否使用后台配置的语言
  static APP_USE_LANG_FROM_NET = true;

  //默认币种 ,比如日本jpy ，美国ustd
  static APP_DEFAULT_CURRENCY = ' USDT ';

  //默认钱的符号
  static APP_DEFAULT_SYMBOL = '$ ';

  //年月日时分
  static APP_DATE_STRING2 = 'YYYY-MM-DD';

  // 时分
  static APP_TIME_STRING = 'HH:mm';

  // 默认主题
  static APP_DEFAULT_THEME = ThemeEnum.light;

  //默认语言 en | ja | zh
  static APP_DEFAULT_LOCALE = 'en';

  //默认时间格式
  static APP_DEFAULT_TIME_STRING = 'MMMM D, YYYY, h:mm:ss A';

  //默认时间格式
  // static APP_DEFAULT_TIME_STRING2 = 'MMMM D, YYYY, hh:mm:ss A';

  //默认日期
  static APP_DEFAULT_DATE_STRING = 'MM-DD';

  //年月日日期格式
  static APP_DATE_STRING = 'YYYY-MM-DD';

  // 是否开启utc 时间
  static APP_UTC_TIME = true;

  // 是否开启日本时间
  static APP_JP_TIME = false;

  // 是否开启logo
  static App_SHOW_LOGO = true;

  // 是否显示邮箱 登录注册
  static APP_IS_SHOW_EMAIL = true;

  //UTC-5 伦敦时间 k线时区
  // static chartTimezone = 'America/New_York';
  static chartTimezone = 'Europe/London';

  // ustd数字 的价格精度
  static usdAccuracyLength = 2;

  static isShowStock: boolean = isDev;
}
