import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import { useLocalStorage } from '@/hooks/useStorage';
import { getTradingSymbolsApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useEffect } from 'react';

export interface TradePair {
  transactionAccuracy: number;
  alias: string;
  baseId: string;
  baseSymbol: string;
  coin: string[];
  commission: number;
  commissionType: number;
  createTime: number;
  exchange: string;
  exchangeId: string;
  exchangeSymbol: string;
  id: string;
  imgUrl: string | null;
  name: string;
  priceAccuracy: number;
  remark: string | null;
  sort: number;
  startTime: number | null;
  status: number;
  symbol: string;
  symbolId: string;
  type: number;
  visible: number;
}

export default () => {
  const { sendMsg, isSub } = useModel('socket');
  //选中币种
  const [localStock, setLocalStock]: [coin: TradePair, setCoin: any] =
    useLocalStorage({
      key: CacheEnum.APP_LOCAL_STOCK,
      defaultValue: null,
    });

  // 异步获取合约缓存进本地
  const {
    data: stockListData = [] as any,
    run: getTheListOfCoins,
    loading: stockListLoading,
  } = useReq(getTradingSymbolsApi, {
    loadingDefault: false,
    manual: true,
    cacheKey: 'stockListData',
    formatResult: (res: any) => {
      const { list } = res?.data || [];
      return list ?? [];
    },
    onSuccess: (res: any) => {
      // 成功获取数据后，更新本地存储和发送订阅消息
    },
  });

  const pageKey = usePageRefreshOnVisible();

  useEffect(() => {
    // 初始化 订阅 和设置数据
    if (!localStock?.id && stockListData?.length) {
      sendMsg( 
        `{"type":"SUB","data": ${JSON.stringify(
          stockListData?.map?.((i: { symbol: any }) => i?.symbol),
        )}}`,
      );

      if (!localStock) {
        setLocalStock(stockListData[0]);
      } else {
        const finditem = stockListData.find?.(
          (i: any) => i?.id === localStock?.id,
        );
        if (!finditem) {
          setLocalStock(stockListData[0]);
        }
      }
    } else if (stockListData?.length) {
      const find = stockListData?.find?.(
        (item: any) => localStock?.id === item?.id,
      );
      if (find) {
        setLocalStock(find);
      } else {
        sendMsg(
          `{"type":"SUB","data": [${JSON.stringify(localStock?.symbol)}]}`,
        );
      }
    }
  }, [stockListData, pageKey, isSub]);

  // 获取用户信息
  const { user } = useModel('user');

  const { sortState, isHomeSort, coinSelected, sortType } = useModel('system');

  // 监听用户信息变化
  useEffect(() => {
    if ((user?.id && !stockListData.length) || !localStock) {
      getTheListOfCoins({
        pageNo: 1,
        pageSize: 15,
        type: GetCoinListTypeEnum.COIN,
      });
    }
  }, [user]);

  useUpdateEffect(() => {
    if (user?.id && isHomeSort && sortType === GetCoinListTypeEnum.STOCK) {
      getTheListOfCoins({
        pageNo: 1,
        pageSize: 15,
        plate: coinSelected,
        type: GetCoinListTypeEnum.STOCK,
        ...sortState,
      });
    }
  }, [isHomeSort]);

  useUpdateEffect(() => {
    if (user?.id && isHomeSort && sortType === GetCoinListTypeEnum.STOCK) {
      getTheListOfCoins({
        pageNo: 1,
        pageSize: 15,
        plate: coinSelected,
        type: GetCoinListTypeEnum.STOCK,
        ...sortState,
      });
    }
  }, [sortState, coinSelected]);

  return {
    stockListData,
    stockListLoading,
    getTheListOfCoins,
    localStock,
    setLocalStock,
  };
};
