import { isDev } from '@/models/socket';
import ProjectSetting from '@/setting/projectSetting';
import dayjs from 'dayjs';
import i18n from 'i18next';
import moment from 'moment';
require('dayjs/locale/de');
dayjs.locale('en'); // 设置语言环境为英语

const { t } = i18n;
dayjs.extend(require('dayjs/plugin/utc'));

export default class RenderUtil {
  // 去除指定字符串
  static removeSuffix(str: string, suffix = /\.(NS|BO)/g): string {
    if (!str) return '';
    const regex = new RegExp(suffix, 'gi');
    return str?.replace(regex, '');
  }
  // 自用版 toFixed ，确保不会导致四舍五入

  /**
   * 巴西 '-3000'
   * 日本 '+0900'
   * 韩国 '+0900'
   * 越南 '+0700'
   * 美国 '-0500'
   * 印度 '+0530'
   */
  static utcOffset: any = '-0400';

  static toFixed(
    num: number | string,
    digits: any,
    padZeroes: boolean = false,
  ): string {
    if (num === null || num === undefined) {
      return '';
    }
    let str = num.toString();

    try {
      if (typeof num === 'number' && num < 0.1) {
        str = num.toFixed(digits).toString();
      }
    } catch {
      str = num.toString();
    }

    const dotIndex = str.indexOf('.');
    if (dotIndex === -1) {
      // 没有小数点
      return digits > 0 && padZeroes ? `${str}.${'0'.repeat(digits)}` : str;
    }

    const decimal = str.substring(dotIndex + 1);
    if (decimal.length > digits) {
      str = str.substring(0, dotIndex + digits + 1);
    } else if (decimal.length < digits) {
      str = padZeroes ? str + '0'.repeat(digits - decimal.length) : str;
    }

    if (!padZeroes) {
      // 移除尾部多余的 0
      str = parseFloat(str).toString();
    }
    return str;
  }

  /**
   * 将数字转换为固定小数位数的字符串。
   * @param numStr 待转换的数字。
   * @param fixed 小数位数，默认为5。
   * @param dfValue 如果输入为null，返回的默认值。
   */
  static formatNumToString(numStr: any, fixed = 5, dfValue = '0'): string {
    if (numStr !== null && numStr !== undefined) {
      return this.toFixed(Number(numStr), fixed ?? 5);
    }
    return dfValue;
  }

  /**
   * 格式化数字到固定小数位数。
   * @param numStr 待格式化的数字。
   * @param fixed 小数位数，默认为5。
   * @param dfValue 如果输入为null，返回的默认值。
   */
  static formatNum(numStr: number | null, fixed = 5, dfValue = 0): number {
    if (numStr !== null && numStr !== undefined) {
      let res: any = Number(numStr) || 0;
      if (res !== 0 && fixed !== 0) {
        res = this.toFixed(Number(numStr), fixed);
      } else if (fixed === 0) {
        res = parseInt(numStr + '');
      }
      return res;
    }
    return dfValue;
  }

  static shortenNumber(num: any) {
    if (isNaN(num)) return '0';

    if (num >= 10000) {
      // 如果数字大于等于 10000，转为 "Xw" 格式，保留一位小数
      return (num / 10000).toFixed(1).replace(/\.0$/, '') + 'w';
    } else if (num >= 1000) {
      // 如果数字大于等于 1000，小于 10000，转为 "XK" 格式，保留一位小数
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else {
      // 否则直接返回原始数字
      return num.toString();
    }
  }

  /**
   * @name formatAndCeilToTwoDecimals
   * @description 格式化输入的值，保留两位小数并向上取整
   * @param {string | number} inputValue - 需要格式化的输入值
   * @returns {number} - 返回保留两位小数向上取整的数值
   */
  static formatAndCeilToTwoDecimals(inputValue: any) {
    if (inputValue === undefined || inputValue === null) return 0;
    if (Number(inputValue) < 0.01 && Number(inputValue) > 0) return '0.01';

    // 将字符串中的逗号去掉，处理小数点
    let sanitizedValue = inputValue.toString().replace(/,/g, '');

    // 解析为浮点数
    const parsedNumber = parseFloat(sanitizedValue);

    // 如果解析结果为 NaN，则返回 0
    if (isNaN(parsedNumber)) return 0;

    // 乘以100后取整，再除以100，保留两位小数的向上取整
    const result = Math.ceil(parsedNumber * 100) / 100;

    return result;
  }

  /**
   * 日期格式化为年月日字符串。
   * @param date 待格式化的日期。
   * @param format 日期格式，默认取AppConfig中的APP_DEFAULT_TIME_STRING。
   */
  static formatDate(date: any, format?: string): string {
    const localStorageDayjsOffset =
      window.localStorage.getItem('selectedTimezone');

    let formatStr = format ?? ProjectSetting.APP_DEFAULT_TIME_STRING;
    if (isDev) {
      // formatStr = 'YYYY/MM/DD HH:mm:ss';
    }
    // 日期不存在，则返回空
    if (!date) {
      return '';
    }
    let resDate = date;
    // 如果日期是10位数，则将其乘以1000
    if (date.toString().length === 10) {
      resDate *= 1000;
    }

    if (ProjectSetting.APP_UTC_TIME && localStorageDayjsOffset)
      // 使用指定的时区偏移量
      return dayjs(resDate)
        .locale('en')
        .utcOffset(localStorageDayjsOffset ?? RenderUtil.utcOffset)
        .format(formatStr);

    return dayjs(resDate).locale('en').format(formatStr);
  }

  /**
   * 格式化日期为仅包含日期和月份的字符串。
   * @param date 待格式化的日期。
   */
  static formatOnlyDate(date: any): string {
    if (!date) {
      return '';
    }
    if (ProjectSetting.APP_UTC_TIME)
      //   return dayjs(date).utc().format(ProjectSetting.APP_DEFAULT_DATE_STRING);
      return dayjs(date)
        .utcOffset(RenderUtil.utcOffset)
        .format(ProjectSetting.APP_DEFAULT_DATE_STRING);
    if (ProjectSetting.APP_JP_TIME)
      return dayjs(date)
        .utcOffset('+0900')
        .format(ProjectSetting.APP_DEFAULT_DATE_STRING);
    return dayjs(date).format(ProjectSetting.APP_DEFAULT_DATE_STRING);
  }

  static formatYearData(date: any): string {
    if (!date) {
      return '';
    }
    if (ProjectSetting.APP_UTC_TIME)
      return dayjs(date).utc().format(ProjectSetting.APP_DATE_STRING);
    if (ProjectSetting.APP_JP_TIME)
      return dayjs(date)
        .utcOffset('+0900')
        .format(ProjectSetting.APP_DATE_STRING);
    return dayjs(date).format(ProjectSetting.APP_DATE_STRING);
  }

  /**
   * 将时间戳转换为日期时间字符串。
   * @param timestamp 待转换的时间戳。
   */
  static formatTimeString(timestamp: string | number | Date | null) {
    if (timestamp === null) return '';
    return new Date(timestamp).toISOString().slice(0, 19).replace('T', ' ');
  }

  /**
   * 根据值的非空和非null条件渲染值，否则渲染默认值。
   * @param val 待检查的值。
   * @param dfValue 默认值。
   */
  static renderByNotNull(val: string | null, dfValue = '') {
    if (val !== null && val !== '') {
      return val.toString();
    }
    return dfValue;
  }

  /**
   * 添加两个数，考虑null值。
   * @param a 第一个数。
   * @param b 第二个数。
   */
  static add(a: any, b: any) {
    return (a || 0) + (b || 0);
  }

  /**
   * 减去两个数，考虑null值。
   * @param a 第一个数。
   * @param b 第二个数。
   */
  static sub(a: any, b: any) {
    return (a || 0) - (b || 0);
  }

  /**
   * 获取价格长度（用于格式化）。
   * @param value 待检查的值。
   */
  static getPriceLength(value: number) {
    if (value === 0) return 1;
    let absoluteValue = Math.abs(value);

    if (absoluteValue >= 1000) {
      return 2;
    } else if (absoluteValue >= 100) {
      return 3;
    } else if (absoluteValue >= 1) {
      return 4;
    }
    return 5;
  }

  /**
   * 获取数字小数点后的长度。
   * @param number 待检查的数字。
   */
  static getDecimalLength(number: string | number) {
    return RenderUtil.FormatAmount(number);
  }

  /**
   * 根据金额和小数位数动态格式化价格。
   * @param amount 待格式化的金额。
   * @param decimalPlaces 小数位数。
   */
  static getDynamicPrice(
    amount: any | null | undefined,
    decimalPlaces?: any,
    padZeroes: boolean = false,
  ) {
    return RenderUtil.FormatAmount(amount, decimalPlaces, padZeroes);
  }

  /**
   * 动态格式化价格为字符串。
   * @param value 待格式化的值。
   * @param fixed 固定的小数位数。
   */
  static getDynamicPriceString(
    value: any | null | undefined,
    fixed = null as any,
    padZeroes: boolean = true, //是否显示多余的0 ，默认需要
  ) {
    if (value === undefined || value === null || value === 0) return 0;
    // eslint-disable-next-line no-param-reassign
    value = Number(value);
    let formattedValue;
    if (fixed && typeof fixed === 'number') {
      formattedValue = this.toFixed(value, fixed, padZeroes);
    } else {
      formattedValue = this.toFixed(
        value,
        this.getDecimalLength(value),
        padZeroes,
      );
    }
    return (formattedValue ?? 0).toString();
  }

  /**
   * 根据指定字段去重数组对象。
   * @param arr 待去重的数组。
   * @param field 用于去重的字段。
   */
  static deduplicateByField(arr: any[], field: string | number) {
    const seen = new Map();
    return arr?.filter((item: { [x: string]: any }) => {
      if (!seen.has(item[field])) {
        seen.set(item[field], true);
        return true;
      }
      return false;
    });
  }

  /**
   * 转换时间戳为不同的时间字符串。
   * @param timestamp 待转换的时间戳。
   */
  static convertTimestamp(timestamp: number) {
    let currentDate = moment();
    let targetDate = moment(timestamp);

    if (targetDate.year() < currentDate.year()) {
      return targetDate.format('YYYY-MM-DD');
    } else if (timestamp > currentDate.valueOf()) {
      return targetDate.format('MM-DD');
    } else if (currentDate.diff(targetDate, 'minutes') < 30) {
      return t('刚刚');
    } else if (currentDate.diff(targetDate, 'hours') < 1) {
      return currentDate.diff(targetDate, 'minutes') + t('分钟前');
    } else if (currentDate.diff(targetDate, 'hours') < 24) {
      return currentDate.diff(targetDate, 'hours') + t('小时前');
    } else {
      return targetDate.format('MM-DD');
    }
  }

  /**
   * 获取AM/PM时间格式的字符串。
   * @param timestamp 待转换的时间戳。
   */
  static getAmPmTime(timestamp: string) {
    if (!timestamp) return '';
    const date = dayjs(timestamp);
    const hours = date.hour();
    const minutes = date.minute();

    let ampm = '';
    let formattedHours = hours;

    if (hours < 12) {
      ampm = t('上午');
    } else if (hours < 18) {
      ampm = t('中午');
    } else {
      ampm = t('下午');
      formattedHours = hours - 12;
    }

    return (
      ampm + ' ' + formattedHours + ':' + minutes.toString().padStart(2, '0')
    );
  }

  /**
   * 将数字转换为短形式，如千位用"k"，百万位用"M"表示。
   * @param number 待转换的数字。
   * @param decimalPlaces 小数位数。
   */
  static convertToShortForm(number: number, decimalPlaces: number) {
    let num = Number(number);
    if (num >= 1000000 && num < Number.MAX_VALUE) {
      let result = num / 1000000;
      return (
        Math.floor(result * Math.pow(10, decimalPlaces)) /
          Math.pow(10, decimalPlaces) +
        'M'
      );
    } else if (num >= 1000 && num < 1000000) {
      let result = num / 1000;
      return (
        Math.floor(result * Math.pow(10, decimalPlaces)) /
          Math.pow(10, decimalPlaces) +
        'k'
      );
    } else {
      return num.toString();
    }
  }
  /**
   * 格式化金额，支持科学计数法转正常数字，添加千位分隔符，保留指定小数位数。
   * @param amount 待格式化的金额。
   * @param decimalPlaces 小数位数，默认为2。
   * @param padZeroes 是否补充小数后面的0。
   */
  static FormatAmount(
    amount: number | string,
    decimalPlaces: number = 2,
    padZeroes: boolean = false,
  ): string | number {
    if (!amount || Number(amount) === 0) {
      return padZeroes ? Number(0).toFixed(decimalPlaces) : '0';
    }

    try {
      // 处理科学计数法表示的数值
      let numericAmount: number;
      if (typeof amount === 'string' && /e[-+]?\d+/i.test(amount)) {
        numericAmount = Number(amount);
      } else {
        numericAmount = Number(amount);
      }

      if (isNaN(numericAmount)) {
        return amount; // 如果转换失败，返回原值
      }

      // const fixedAmount: string = numericAmount.toFixed(decimalPlaces);
      const fixedAmount:string=this.toFixed(
        amount,
        decimalPlaces,
        padZeroes,
      );
      const [integerPart, decimalPart]: string[] = fixedAmount.split('.');
      const integerWithCommas: string = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      );

      let formattedString: string = integerWithCommas;
      if (decimalPart && (padZeroes || Number(decimalPart) > 0)) {
        formattedString += `.${decimalPart}`;
      }

      return formattedString;
    } catch {
      return amount;
    }
  }

  /**
   * 格式化字符串，保留前缀和后缀，中间用省略号表示。
   * @param str 待格式化的字符串。
   * @param prefixLength 前缀长度。
   * @param suffixLength 后缀长度。
   */
  static formatString(
    str: string,
    prefixLength: number,
    suffixLength: number,
  ): string {
    if (!str) return '';
    const totalLength = prefixLength + suffixLength;
    if (str.length >= totalLength) {
      const prefix = str.substring(0, prefixLength);
      const suffix = str.substring(str.length - suffixLength);
      return `${prefix}......${suffix}`;
    } else {
      return str;
    }
  }

  static formatToThousandSeparator(numString: any) {
    // 移除非数字字符，除了数字和小数点
    const rawNumber =
      (numString.toString() || '')?.replace(/[^0-9.]/g, '') ?? '';
    // 格式化为千分位
    return rawNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
