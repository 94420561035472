import PageEnum from '@/enums/pageEnum';
import { useStrignStorage } from '@/hooks/useStorage';
import { getCandlestickApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history, useModel } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum ChartTypeEnum {
  //币币
  COIN = '1',
  //合约
  CONTRACT = '2',
  //股票
  STOCK = '3',
  //自选
  FAVORITES = '4',

  // 快速合约
  FAST_CONTRACT = '5',
}

/**
 * K线图组件
 * 该组件用于提供K线图的相关数据和功能，包括时间分辨率选择、数据请求、数据刷新等功能。
 *
 * @returns 返回K线图组件的相关状态和函数，可用于驱动UI渲染和交互。
 */
export default function kline() {
  // 使用i18n钩子获取多语言支持
  const { t } = useTranslation();

  // 使用自定义useLocalStorage钩子存储K线数据
  const [klineData, setKlineData] = useState<any>([]);

  // 定义支持的时间分辨率列表
  const supported_resolutions = [
    '1', // 1 分钟
    '5', // 5 分钟
    '15', // 15 分钟
    '30', // 30 分钟
    '60', // 1 小时
    '2H', // 2 小时
    // '3H', // 3 小时
    '4H', // 4 小时
    '1D', // 1 天
    // '3D', // 3 天
    '1W', // 1 周
    '1M', // 1 月
    '12M', // 1 年
  ];

  // 将时间分辨率列表格式化为文本和值对
  const resolutionsArr = [
    { text: t('1分'), value: '1' },
    { text: t('5分'), value: '5' },
    { text: t('15分'), value: '15' },
    { text: t('30分'), value: '30' },
    { text: '1' + t('小时'), value: '60' },
    { text: '2' + t('小时'), value: '2H' },
    // { text: '3' + t('小时'), value: '3H' },
    { text: '4' + t('小时'), value: '4H' },
    { text: t('1天'), value: '1D' },
    { text: t('1周'), value: '1W' },
    { text: t('1月'), value: '1M' },
    // { text: t('1年'), value: '1Y' },
  ];

  // 使用 useState 管理当前选择的时间分辨率
  const [interval, setInterval] = useState<
    | '1'
    | '5'
    | '15'
    | '30'
    | '60'
    | '120'
    | '180'
    | '240'
    | '2H'
    | '3H'
    | '4H'
    | '1D'
    // | '3D'
    | '1W'
    | '1M'
    // | '1Y'
  >('30');

  // 管理数据请求的状态
  const [hasReq, setHasReq] = useState<any>(false);
  // 管理请求时间戳字符串
  const [timeString, setTimeString] = useState<any>('');

  // 使用自定义useReq钩子发起异步数据请求并管理请求状态
  const {
    runAsync: requestKlinInfo,
    loading,
    refresh,
  } = useReq(getCandlestickApi, {
    manual: true,
    staleTime: 5000,
    debounceWait: 0,
    onSuccess: (res: any, param) => {
      if (res?.data) {
        const arr = res?.data ?? [];
        if (arr?.length) {
          arr.sort((a: any, b: any) => a.t - b.t); // 对数据按时间排序
        }
        setHasReq(true); // 数据请求成功后标记为已请求
        setKlineData(arr); // 更新K线数据
      }
    },
  });

  // 刷新图表数据的方法
  const reloadChart = () => {
    setTimeString(new Date() + '');
  };

  // 管理图表类型的状态
  const [coinType, setCoinType] = useStrignStorage({
    key: 'app_coinType',
    defaultValue: ChartTypeEnum.CONTRACT,
  });

  // 使用useModel钩子获取股票模型的相关状态
  const { localContract, localFastContract }: any = useModel('contract');
  const { localCoin }: any = useModel('coin');
  const { localStock }: any = useModel('stock');

  // 使用useMemo钩子来优化图表信息的计算，减少不必要的重新渲染
  const LocalChartInfo: any = useMemo(() => {
    if (location.pathname === PageEnum.CONTRACT) return localContract;

    if (coinType === ChartTypeEnum.FAST_CONTRACT) {
      // 极速合约
      return localFastContract;
    }

    if (coinType === ChartTypeEnum.COIN) {
      // 币币
      return localCoin;
    }

    if (coinType === ChartTypeEnum.STOCK) {
      // 股票
      return localStock;
    }

    // 合约
    return localContract;
  }, [
    coinType,
    localContract?.symbol,
    localFastContract?.symbol,
    localCoin?.id,
    location.pathname,
    localContract,
    localCoin,
    localFastContract,
    localStock,
  ]);

  // 计算仓位

  // 跳转到图表页面的方法
  const toChart = (chartType: ChartTypeEnum) => {
    setCoinType(chartType);
    history.push(PageEnum.CHART);
  };

  // 跳转到交易图表页面的方法
  const toTradeChart = (chartType: ChartTypeEnum) => {
    setCoinType(chartType);
    history.push(PageEnum.CONTRACT);
  };

  return {
    interval,
    setInterval,
    klineData,
    setKlineData,
    requestKlinInfo,
    loading: hasReq ? false : loading,
    refresh,
    timeString,
    setTimeString,
    reloadChart,
    hasReq,
    supported_resolutions,
    resolutionsArr,
    setHasReq,
    toChart,
    LocalChartInfo,
    setCoinType,
    coinType,
    toTradeChart,
  };
}
