/**
 * 获取当前的环境变量
 * 无参数
 * @returns {string} 返回当前的环境变量值
 */
export function getEnv() {
  return process.env.NODE_ENV;
}

/**
 * 判断当前是否处于开发模式
 * 无参数
 * @returns {boolean} 如果当前环境为开发环境，则返回true，否则返回false
 */
export function isDevMode(): boolean {
  return getEnv() === 'development';
}
