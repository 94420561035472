import NetEnum from '@/enums/netEnum';
import { isDevMode } from '@/utils/env';

export default class HttpSetting {
  static API_URL = isDevMode() ? NetEnum.DEV_API_URL : NetEnum.PRO_API_URL;
  static WSS_URL = isDevMode() ? NetEnum.DEV_WSS_URL : NetEnum.PRO_WSS_URL;
  static IM_WSS_URL = isDevMode()
    ? NetEnum.DEV_IM_SOCKET_WSS_URL
    : NetEnum.PRO_IM_SOCKET_WSS_URL;
  static UPLOAD_URL =  isDevMode() ? NetEnum.UPLOAD_URL : NetEnum.PRO_UPLOAD_URL;
}
