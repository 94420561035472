import { BusEnums } from '@/enums/busEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import SocketEnum from '@/enums/socketEnum';
import { useLocalStorage, useStrignStorage } from '@/hooks/useStorage';
import { toast } from '@/layouts/components/ToastMessage';
import HttpSetting from '@/setting/httpSetting';
import eventBus from '@/utils/evevtBus';
import {
  toastOrderMsg,
  toastRechargeMsg,
  toastWithdrawalMsg,
  toJsonData,
  updateList,
} from '@/utils/socket';
import { history, useModel } from '@umijs/max';
import {
  useDocumentVisibility,
  useInterval,
  useRafInterval,
  useUnmount,
  useUpdateEffect,
  useWebSocket,
} from 'ahooks';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RenderUtil from '@/utils/RenderUtil';

export const isDev = process.env.NODE_ENV === 'development';

export type DataType = {
  a: number; //当前价格
  b: number; //卖价
  p: string; //符号
  pe: number; //日涨幅比
  s?: number; //市场
  t: number; //时间戳
  peA: number; //日涨幅
  d: number; //点差
  st: 1 | 0; //0|1 正常==1 闭市==0
};

// enum ReadyState {
//   Connecting = 0, //连接中
//   Open = 1, //打开
//   Closing = 2, //关闭
//   Closed = 3, //关闭中
// }

export type UserAmountType = {
  contractAmount: number; //合约
  contractAmountConvert: number;
  convertCoin: string;
  fundAmount: number; //资金 | 现货
  fundAmountConvert: number;
  riskRatio: number;
  totalAmount: number; //总资产
  totalAmountConvert: number;
};

// 定时发送心跳时间
const HEART_BEAT_INTERVAL = 2000;

// 定时检查心跳重连时间
const HEART_BEAT_RECONNECT_INTERVAL = 5000;

// 心跳超时时间，重联
const HEART_BEAT_TIMEOUT = 5000;

// 重连次数超过弹窗
export const MAX_RECONNECT_COUNT = 5;

/**
 * @returns socket 连接，返回，数据。格式化
 */
export default () => {
  // 当前重连次数（重连超过）
  const [reconnectCount, setReconnectCount] = useState(0);
  const { t } = useTranslation();
  const [socketId, setSocketId] = useState<any>('');
  // kline 全部数据
  const [marketList, setMarketList]: [DataType[], any] = useState([]);

  // soket 最新一次的数据
  const [socketNewData, setSocketNewData]: any = useState([]);

  // kline 全部数据
  const [marketListLocal, setLocalSMarketList]: [DataType[], any] =
    useLocalStorage({
      key: 'LocalSaveKey.MARKET_LIST_DATA', // 全部
      defaultValue: [],
    });
  // lastMarketListTime
  const [lastMarketListTime, setLastMarketListTime] = useState(0);
  // 每10秒同步一次数据
  useInterval(() => {
    setLocalSMarketList(marketList);
    setLastMarketListTime(new Date().getTime());
  }, 10000);

  // 首次缓存
  useUpdateEffect(() => {
    if (marketList?.length <= 0 || marketListLocal?.length > 0) return;
    setLocalSMarketList((prev: any) => {
      if (prev?.length > 0) {
        return prev;
      }
      return [...marketList];
    });
  }, [marketList]);

  // 订阅的数据（轮询查看这个行情是否一直没推，没有推送就重新订阅没有改变的值）
  const [depthList, setDepthList]: any = useLocalStorage({
    key: CacheEnum.SOCKET_ID_DEPTH_DATA, // 全部
    defaultValue: [],
  });

  const [coinDepthList, setCoinDepthList]: any = useLocalStorage({
    key: CacheEnum.SOCKET_COIN_DEPTH_DATA, // 全部
    defaultValue: [],
  });

  // 最后推送的时间戳
  const [lastDepthTime, setLastDepthTime] = useState(0);
  useUpdateEffect(() => {
    setLastDepthTime(new Date().getTime());
  }, [depthList]);
  // 最新成交的数据
  const [trading, setTrading] = useLocalStorage({
    key: CacheEnum.SOCKET_ID_TRADE_DATA, // 全部
    defaultValue: [],
  });
  // 存储版本信息
  const [version, setVersion] = useLocalStorage({
    key: CacheEnum.APP_VERSION_CACHE, // 版本缓存
    defaultValue: '',
  });
  // 是否弹窗询问刷新
  const [isRefresh, setIsRefresh] = useState(false);
  // 合约账户
  const [userSoketAmount, setUserAmount] = useState<UserAmountType | any>();
  // 合约强平
  const [contractForcedLiquidation, setContractForcedLiquidation] =
    useState(false);
  // 资金总览
  const [fundingOverviewSocket, setFundingOverviewSocket] = useState<
    UserAmountType | any
  >();

  const [orderInfo, setOrderInfo] = useState({
    available: 0,
    netWorthBigDecimal: 0,
    profit: 0,
    balance: 0,
    marginRatio: 0,
    occupyMargin: 0,
    riskRate: 0,
  });
  // 刷新用户信息
  const [refreshUserInfoKey, setRefreshUserInfoKey] = useState(0);

  const [order, setOrder]: any = useState([]);

  //K线 最新 的一条
  const [lastKlineList, setLastKlineList] = useState<any>([]);

  const getLastKline = (name: string) => {
    if (marketList.length) {
      const cur = lastKlineList
        ?.filter((i: any) => i.s === name)
        ?.reduce((max: any, item: any) => {
          if (!max.ts || item.ts > max.ts) {
            return item;
          }
          return max;
        }, {});

      if (cur && Object.keys(cur).length > 0) {
        return cur;
      }
    }
    return {};
  };

  // ticklines
  const [tickLines, setTickLines] = useLocalStorage({
    key: CacheEnum.APP_TICKLES_DATA,
    defaultValue: [],
  });
  // token
  const [token] = useStrignStorage({
    key: 'token',
    defaultValue: '',
  });
  // 未读数
  const [noReading, setNoReading] = useStrignStorage({
    key: CacheEnum.APP_UNREAD_MESSAGE_COUNT,
    defaultValue: '0',
  });
  //传入名称 ,获取摸一个币种的信息
  const getSocketRowByName = (name: string): DataType => {
    if (marketList.length) {
      const cur = marketList?.find((i: any) => i.p === name);
      if (cur) return cur;
    }

    if (marketListLocal?.length) {
      const cur = marketListLocal?.find((i: any) => i.p === name);
      if (cur) return cur;
    }

    return {
      a: 0,
      p: '',
      b: 0,
      pe: 0,
      t: 0,
      peA: 0,
      st: 1,
    } as unknown as DataType;
  };

  //传入名称 ,获取摸一个币种的交易信息
  const getSocketDepthByName = (name: string): any => {
    if (depthList.length) {
      const cur = depthList?.find((i: any) => i.p === name);
      if (cur) return cur;
    }
    return {
      a: [],
      b: [],
      p: '',
    };
  };

  //传入订单号,获取某已订单的信息
  const getSocketRowByOrderId = (id: number | string) => {
    if (!!id && order?.length) {
      const cur = order?.find((i: any) => i.id === id);
      if (cur) return cur;
    }
    return {};
  };

  const { reLoadOrder,setReReNameTitle,setReReNameText,setSuccessModalVisible } = useModel('system');
  // 资金相关socket数据
  const [fundingOverviewSocketMap, setFundingOverviewSocketMap] = useState({});
  // useLocalStorage({
  //   key: CacheEnum.APP_USER_AMOUNT,
  //   default: {},
  // });

  // 刷新用户账户信息
  const [refreshUserAccountInformation, setRefreshUserAccountInformation] =
    useState(0);
  // socket 连接更新账户余额
  const [updateUserAmount, setUpdateUserAmount] = useState(0);
  // 首页通知
  const [homeNotice, setHomeNotice] = useState({})
  const [fundingRate, setfundingRate] = useLocalStorage({
    key: 'CacheEnum.fundingRate', // 合约资金
    defaultValue: '',
  });

  //封装更新数据
  const updateAll = (data: any) => {
    // 首页通知
    if (data?.type === SocketEnum.APP_NOTICE) {
      if(data?.data){
          setHomeNotice(toJsonData(data?.data));
      }
    }
    // 实名拒绝
    if (data?.type === SocketEnum.REAL_NAME_REJECT) {
      setReReNameTitle(t('实名已被拒绝'))
      setReReNameText(toJsonData(data?.data)?.remark??('实名已被拒绝'))
      setSuccessModalVisible(true)
      // toast.info(
      //   toJsonData(data?.data)?.remark ? toJsonData(data?.data)?.remark : t('实名已被拒绝'),
      // );
      setRefreshUserInfoKey((pev: number) => pev + 1);
    }
    // 提现拒绝
    if (data?.type === SocketEnum.WITHDRAW_REJECT) {
      setReReNameTitle(t('提现审核不通过'))
      setReReNameText(toJsonData(data?.data)?.remark??('提现审核不通过'))
      setSuccessModalVisible(true)

      if (toJsonData(data?.data)?.remark) {
        // toast.info(
        //   toJsonData(data?.data)?.remark,
        // );
      }

    }
    // 刷新用户信息
    if (data?.type === SocketEnum.MEMBER_INFO_UPDATE) {
      setRefreshUserInfoKey((pev: number) => pev + 1);
    }
    // 资金相关
    if (data?.type === SocketEnum.NEW_ACCOUNT_STATISTICS) {
      if (data?.data) {
        setFundingOverviewSocketMap(cloneDeep(data.data));
      }
    }
    // 合约仓位资金费率
    if (data?.type === SocketEnum.FUNDING_RATE) {
      if (data?.data) {
        setfundingRate(cloneDeep(data.data));
      }
    }
    // 登录设备确认
    if (data?.type === SocketEnum.OTHER_LOGIN) {
      eventBus.emit(SocketEnum.OTHER_LOGIN, true);
    }
    // 设备登录【取消】
    if (data?.type === SocketEnum.CAN_LOGIN) {
      toast.success(
        t('您的账号已成功切换到本设备登录。其他设备已被强制登出。'),
      );
    }
    if (data?.type === SocketEnum.FORCE_LOGOUT) {
      // 清楚缓存 重置基本配置
      sessionStorage.clear();
      const i18nextLng = localStorage.getItem('i18nextLng');
      const LANGUAGE = localStorage.getItem('LANGUAGE');
      localStorage.clear();
      localStorage.setItem('i18nextLng', i18nextLng as any);
      localStorage.setItem('LANGUAGE', LANGUAGE as any);
      //
      // eventBus.emit("OTHER_LOGIN", SocketEnum.FORCE_LOGOUT)
      //
      toast.info(t('您的账号已在其他设备上被重新登录，本设备已被强制登出。'));
      //强制退出
      history.replace(PageEnum.LOGIN);

      // setTimeout(() => {
      //   window.location.reload();
      // }, 1);
    }
    //更新账户
    if (data?.type === SocketEnum.WALLET_UPDATE) {
      setUpdateUserAmount((prevState) => prevState + 1);
    }
    //行情全部
    if (data?.type === SocketEnum.EXCHANGE_RATE) {
      updateList(data, marketList, setMarketList, 'p');
    }
    if (data?.type === SocketEnum.EXCHANGE_DEPTH) {
      updateList(data, depthList, setDepthList, 'p');
    }

    if (data?.type === SocketEnum.EXCHANGE_CANDLESTICK) {
      updateList(data, lastKlineList, setLastKlineList, 's');
    }

    // 现货的 深度图
    if (data?.type === SocketEnum.EXCHANGE_COIN_DEPTH) {
      updateList(data, coinDepthList, setCoinDepthList, 'p');
    }

    //订单推送
    if (data?.type === SocketEnum.CONTRACT_ORDER) {
      updateList(data, order, setOrder, 'id');
    }
    //获取订单消息未读消息数量
    if (data?.type === SocketEnum.UNREAD_MESSAGE_SYSTEM_NOTICE) {
      setNoReading(data?.data);
    }
    // 账户退出登陆
    if (data?.type === SocketEnum.ACCOUNT_LOGOUT) {
      toast.success(
        t('退出登陆成功'),
      );
    }
    // 账户停用
    if (data?.type === SocketEnum.ACCOUNT_DELETE) {
      toast.info(
        t('您的账号交易功能已被限制，无法继续使用。如果需要帮助，请联系客服。'),
      );
    }
    // 后台 平仓，不用消息 ，刷新订单页面 就可以
    if (data?.type === SocketEnum.CONTRACT_BACKEND_CPMPLEAE) {
      // reLoadOrder();
    }
    // 充值审核通知
    if (data?.type === SocketEnum.RECHARGE_SYSTEM_NOTICE) {
      const jsonData = toJsonData(data?.data);
      if (jsonData) toastRechargeMsg(jsonData);
    }
    // vip审核通知
    if (data?.type === SocketEnum.VIP_AUDIT) {
      const jsonData = toJsonData(data?.data);
      if (jsonData) {
        setRefreshUserAccountInformation((prevState) => prevState + 1);
      }
    }
    // 提现审核通知
    if (data?.type === SocketEnum.WITHDRAWAL_SYSTEM_NOTICE) {
      const jsonData = toJsonData(data?.data);
      if (jsonData) toastWithdrawalMsg(jsonData);
    }
    // 内测弹窗
    if (data?.type === SocketEnum.INTERNAL_BETA_ORDER_EXPIRED) {
      const jsonData = toJsonData(data?.data);
      eventBus.emit('INTERNAL_BETA_ORDER_EXPIRED', jsonData);
    }
    // 体验金到期弹窗
    if (data?.type === SocketEnum.EXPERIENCE_FUND_EXPIRED) {
      const jsonData = toJsonData(data?.data);
      eventBus.emit(SocketEnum.EXPERIENCE_FUND_EXPIRED, jsonData);
    }
    //全仓合约订单强平
    if (data?.type === SocketEnum.CONTRACT_FORCED_LIQUIDATION) {
      reLoadOrder();
      const jsonData = toJsonData(data?.data);
      toastOrderMsg(SocketEnum.CONTRACT_FORCED_LIQUIDATION, jsonData);
      setContractForcedLiquidation(true);
    }

    // CONTRACT_LEAD_TRADING 合约带单跟单完成
    if (data?.type === SocketEnum.CONTRACT_LEAD_TRADING) {
      const jsonData = toJsonData(data?.data);
      if (jsonData) toastOrderMsg(SocketEnum.CONTRACT_LEAD_TRADING, jsonData);
    }

    // 合约带单平仓完成
    if (data?.type === SocketEnum.CONTRACT_LEAD_TRADING_COMPLETE) {
      const jsonData = toJsonData(data?.data);
      if (jsonData)
        toastOrderMsg(SocketEnum.CONTRACT_LEAD_TRADING_COMPLETE, jsonData);
    }

    //限价合约订单完成
    if (data?.type === SocketEnum.CONTRACT_LIMIT) {
      const jsonData = toJsonData(data?.data);
      reLoadOrder();
      if (jsonData) toastOrderMsg(SocketEnum.CONTRACT_LIMIT, jsonData);
    }

    //限价币币订单完成
    if (data?.type === SocketEnum.COIN_LIMIT) {
      reLoadOrder();
      const jsonData = toJsonData(data?.data);
      if (jsonData) toastOrderMsg(SocketEnum.COIN_LIMIT, jsonData);
    }

    // 逐仓合约订单强平
    if (data?.type === SocketEnum.ISOLATED_CONTRACT_FORCED_LIQUIDATION) {
      reLoadOrder();
      const jsonData = toJsonData(data?.data);
      if (jsonData)
        toastOrderMsg(
          SocketEnum.ISOLATED_CONTRACT_FORCED_LIQUIDATION,
          jsonData,
        );
      setContractForcedLiquidation(true);
    }

    //合约订单止盈止损完成
    if (data?.type === SocketEnum.CONTRACT_STOP_PROFIT_LOSS) {
      reLoadOrder();
      const jsonData = toJsonData(data?.data);
      if (jsonData)
        toastOrderMsg(SocketEnum.CONTRACT_STOP_PROFIT_LOSS, jsonData);
    }

    //合约订单止盈完成
    if (data?.type === SocketEnum.CONTRACT_STOP_PROFIT) {
      reLoadOrder();
      const jsonData = toJsonData(data?.data);
      if (jsonData) toastOrderMsg(SocketEnum.CONTRACT_STOP_PROFIT, jsonData);
    }

    //合约订单止损完成
    if (data?.type === SocketEnum.CONTRACT_STOP_LOSS) {
      reLoadOrder();
      const jsonData = toJsonData(data?.data);
      if (jsonData) toastOrderMsg(SocketEnum.CONTRACT_STOP_LOSS, jsonData);
    }

    // 后台消息
    if (data?.type === SocketEnum.ADMIN_ACTIVE) {
      return toast.info(data?.data);
    }

    if (data?.type === SocketEnum.CONTRACT_INFO) {
      //合约资金
      const jsonData = toJsonData(data?.data);
      if (jsonData) setUserAmount(jsonData);
    }

    if (data?.type === SocketEnum.ACCOUNT_INFO) {
      //资金总览
      const jsonData = toJsonData(data?.data);
      if (jsonData) setFundingOverviewSocket(jsonData);
    }

    if (data?.type === SocketEnum.REFRESH_INTERFACE) {
      //刷新页面
      // window.location.reload();
    }

    if (data?.type === SocketEnum.VERSION) {
      if (data?.data) {
        const versionData = toJsonData(data?.data);
        if (!version) {
          setVersion(versionData);
        } else {
          // 可忽略类型--直接跳过
          if (+versionData?.updateType === 0) return;
          setVersion(versionData);
          // 强制更新
          if (
            versionData?.id !== version?.id &&
            +versionData.updateType === 2
          ) {
            window.location.reload();
          }
          //弹窗更新
          if (
            versionData?.id !== version?.id &&
            +versionData.updateType === 1
          ) {
            setIsRefresh(true);
          }
        }
      }
    }

    // 强制退出
    // if (data?.type === SocketEnum.FORCE_LOGOUT) {
    //   console.log('SocketEnum 退出指令');

    //   //强制退出
    //   history.push(PageEnum.LOGIN);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1);
    // }
  };

  //是否已经订阅
  const [isSub, setIsSub] = useState<boolean>(false);

  useEffect(() => {
    setIsSub(false);
  }, [token]);

  //最后收到消息的时间
  const [lastMessageTime, setLastMessageTime] = useState(Date.now());

  // const SocketUrl = HttpSetting.WSS_URL;
  const SocketUrl =
    localStorage.getItem('window.soketUrl') || HttpSetting.WSS_URL;

  //连接核心代码
  const { readyState, disconnect, connect, sendMessage } = useWebSocket(
    SocketUrl + socketId + '&version=v1',
    {
      manual: true, //手动连接
      reconnectLimit: 9999,
      onOpen: (e) => {
        //重置重连次数
        setReconnectCount(0);
        //连接成功后面让 重新订阅下
        setIsSub(true);
      },
      onClose() {
        setIsSub(false);
      },
      onError() {
        setIsSub(false);
      },
      onMessage: async (msg) => {
        setLastMessageTime(Date.now()); // 更新收到消息的时间

        let data: any = {};
        const strArr = ['ping', 'close', 'pong', 'p'];


        if (typeof msg?.data === 'string' && !strArr.includes(msg?.data)) {
          try {
            data = JSON.parse(msg?.data);
            setSocketNewData({ ...data, time: Date.now() });
          } finally {
          }
        }
        setIsSub(true);

        //刷新token
        if (data?.type === 'REFRESH_TOKEN') {
          const { accessToken, refreshToken } = data?.data || {};
          if (accessToken) {
            localStorage.setItem(
              CacheEnum.AUTHORIZATION,
              JSON.stringify(accessToken),
            );
          }
          if (refreshToken) {
            localStorage.setItem(
              CacheEnum.REFRESHTOKEN,
              JSON.stringify(refreshToken),
            );
          }
        }

        //在token 失效的时候 ，直接断开连接
        if (data?.type === 'DISCONNECT') {
          disconnect(); // 断开连接
        } else if (data) {
          updateAll(data);
        }
      },
    },
  );

  /**
   * 为了防止 重复订阅，在这个加一个已经订阅的
   */
  const [, setSubList] = useState<any[]>([]);
  const lastSent = useRef<any>(null);
  const timeoutRef = useRef<any>(null);

  //发送连接
  const sendMsg: any = (p: any) => {
    try {
      if (sendMessage) {
        if (lastSent.current !== p) {
          setSubList((old: any) => [...old, p]);
          sendMessage(p);
          lastSent.current = p;

          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            lastSent.current = null;
          }, 2000);
        }
      }
    } catch (e) {
    }
  };

  // 轮询发送心跳
  useRafInterval(() => {
    try {
      if (sendMessage) {
        sendMessage('heartbeat');
      }
    } catch (e) {
    }
  }, HEART_BEAT_INTERVAL);
  // // 轮询检查最新收到消息
  useRafInterval(() => {
    // 当前时间和lastMessageTime时间做比较超过HEART_BEAT_TIMEOUT，做重连
    try {
      // 不要无限重连，超过5次就不再重连（直接弹窗）
      if (
        Date.now() - lastMessageTime > HEART_BEAT_TIMEOUT &&
        reconnectCount <= MAX_RECONNECT_COUNT && !!socketId
      ) {
        disconnect?.();
        // 重连次数加1
        setReconnectCount((prevState) => prevState + 1);
        connect?.();
      }
    } catch (e) {
    }
  }, HEART_BEAT_RECONNECT_INTERVAL);
  // 轮询检测是否有在推送
  // useRafInterval(() => {
  //   const currentTime = new Date().getTime();
  //   // 交易页面图
  //   const timeDiff = Math.abs(currentTime - lastDepthTime) / 1000;
  //   // 行情
  //   const timeDiff2 = Math.abs(currentTime - lastMarketListTime) / 1000;
  //   // 5s没推送，重新订阅
  //   if (readyState === 1) {
  //     // 交易页面图
  //     if (timeDiff > 5 && depthList?.length > 0) {
  //       sendMsg(
  //         `{"type":"SUB_DEPTH","data": ${JSON.stringify(
  //           depthList?.at?.(-1)?.p,
  //         )}}`,
  //       );
  //     }
  //     // 行情订阅
  //     if (timeDiff2 > 5 && marketList?.length > 0) {
  //       sendMsg(
  //         `{"type":"SUB","data": ${JSON.stringify(
  //           marketList?.map((i: { p: any }) => i?.p),
  //         )}}`,
  //       );
  //     }
  //   }
  // }, 1000);
  // 设置定时器检查消息间隔
  useEffect(() => {
    const checkMessageInterval = setInterval(() => {
      try {
        if (sendMessage) {
          sendMessage('ping');
        }
      } catch (e) {
      }

      if (Date.now() - lastMessageTime > 6000) {
        setIsSub(false);
      }
    }, 6000); // 每6秒检查一次

    return () => clearInterval(checkMessageInterval); // 清理定时器
  }, [lastMessageTime, readyState, disconnect, connect, sendMsg]);

  const documentVisibility: any = useDocumentVisibility();
  //监听用户离开app的事件
  useEffect(() => {
    if (documentVisibility === 'hidden') {
    }
    if (documentVisibility === 'visible' && isSub === false) {
      //用户回到app的时候 重新连接下soket
      try {
        if (readyState === 1 && disconnect) {
          disconnect(); // 断开连接
        }
        if (connect && socketId) {
          connect(); // 重新连接
        }
      } finally {
      }
    }
  }, [documentVisibility]);
  // 从新链接
  const newLink = () => {
    try {
      if (socketId) {
        connect(); // 重新连接
      }
    } finally {
    }
  };
  // 当 socketId 更改时，重新连接 WebSocket
  useEffect(() => {
    if (socketId) {
      try {
        if (readyState === 1) {
          if (disconnect) disconnect();
        }
        if (connect && socketId) connect();
      } catch (e) {
      }
    }
  }, [socketId]);

  //开始连接
  const connectWebSocket = (id: any) => {
    if (id && id !== socketId) {
      setSocketId(id);
    }
  };

  // 时间监听token发生变化，重连socket
  useEffect(() => {
    eventBus.on(BusEnums.RECONNECT_SOCKET, connectWebSocket);
    return () => {
      eventBus.off(BusEnums.RECONNECT_SOCKET, connectWebSocket);
    };
  }, []);

  useUnmount(() => {
    eventBus.off('OTHER_LOGIN');
    eventBus.off('INTERNAL_BETA_ORDER_EXPIRED');
  });

  //断开连接
  const onclose = () => {
    if (readyState === 1) {
      console.log('---执行断开连接');
      setSocketId('');
      try {
        if (disconnect) disconnect();
      } catch (e) {
      }

      setIsSub(false);
    }
  };

  return {
    marketList,
    tickLines,
    depthList,
    refreshUserInfoKey,
    setRefreshUserInfoKey,
    order, //订单对象
    connectWebSocket, //连接 socket方法
    getSocketRowByName,
    getSocketRowByOrderId,
    sendMsg,
    readyState,
    trading,
    onclose,
    setTrading,
    getLastKline,
    userSoketAmount,
    setUserAmount,
    setIsSub,
    setDepthList,
    orderInfo,
    setOrderInfo,
    getSocketDepthByName,
    socketId,
    isSub,
    noReading,
    setNoReading,
    isRefresh,
    setIsRefresh,
    fundingOverviewSocket,
    setFundingOverviewSocket,
    contractForcedLiquidation,
    setContractForcedLiquidation,
    lastKlineList,
    setSocketId,
    refreshUserAccountInformation,
    setRefreshUserAccountInformation,
    updateUserAmount,
    fundingOverviewSocketMap,
    setFundingOverviewSocketMap,
    socketNewData,
    newLink,
    version,
    reconnectCount,
    setReconnectCount,
    coinDepthList, // 现货深度图
    fundingRate,
    lastMessageTime,
    homeNotice
  };
};
