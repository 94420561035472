// im缓存获取过的头像
import { useLocalStorage } from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';
import { getUserInfoCustomerServiceApi } from '@/services/api/requestApi';
import { useState } from 'react';

export default () => {
  // 缓存后的列表
  const [cacheAvatarMap, setCacheAvatarMap] = useLocalStorage({
    key: CacheEnum.APP_AVATAR,
    defaultValue: {},
  });
  // 正在请求的id
  const [cacheAvatarIds, setCacheAvatarIds] = useState<string[]>([]);

  const getUserInfoCustomerServiceReq = (userMemberIds: string[], groupId: string) => {
    // 获取出没有在请求中的id
    const requestIds = userMemberIds.filter?.(id => !cacheAvatarIds.includes(id + groupId) && !cacheAvatarMap[id + groupId]);
    setCacheAvatarIds(prevState => {
      return [...new Set([...prevState, ...requestIds?.map(id => id + groupId)])];
    });
    if (requestIds.length > 0) {
      getUserInfoCustomerServiceApi?.({
        userMemberIds: requestIds,
        groupId,
      }).then(res => {
        setCacheAvatarMap((prevState: any) => {
          return {
            ...prevState,
            ...res.data.reduce((acc: any, cur: string) => {
              acc[cur.id + groupId] = cur;
              return acc;
            }, {}),
          };
        });

      }).finally(() => {
        setCacheAvatarIds(prevState => {
          return prevState.filter(id => !requestIds.includes(id));
        });
      });
    }
  };

  const getUserInfoCustomerService = (userMemberId: string, groupId: string) => {
    return cacheAvatarMap[userMemberId + groupId];
  };

  return {
    getUserInfoCustomerServiceReq,
    getUserInfoCustomerService,
  };
}