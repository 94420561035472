import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import { useLocalStorage } from '@/hooks/useStorage';
import {
  getTradingSymbolsApi,
  positionAndEntrustmentApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@umijs/max';
import { useDebounceFn, useUpdateEffect } from 'ahooks';
import { useEffect, useMemo } from 'react';

export default () => {
  // 使用本地存储钩子来管理股票信息
  const [localContract, setLocalContract] = useLocalStorage({
    key: CacheEnum.APP_LOCAL_CONTRACT,
    defaultValue: null,
  });

  // 使用本地存储钩子来管理股票信息
  const [localFastContract, setLocalFastContract] = useLocalStorage({
    key: CacheEnum.APP_FASTCONTRACT,
    defaultValue: null,
  });
  // 获取持仓或者委托中的币种id和对应的杠杆
  const { data: positionAndEntrustment, run: positionAndEntrustmentReq } =
    useReq(positionAndEntrustmentApi, {
      loadingDefault: false,
      manual: true,
      ready: false, //  本项目用不到，暂时注释
    });
  // 使用socket模型来发送消息
  const { sendMsg, isSub } = useModel('socket');
  const { sortState, isHomeSort, sortType } = useModel('system');
  const [contractList = [], setContractList] = useLocalStorage({
    key: CacheEnum.PAGE_HOME_CONTRACT_LIST,
    defaultValue: [],
  });
  // 异步获取合约缓存进本地
  const { runAsync: getContractList, loading: contractListLoading } = useReq(
    getTradingSymbolsApi,
    {
      loadingDefault: false,
      manual: true,
      formatResult: (res: any) => {
        const { list } = res?.data || [];
        return list ?? [];
      },
    },
  );

  const pageKey = usePageRefreshOnVisible();

  useEffect(() => {
    // 合约列表为空不执行
    if (!contractList?.length) return;
    // 订阅当前列表
    sendMsg(
      `{"type":"SUB","data": ${JSON.stringify(
        contractList?.map?.((i: { symbol: any }) => i?.symbol),
      )}}`,
    );
    // 如果没有选择过合约
    if (!localContract?.id) {
      setLocalContract(contractList?.[0]);
    } else {
      //  查找第一页的合约数据和本地选择的数据
      const find = contractList?.find?.(
        (item: any) => localContract?.id === item?.id,
      );
      // 如果有，则更新本地合约数据
      if (find) {
        setLocalContract?.(find);
      } else {
        getContractList({
          type: GetCoinListTypeEnum.CONTRACT,
          symbol: localContract?.symbol,
        }).then((res: any) => {
          setLocalContract(res?.[0] ?? {});
        });
        // 如果没有就重新订阅当前合约数据
        sendMsg(
          `{"type":"SUB","data": [${JSON.stringify(localContract?.symbol)}]}`,
        );
      }
    }
  }, [contractList, pageKey, isSub]);

  const updateContract = async (symbol: any) => {
    if (symbol) {
      const data: any = await getTradingSymbolsApi({
        // fuzzy:
        //   localContract?.symbol ?? localContract?.name ?? localContract?.alias,
        pageNo: 1,
        pageSize: 15,
        type: GetCoinListTypeEnum.CONTRACT,
        symbol: symbol,
      });
      if (data?.code === 0) {
        const newData = data?.data?.list?.[0];
        if (newData) {
          setLocalContract(newData);
        }
      }
    }
  };

  //极速合约
  const { data: fastContractList = [] as any, run: getFastContractList } =
    useReq(getTradingSymbolsApi, {
      manual: true,
      cacheKey: CacheEnum.PAGE_HOME_FASTCONTRACT,
      formatResult: (res: any) => {
        const { list } = res?.data || [];
        return list ?? [];
      },
      onSuccess: (res: any) => {
        // 成功获取数据后，更新本地存储和发送订阅消息
        if (res?.[0]) {
          if (!localContract) {
            setLocalFastContract(res[0]);
          }
          sendMsg(
            `{"type":"SUB","data": ${JSON.stringify(
              res?.map?.((i: { symbol: any }) => i?.symbol),
            )}}`,
          );
        }
      },
    });

  useEffect(() => {
    // 初始化 订阅 和设置数据
    if (!fastContractList?.id && fastContractList?.length) {
      sendMsg(
        `{"type":"SUB","data": ${JSON.stringify(
          fastContractList?.map?.((i: { symbol: any }) => i?.symbol),
        )}}`,
      );
      setLocalFastContract(fastContractList[0]);
    } else if (fastContractList?.length) {
      const find = fastContractList?.find?.(
        (item: any) => localContract?.id === item?.id,
      );
      if (find) {
        setLocalFastContract(find);
      } else {
        sendMsg(
          `{"type":"SUB","data": ${JSON.stringify(localContract?.symbol)}}`,
        );
      }
    }
  }, [fastContractList]);

  // 获取用户信息
  const { user } = useModel('user');
  // 监听用户信息变化
  const { run: fetchContractList } = useDebounceFn(
    async (params: any) => {
      const res = await getContractList(params);
      setContractList(res);
    },
    { wait: 1000 },
  );

  useEffect(() => {
    if (user?.id && !contractList?.length) {
      fetchContractList({
        pageNo: 1,
        pageSize: 15,
        type: GetCoinListTypeEnum.CONTRACT,
      });
    }
  }, [user, contractList?.length]);

  useUpdateEffect(() => {
    if (user?.id && isHomeSort && sortType === GetCoinListTypeEnum.CONTRACT) {
      fetchContractList({
        pageNo: 1,
        pageSize: 15,
        type: GetCoinListTypeEnum.CONTRACT,
        ...sortState,
      });
    }
  }, [user, isHomeSort, sortState]);

  // 小数长度，后台设置的精度
  const priceAccuracy = useMemo(() => {
    return localContract?.priceAccuracy ?? 6;
  }, [localContract]);

  const transactionAccuracy = useMemo(() => {
    return localContract?.transactionAccuracy ?? 4;
  }, [localContract]);

  return {
    localContract,
    setLocalContract,
    contractList,
    fastContractList,
    getFastContractList,
    localFastContract,
    setLocalFastContract,
    getContractList,
    contractListLoading,
    updateContract,
    positionAndEntrustment,
    positionAndEntrustmentReq,
    priceAccuracy,
    transactionAccuracy,
  };
};
