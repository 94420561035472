import { useState } from 'react';

export default ()=>{
  // NO-TRANSLATION
  const [defaultLanguageList] = useState([
    {
      code: 'ja',
      name: '日本語',
      avatar: (
        <img
          src={require('@/assets/flag/jp_lang.png')}
          alt=""
          width={25}
          style={{ marginRight: '8px' }}
        />
      ),
    },
    {
      code: 'zh',
      name: '繁体中文',
      avatar: (
        <img
          src={require('@/assets/flag/hk_lang.png')}
          alt=""
          width={25}
          style={{ marginRight: '8px' }}
        />
      ),
    },
    {
      code: 'en',
      name: 'English',
      avatar: (
        <img
          src={require('@/assets/flag/us_lang.png')}
          alt=""
          width={25}
          style={{ marginRight: '8px' }}
        />
      ),
    },
    {
      code: 'hi',
      name: 'हिंदी',
      avatar: (
        <img
          src={require('@/assets/flag/in_lang.png')}
          alt=""
          width={25}
          style={{ marginRight: '8px' }}
        />
      ),
    },
    {
      code: 'ko',
      name: '한국인',
      avatar: (
        <img
          src={require('@/assets/flag/ko_lang.png')}
          alt=""
          width={25}
          style={{ marginRight: '8px' }}
        />
      ),
    },
  ])
  return {
    defaultLanguageList
  }
}