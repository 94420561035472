import {useState} from 'react';

export default () => {
    //控制底部导航是否隐藏
    const [isHideBottomNav, setIsHideBottomNav] = useState(false);

    return {
        isHideBottomNav,
        setIsHideBottomNav,
    };
};
