import Decimal from 'decimal.js';
import RenderUtil from './RenderUtil';

/**
 * 计算盈亏比例
 * @param {number} marketPrice - 行情价格
 * @param {number} openPrice - 开仓价格
 * @param {number} leverage - 杠杆倍数
 * @param {number} lotSize - 一手的数量
 * @param {boolean} isLong - 是否为做多，true 为做多，false 为做空
 * @returns {number} 盈亏比例，单位为小数
 */
// export function calculateProfitLossRatio({
//   marketPrice,
//   openPrice,
//   leverage = 1,
//   lotSize = 1,
//   isLong = true,
// }: {
//   marketPrice: number;
//   openPrice: number;
//   leverage: number;
//   lotSize: number;
//   isLong: boolean;
// }) {
//   if (openPrice <= 0 || lotSize <= 0) {
//     console.log('开仓价格、保证金和杠杆倍数必须大于零', '');
//     return 0;
//   }

//   // 计算基础盈亏比例
//   let ratio = ((marketPrice - openPrice) * leverage) / (openPrice * lotSize);

//   // 做空时取反
//   if (!isLong) {
//     ratio = -ratio;
//   }

//   if (isNaN(ratio)) return 0;

//   return RenderUtil.formatNum(ratio * 100, 2);
// }

// 计算盈亏比例 Decimal 版本
export function calculateProfitLossRatio({
  marketPrice = 0,
  openPrice = 0,
  leverage = 1,
  lotSize = 1,
  isLong = true,
}: {
  marketPrice: number;
  openPrice: number;
  leverage: number;
  lotSize: number;
  isLong: boolean;
}) {
  // 转为 Decimal 类型
  const dMarketPrice = new Decimal(marketPrice || 0);
  const dOpenPrice = new Decimal(openPrice || 0);
  const dLeverage = new Decimal(leverage || 1);
  const dLotSize = new Decimal(lotSize || 1);

  // 检查是否大于0
  if (dOpenPrice.lte(0) || dLotSize.lte(0)) {
    console.log('开仓价格、保证金和杠杆倍数必须大于零', '');
    return 0;
  }

  // 计算盈亏比例 = ((现价 - 开仓价) * 杠杆) / (开仓价 * 手数)
  let ratio = dMarketPrice
    .minus(dOpenPrice)
    .times(dLeverage)
    .div(dOpenPrice.times(dLotSize));

  // 做空时取反
  if (!isLong) {
    ratio = ratio.negated();
  }

  // isNaN 检查可改成 !ratio.isFinite()
  if (!ratio.isFinite()) {
    return 0;
  }

  // 乘以100变为百分比
  const ratioTimes100 = ratio.times(100);
  // 调用自定义格式化方法，这里假设 RenderUtil.formatNum(value, decimalPlaces)
  return RenderUtil.formatNum(ratioTimes100.toNumber(), 2);
}

/**
 * 计算盈亏
 * @param {number} marketPrice - 行情价格
 * @param {number} openPrice - 开仓价格
 * @param {number} margin - 保证金
 * @param {number} leverage - 杠杆倍数
 * @param {boolean} isLong - 是否为做多，true 为做多，false 为做空
 * @returns {number} 盈亏金额
 */

// export function calculateProfitLoss({
//   marketPrice,
//   openPrice,
//   margin,
//   leverage = 1,
//   isLong,
// }: {
//   marketPrice: number;
//   openPrice: number;
//   margin: number;
//   leverage: number;
//   isLong: boolean;
// }) {
//   if (openPrice <= 0 || margin <= 0 || leverage <= 0) {
//     console.log('开仓价格、保证金和杠杆倍数必须大于零', '');
//     return 0;
//   }

//   // 计算基础盈亏金额
//   let profitLoss =
//     ((marketPrice - openPrice) / openPrice) * (margin * leverage);

//   // 做空时取反
//   if (!isLong) {
//     profitLoss = -profitLoss;
//   }

//   if (isNaN(profitLoss)) return 0;

//   return profitLoss;
// }

// 计算盈亏 Decimal 版本
export function calculateProfitLoss({
  marketPrice = 0,
  openPrice = 0,
  margin = 0,
  leverage = 1,
  isLong,
}: {
  marketPrice: number;
  openPrice: number;
  margin: number;
  leverage: number;
  isLong: boolean;
}) {
  // 转换为 Decimal 以便进行精确计算
  const dMarketPrice = new Decimal(marketPrice || 0);
  const dOpenPrice = new Decimal(openPrice || 0);
  const dMargin = new Decimal(margin || 0);
  const dLeverage = new Decimal(leverage || 1);

  // 检查数值是否符合条件
  if (dOpenPrice.lte(0) || dMargin.lte(0) || dLeverage.lte(0)) {
    console.log('开仓价格、保证金和杠杆倍数必须大于零', '');
    return 0; // 返回普通 number
  }

  // 计算基础盈亏金额 = [(当前价格 - 开仓价格) / 开仓价格] * (保证金 * 杠杆)
  let profitLoss = dMarketPrice
    .minus(dOpenPrice)
    .div(dOpenPrice)
    .times(dMargin.times(dLeverage));

  // 如果是做空，就取反
  if (!isLong) {
    profitLoss = profitLoss.negated();
  }

  // 检查是否为有效数值
  if (!profitLoss.isFinite()) {
    return 0;
  }

  // 将 decimal.js 对象转换为普通数值
  return profitLoss.toNumber();
}
