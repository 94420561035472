import CacheEnum from '@/enums/cacheEnum';
import useDefaultLanguage from '@/hooks/useDefaultLanguage';
import i18n, { defaultLocale } from '@/locales';
import enTranslation from '@/locales/en.json';
import { getLangJsonApi, getLangListApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import ProjectSetting from '@/setting/projectSetting';
import { decodeHTMLEntitiesOptimized } from '@/utils';
import { useLocalStorageState, useMount } from 'ahooks';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useModel } from '@umijs/max';

/**
 * 该函数主要负责管理应用的多语言支持，包括：
 * 1. 初始化和管理当前语言状态。
 * 2. 从后台获取语言列表和对应语言的配置数据。
 * 3. 提供设置应用语言的函数。
 * 4. 为TradingView图表库提供语言设置。
 * 5. 在组件挂载时根据配置自动调整应用语言。
 *
 * @returns 返回一个对象，包含设置应用语言的函数、当前语言、设置语言的函数、图表语言和语言列表。
 */
export default () => {
  // 前端默认语言列表
  const { defaultLanguageList } = useDefaultLanguage();
  const { sendMsg, isSub } = useModel('socket');
  // 使用本地存储获取或设置当前语言
  const [language, setLanguage] = useLocalStorageState(CacheEnum.I18LANGUAGE, {
    defaultValue: defaultLocale,
  });
  // 使用自定义hook从后台获取指定语言的配置数据
  const { loading, runAsync: getSysJsonReq } = useReq(getLangJsonApi, {
    cacheKey: CacheEnum.APP_ARRANGEMENT_I18N,
    manual: true,
    // 处理后台返回的语言配置数据
    formatResult: (res: any) => {
      return res?.data?.translation ?? {};
    },
  });

  // 使用本地存储获取或设置语言配置数据
  const [languageJson, setLanguageJson] = useLocalStorageState<any>(
    CacheEnum.APP_ARRANGEMENT_I18N,
    {
      defaultValue: false,
    },
  );

  /**
   * 遍历对象并对值进行解码
   */
  function decodeTranslations(
    translations: Record<string, string>,
  ): Record<string, string> {
    const decoded: Record<string, string> = {};
    for (const key in translations) {
      if (translations.hasOwnProperty(key)) {
        const value = translations[key];
        decoded[key] = decodeHTMLEntitiesOptimized(value); // 直接处理字符串
      }
    }
    return decoded;
  }

  /**
   * 设置应用语言。
   * @param lang 要设置的语言代码。
   */
  const languageModules: { [key: string]: () => Promise<any> } = {
    'zh-TW': () => import('@/locales/zh-TW.json'),
    'zh-CN': () => import('@/locales/zh-TW.json'),
    ja: () => import('@/locales/ja.json'),
    en: () => import('@/locales/en.json'),
    fr: () => import('@/locales/fr.json'),
    es: () => import('@/locales/es.json'),
    hi: () => import('@/locales/hi.json'),
    it: () => import('@/locales/it.json'),
    pt: () => import('@/locales/pt.json'),
    th: () => import('@/locales/th.json'),
    ar: () => import('@/locales/ar.json'),
    nl: () => import('@/locales/nl.json'),
    da: () => import('@/locales/da.json'),
    de: () => import('@/locales/de.json'),
    sv: () => import('@/locales/sv.json'),
    fi: () => import('@/locales/fi.json'),
    no: () => import('@/locales/no.json'),
    ga: () => import('@/locales/ga.json'),
  };

  const setAppLanguage = async (lang: string) => {
    if (!lang) return;
    i18n.changeLanguage(lang);
    setLanguage(lang)
    if (isSub) {
      sendMsg(
        JSON.stringify({
          type: 'language',
          data: lang,
        }),
      );
    };

    if (ProjectSetting.APP_USE_LANG_FROM_NET) {
      const translations: any = await getSysJsonReq({ typeCode: lang });
      const decodedTranslations = decodeTranslations(translations) || {};
      if (decodedTranslations && Object.keys(decodedTranslations)?.length) {
        try {
          // 动态导入语言文件
          const languageTranslation = await languageModules[lang]?.();


          const resultTranslation = languageTranslation
            ? { ...languageTranslation?.translation, ...decodedTranslations }
            : {
                ...decodedTranslations,
                ...cloneDeep(enTranslation?.translation),
              };


          // 优先使用后台返回的翻译
          for (const key in resultTranslation) {
            if (
              i18n.exists(key) &&
              i18n.t(key) !== resultTranslation[key] &&
              decodedTranslations[key]
            ) {
              resultTranslation[key] = decodedTranslations[key];
            }
          }

          if (lang.includes('zh') || lang.includes('cn')) {
            // 如果是 中文，回滚语言设置为 fallbackLng
            i18n.options.fallbackLng = lang;
          } else {
            // 更新语言设置
            i18n.options.fallbackLng = 'en';
          }
          setLanguageJson(resultTranslation); //缓存下
          i18n.addResourceBundle(
            lang,
            'translation',
            resultTranslation,
            true,
            true,
          );
          i18n.changeLanguage(lang);
          window.FlutterI18n?.postMessage(lang);
        } catch (error) {
          console.error(`Failed to load language module for ${lang}`, error);
        }
      }
    }
  };

  // 使用自定义hook从后台获取语言列表
  const {
    data: languageList = [] as any,
    runAsync: getLocalesReq,
    loading: languageListLoading,
  } = useReq(getLangListApi, {
    manual: true,
    cacheKey: CacheEnum.APP_ARRANGEMENT_LANGUAGE,
    // 处理后台返回的语言列表数据
    formatResult: (res: any = []) => {
      const data = res?.data || [];
      return data ?? [];
    },
    onSuccess: (res: any) => {
      // 成功获取数据后的操作
      // 如果语言列表中没有当前语言，则设置默认语言
      if (!res.some((item: any) => item.code === language)) {
        setAppLanguage(ProjectSetting.APP_DEFAULT_LOCALE);
      }
    },
  });

  // 根据当前语言选择TradingView图表库的语言
  const chartlanguage = useMemo(() => {
    // if (language?.includes('zh') || language?.includes('cn')) return 'zh_TW';
    // if (language === 'ja') return 'ja';
    // if (language === 'hi') return 'en';
    return 'en';
  }, [language]);

  // 组件挂载时的操作，主要用于初始化语言设置
  useMount(() => {
    localStorage.removeItem('APP_ARRANGEMENT_LANGUAGE_INVESCO');
    localStorage.removeItem('APP_ARRANGEMENT_LANGUAGE_HFC');
    localStorage.removeItem('APP_ARRANGEMENT_LANGUAGE');
    localStorage.removeItem('APP_ARRANGEMENT_I18N');

    if (ProjectSetting.APP_USE_LANG_FROM_NET) {
      // 根据配置决定是否从后台获取语言列表和语言配置数据
      getLocalesReq();
      if (language && languageJson) {
        i18n.addResourceBundle(language, 'translation', languageJson);
        i18n.changeLanguage(language);
      }
    } else {
      i18n.changeLanguage(language);
    }

    // 处理未设置语言或设置为默认语言的情况
    if (!language || language === '' || language === 'undefined') {
      setAppLanguage(defaultLocale);
    } else if (language) {
      setAppLanguage(language);
    }
  });

  return {
    setAppLanguage,
    language,
    setLanguage,
    chartlanguage,
    languageList: ProjectSetting.APP_USE_LANG_FROM_NET
      ? languageList
      : defaultLanguageList,
    getLocalesReq,
    loading: languageListLoading,
    languageJson,
  };
};
