import { useLocalStorage } from '@/hooks/useStorage';
import { getAreaCodeApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useMount } from 'ahooks';
import { useState } from 'react';

export default () => {
  //当前区号
  const [currentAreaCode, setCurrentAreaCode] = useLocalStorage({
    key: 'currentAreaCode',
    defaultValue: null,
  })
  //获取区号列表
  const { data: areaCode, run: getAreaCodeReq } = useReq(getAreaCodeApi, {
    manual: true,

    onSuccess(data: any) {
      if (!currentAreaCode|| (currentAreaCode&&!data?.filter?.((item: any) => +item?.areaCode === +currentAreaCode?.areaCode)?.length)) {
        setCurrentAreaCode(data?.find?.((item: any) => +item?.isDefault === 1));
      }
    },
  });
  useMount(() => {
    getAreaCodeReq();
  });

  return {
    areaCode,
    currentAreaCode,
    setCurrentAreaCode,
  };
};
