//资金记录tab枚举
export enum CompletedOrderOrderProcessEnum {
  //全部
  ALL = '3',
  //充值
  ADD = '1',
  //提现
  SUB = '2',
  //闪兑
  SWAP = '4',
  //划转
  TRANSFER = '5',
}

export enum VerifiedStatesEnum {
  //未实名
  UNREAL = 0,
  //审核中
  UNDER = 1,
  //已实名
  REAL = 2,

}
/**
 * 当前账户类型
 **/
export enum CurrentAccountTypeEnum {
  /**
   * 真实用户
   **/
  REAL_USER = 2,
  /**
   * 匿名用户
   **/
  ANONYMOUS_USER = 1,
}


// 理财列表
export enum FINANCIAL_MANAGEMENT_LIST_ENUM {
  // 量化理财
  QUANTIFICATION_FINANCIAL_MANAGEMENT = '0',
  // 定期理财
  REGULAR_FINANCIAL_MANAGEMENT = '1',
}

// 资金记录类型枚举
export enum ConfuseCompletedOrderOrderTypeEnum {
  //
  CONFUSE = 'CONFUSE',
  //充值
  ADD = 'ADD',
  //提现
  SUB = 'SUB',
  //闪兑
  SWAP = 'SWAP',
  //划转
  TRANSFER = 'TRANSFER',
}

/**
 * 发送验证码用途
 **/
export enum VerifiedCodeEnum {
  // 注册验证码
  REGISTER = 1,
  // 重置密码
  RESET_PASSWORD = 2,
  // 更新资料
  UPDATE_PROFILE = 3,
}

/**
 * 充值类型
 **/
export enum RechargeTypeEnum {
  // 银行卡充值
  BANK_CARD = 0,
  // C2C
  C2C = 3,
  // 虚拟币
  VIRTUAL_CURRENCY = 4,
}

/**
 * 手续费类型
 **/
export enum FeeTypeEnum {
  // 固定手续费
  FIXED = '1',
  // 比例手续费
  RATIO = '2',
}

/**
 * 发送验证码类型（手机号，邮箱）
 **/
export enum VerifiedCodeTypeEnum {
  // 手机号
  PHONE = 1,
  // 邮箱
  EMAIL = 2,
}

/**
 * 股票的列表类型
 **/
export enum StockListTypeEnum {
  /**
   * 普通股票
   **/
  COMMON_STOCK = '1',
  /**
   * 申购股票
   **/
  IPO = '2',
  /**
   * 大宗股票
   **/
  BULK_STOCKS = '3',
  /**
   * IEO
   **/
  IEO = '6',
  /**
   * 配售
   **/
  DISTRIBUTION = '7',
}

//买入卖出状态
export enum CompletedOrderOrderTypeEnum {
  //买入
  BUY = '0',
  //卖出
  SELL = '1',
}

//审核中和已完成
export enum CompletedOrderOrderStatusEnum {
  //审核中
  UnderReview = '0',
  //未通过
  DID_NOT_PASS = '-1',
  //已完成
  COMPLETED = '1',
  // 等待审核中
  WAITING_FOR_REVIEW = '2',
  // 已经取消
  CANCELLED = '-2',
}

export enum FundsOrderHistoryEnum {
  /**
   * 贷款
   *
   * */
  LOAN = 502,
  /**
   * 还款
   *
   * */
  REPAYMENT = 503,
  /**
   * 提现
   *
   * */
  CONTRACT_WITHDRAWAL = 106,
  /**
   * 提现失败
   *
   * */
  CONTRACT_WITHDRAWAL_FAIL = 107,
  /**
   * 充值
   *
   * */
  RECHARGE_CONTRACT_ACCOUNT = 108,
  /**
   * 银行卡充值
   *
   * */
  RECHARGE_BANK_ACCOUNT = 144,
  /**
   * 加密货币充值
   *
   * */
  RECHARGE_CRYPTOCURRENCY = 145,
  // 币币充值
  RECHARGE_COIN_ACCOUNT = 105,
  /**
   * 管理端充值
   *
   * */
  RECHARGE_MANAGER = 1,
  /**
   * 闪兑
   * */
  ORDER_FLASH_EXCHANGE = 26,
  /**
   * 合约订单交易交易
   *
   * */
  ORDER_CONTRACT = 5,
  /**
   * 划转
   * */
  CONTRACT_TRANSFER = 3,
  /**
   * 提现
   * */
  PAYOUTS = 103,
  /**
   * 取消提现
   * */
  CANCEL_WITHDRAWAL = 1062,
  /**
   * 取消提现手续费
   * */
  CANCEL_WITHDRAWAL_FEE = 1063,
  /**
   * 币币订单交易 限价
   * */
  CONTRACT_ORDER_TRADE_PRICE_LIMIT = 24,
  /**
   * 币币订单交易 市价
   * */
  CONTRACT_ORDER_TRADE = 4,
  /**
   * 赠送体验金
   */
  GIVE_AWAY = 1249,
  /**
   * 归还体验金
   */
  EXPERIENCE_FUND_REFUND = 1250,
  /**
   * 归还体验金利润 利润扣除
   */
  EXPERIENCE_FUND_PROFIT_DEDUCTION = 1251,
  /**
   * 理财服务费
   */
  BANKING_FEES = 312,
  /**
   * 定期理财交易
   */
  REGULAR_FINANCIAL_MANAGEMENT = 2180,
  /**
   * AI量化交易
   */
  AI_QUANTITATIVE_TRADING = 2200,
  /**
   * AI量化交易手续费(手续费)
   */
  TRADE_AI_QUANT = 2201,
  /**
   * ai
   **/
  AI_ORDER_TRADE = 12,
  /**
   * 内测赠送
   */
  FREE_FOR_INTERNAL_TESTING = 1200,
  /**
   * 内测赠送资金退回
   */
  INTERNAL_BETA_FREE_FUNDS_REFUND = 1201,
  /**
   * 合约交易-平仓手续费
   **/
  CONTRACT_CLOSING_FEE = 300,
  /**
   * 合约交易-市价购买
   **/
  CONTRACT_MARKET_BUY = 301,
  /**
   * 合约交易-限价购买
   **/
  CONTRACT_LIMIT_BUY = 302,
  /**
   * 合约交易-取消委托
   **/
  CONTRACT_REVOKE = 303,
  /**
   * 合约交易-平仓结算
   **/
  CONTRACT_CLOSING_SETTLEMENT = 304,
  /**
   * 合约交易-平仓扣除
   **/
  CONTR = 305,

  /**
   * CONTRACT_FORCED_LIQUIDATION 合约交易-全仓强平
   */
  CONTRACT_FORCED_LIQUIDATION = 306,
  /**
   * CONTRACT_ISOLATED_FORCED_LIQUIDATION 合约交易-逐仓强平
   */
  CONTRACT_ISOLATED_FORCED_LIQUIDATION = 307,
  /**
   * CONTRACT_LIMIT 合约交易-限价
   */
  CONTRACT_LIMIT = 308,
  /**
   * CONTRACT_STOP_PROFIT_LOSS 合约交易-止盈止损
   */
  CONTRACT_STOP_PROFIT_LOSS = 309,
  /**
   * 邀请返佣
   */
  INVITATION_REBATE = 500,
  /**
   * 邀请返现
   */
  INVITATION_REBATE_CASH = 501,
  /**
   * 分销返佣
   */
  DISTRIBUTION_REBATE = 504,
  /**
   * 分销返现
   */
  DISTRIBUTION_REBATE_CASH = 505,
  /**
   * CONTRACT_LEAD_TRADING 合约交易-跟单订单完成
   */
  CONTRACT_LEAD_TRADING = 310,
  /**
   * CONTRACT_LEAD_TRADING_COMPLETE 合约交易-跟单订单平仓
   */
  CONTRACT_LEAD_TRADING_COMPLETE = 311,
  /**
   * CONTRACT_LEAD_TRADING_COMPLETE 申购IEO购买
   */
  SUBSCRIPTION_IEO_BUY = 710,
  /**
   * CONTRACT_LEAD_TRADING_COMPLETE 申购IEO补缴
   */
  SUBSCRIPTION_IEO_BUY_PAY_BACK = 711,

  /**
   * CONTRACT_LEAD_TRADING_COMPLETE 申购IEO退款
   */
  SUBSCRIPTION_IEO_REIMBURSE = 712,

  /**
   * SUBSCRIPTION_IEO_SETTLEMENT 申购IEO结算
   */
  SUBSCRIPTION_IEO_SETTLEMENT = 713,

  /**
   * FAST_CONTRACT_PURCHASE 极速合约购买
   */
  FAST_CONTRACT_PURCHASE = 1000,
  /**
   * FAST_CONTRACT_SETTLEMENT 极速合约结算
   */
  FAST_CONTRACT_SETTLEMENT = 1001,
  // 资金费率 合约
  FUNDRATE = 1100,
}

//资金记录来源分类
export enum FundsSourceEnum {
  /**
   * 账户操作
   *
   * */
  ACCOUNT_OPERATION = 1,
  /**
   * 股票
   *
   * */
  STOCK_OPERATION = 2,
  /**
   * 合约
   *
   * */
  CONTRACT_OPERATION = 3,
  /**
   * 现货
   *
   * */
  BINANCE_OPERATION = 4,
  /**
   * 申购
   *
   * */
  SUBSCRIPTION = 5,
  /**
   * 基金
   *
   * */
  FUND_OPERATION = 6,
  /**
   * 邀请
   *
   * */
  SYSTEM_INVITATION = 7,
}


//获取文本枚举
export enum GetTextEnum {
  /**
   * 隐私条款类型编码
   */
  PRIVACY_CLAUSE_CODE = 'SYSTEM_PRIVACY_CLAUSE',
  // 量化收益规则
  SYSTEM_AI_EARNINGS_RULES = 'SYSTEM_AI_EARNINGS_RULES',
  // 量化服务协议
  SYSTEM_SERVICE_AGREEMENT = 'SYSTEM_SERVICE_AGREEMENT',
  /**
   * 实名认证类型编码
   */
  SYSTEM_REAL_NAME = 'SYSTEM_REAL_NAME',
  /**
   * 用户协议类型编码
   */
  SYSTEM_USER = 'SYSTEM_USER',
  /**
   * 关于我们类型编码
   */
  APP_ABOUT_US = 'APP_ABOUT_US',
  /**
   * 公司简介编码
   */
  COMPANY_INTRODUCTION = 'COMPANY_INTRODUCTION',
  /**
   * 返佣规则
   */
  SYSTEM_REBATE_RULES = 'SYSTEM_REBATE_RULES',
  /**
   * 冻结金额说明
   */
  FREEZE_NOTICES = 'FREEZE_NOTICES',
  /**
   * 定期-收益规则
   */
  EARNINGS_RULES = 'EARNINGS_RULES',
  /**
   * 定期-服务协定
   */
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  /**
   * 定期-理财时间线
   */
  FINANCIAL_TIMELINE = 'FINANCIAL_TIMELINE',
  // "量化-收益规则"
  QUANTITATIVE_EARNINGS_RULES = 'QUANTITATIVE_EARNINGS_RULES',
  // "量化-服务协定"
  QUANTITATIVE_SERVICE_CONTRACT = 'QUANTITATIVE_SERVICE_CONTRACT',
  // "量化-理财时间线"
  QUANTITATIVE_FINANCIAL_TIMELINE = 'QUANTITATIVE_FINANCIAL_TIMELINE',
  KEY_CUSTOMER_CENTER = 'KEY_CUSTOMER_CENTER',
}

//IM消息状态
export enum ImMessageStatusEnum {
  /**
   * 正常消息 1
   **/
  NORMAL_MESSAGE = 1,
  /**
   * 编辑消息 2
   **/
  EDIT_MESSAGE = 2,
  /**
   * 撤回消息 -1
   **/
  WITHDRAW_MESSAGE = -1,
}

//IM发送消息状态
export enum SEND_MESSAGE_STATUS {
  /**
   * 失败发送
   **/
  FAIL = -1,
  /**
   * 等待中
   **/
  WAIT = 2,
  /**
   * 成功发送
   **/
  SUCCEED = 3,
}

//IM消息类型
export enum ImMessageTypeEnum {
  /**
   * 文字
   **/
  TEXT = 'TEXT',
  /**
   * 图片
   **/
  IMAGE = 'IMAGE',
  /**
   * 订单
   **/
  ORDER = 'ORDER',
  /**
   * 订单
   **/
  VIDEO = 'VIDEO',
  /**
   * 加入工单
   **/
  JOIN_MESSAGES = 'JOIN_MESSAGES',
  /**
   * 结束工单
   **/
  FINISH_MESSAGES = 'FINISH_MESSAGES',
}

//文本类型--区分富文本和markdown
export enum TextTypeEnum {
  /**
   * markdown
   **/
  MARKDOWN = 1,
  /**
   * 富文本
   **/
  RICH_TEXT = 2,
}

// 交易类型 限价/市价
export enum TradeTypeEnum {
  /**
   * 市价
   **/
  MARKET = 1,
  /**
   * 限价
   **/
  LIMIT = 2,
}

//保证金模式 全仓/逐仓
export enum MarginModeEnum {
  ALL = '',
  /**
   * 全仓
   **/
  FULL = 1,
  /**
   * 逐仓
   **/
  SEGMENTED = 2,
}

//保证金模式 做多/做空
export enum TradingDirectionEnum {
  //做多
  LONG = 1,
  //做空
  SHORT = 2,
}

/**
 * 收藏枚举
 **/
export enum CollectEnum {
  /**
   * 币币收藏
   **/
  COIN_COLLECT = 1,
  /**
   * 合约收藏
   **/
  CONTRACT_COLLECT = 2,
  /**
   * 股票收藏
   **/
  STOCK_COLLECT = 3,

  // 快速合约
  FAST_CONTRACT_COLLECT = 5,
}

/**
 * 获取币种列表类型枚举
 **/
export enum GetCoinListTypeEnum {
  /**
   * 现货
   **/
  COIN = '1',
  /**
   * 合约
   **/
  CONTRACT = '2',
  /**
   * 股票
   **/
  STOCK = '3',
  /**
   * 收藏
   **/
  COLLECT = '4',
  /**
   * 快速 合约
   **/
  FASTCONTRACT = '5',

  //ieo
  IEO = '6',

  //HEATMAP
  HEATMAP = '7',
}

/**
 * 合约下的列表类型
 **/
export enum ContractListTypeEnum {
  /**
   * 外汇
   **/
  FOREIGN_EXCHANGE = '2',
  /**
   * 虚拟币
   **/
  CRYPTOCURRENCY = '1',
}

/**
 * 钱包类型
 **/
export enum WalletTypeEnum {
  /**
   * 现货
   **/
  COIN = '1',
  /**
   * 合约
   **/
  CONTRACT = '2',
  /**
   * 法币
   **/
  FIAT = '3',

  // 股票
  STOCK = '3',

  /**
   * 快速 合约
   **/
  FASTCONTRACT = '5',
}

/**
 * 客服类型
 **/
export enum CustomerServiceTypeEnum {
  /**
   * 银行卡
   **/
  BANK_CARD = 0,
  /**
   * 虚拟币-回调自动充值
   **/
  CRYPTOCURRENCY_AUTO_CALLBACK = 1,
  /**
   * 后台充值
   **/
  BACKGROUND_RECHARGE = 2,
  /**
   * C2C
   **/
  C2C_CUSTOMER_SERVICE = 3,
  /**
   * 虚拟币-上传截图审核
   **/
  CRYPTOCURRENCY_UPLOAD_SCREENSHOT = 4,
  /**
   * 普通客服
   **/
  COMMON_CUSTOMER_SERVICE = 5,
  /**
   * 三方客服
   **/
  THIRD_PARTY_CUSTOMER_SERVICE = 6,
  /**
   * 临时客服
   **/
  TEMPORARY_CUSTOMER_SERVICE = 7,
}

/**
 * 充值通道类型
 **/
export enum RechargeChannelTypeEnum {
  /**
   * 银行卡充值
   **/
  BANK_CARD = 0,
  /**
   * 虚拟币自动回调
   **/
  CRYPTOCURRENCY_AUTO_CALLBACK = 1,
  /**
   * C2C商家买币
   **/
  C2C_BUY = 3,
  /**
   * 虚拟币上传截图
   **/
  CRYPTOCURRENCY_UPLOAD_SCREENSHOT = 4,
}

/**
 * 客服类型
 **/
export enum CustomerServiceRoleEnum {
  /**
   * 客服指定权限
   **/
  CUSTOMER_SERVICE_DESIGNATED_PERMISSIONS = 0,
  /**
   * 拥有者
   **/
  OWNER = 1,
  /**
   * 管理员
   **/
  ADMIN = 2,
  /**
   * 成员
   **/
  MEMBER = 3,
}

export const MarkListType = {
  //自选
  // COLLECT: GetCoinListTypeEnum.COLLECT,

  // VIP股票
  VIP_STOCKS: 9,
  // UC股票
  UC_STOCKS: 0,
  //第三方股票
  BRAZILIAN_STOCKS: 1,
  //美国股票
  US_STOCKS: 2,
  //加密货币
  CRYPTOCURRENCY: 3,
  //基金
  FUND: 4,
  // IPO
  IPO: 5,
  //IEO
  IEO: 6,
  // 大宗
  BULK: 7,
  // 配售
  DISTRIBUTION: 8,
  // 蓝筹股
  BLUE_CHIPS: 10,
  //ETF
  ETF: 11,
  //OPTIONS
  OPTIONS: 12,
};