//  不需要登录的路由
import PageEnum from '../enums/pageEnum';

export const basicRoutes = [
  {
    // 根路径重定向到登录页面
    path: '/',
    // redirect: PageEnum.LOGIN,
    component: '@/layouts/RedirectComponent', // 动态重定向组件
    i18n: 'market',
    isTab: false,
    motionType: 'alpha',
  },
  {
    path: '/proxy', //国际化key
    component: '@/pages/public/LanguageKeyPage',
    i18n: 'Language Key Page',
    motionType: 'bottom',
    noTopNav: true,
  },

  // {
  //   // 根路径重定向到登录页面
  //   path: '/',
  //   redirect: PageEnum.LOGIN,
  //   i18n: 'market',
  //   isTab: false,
  //   motionType: 'alpha',
  // },
  {
    // 登录页面路由
    path: PageEnum.LOGIN,
    component: '@/pages/public/login/LoginView',
    i18n: 'market',
    isTab: false,
    motionType: 'alpha',
  },
  {
    // 注册页面路由
    path: PageEnum.REGISTERED,
    component: '@/pages/public/register/RegisterView',
    i18n: 'market',
    isTab: false,
    motionType: 'alpha',
  },
  {
    // 忘记密码页面路由
    path: PageEnum.FORGOT,
    component: '@/pages/public/forgot/ForgotView',
    i18n: 'market',
    isTab: false,
    motionType: 'alpha',
  },
  {
    // 选择区号
    path: PageEnum.CHOOSE_AREA,
    component: '@/pages/public/selectAreaCode/SelectAreaCodeView',
    i18n: 'selectAreaCode',
    isTab: false,
    motionType: 'alpha',
  },
];
