import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage, useStrignStorage } from '@/hooks/useStorage';
import ProjectSetting from '@/setting/projectSetting';
import { useDebounceFn } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Timezone {
  name: string;
  offset: string;
  dayjsOffset: string;
  KLineChartTimezone: string; // echarts 所需的时区
}
/**
 * 用于检测当前设备是否为移动设备的Hook。
 * 初始时假设为移动设备，随后根据窗口大小判断是否为移动设备。
 *
 * @returns {Object} 返回一个包含isMobile属性的对象，isMobile表示当前设备是否被认为是移动设备。
 */
export default () => {
  const [isMobile, setIsMobile] = useState(true); // 初始状态将设备设置为移动设备
  const [coinSelected, setcoinSelected] = useState('');
  const [sortType, setSortType] = useState<GetCoinListTypeEnum>(
    GetCoinListTypeEnum.COLLECT,
  );
  //
  const { t } = useTranslation();

  // 时区列表 同步语TradingView 设置中的时区
  const timezones: Timezone[] = [
    {
      name: t('UTC'),
      offset: 'UTC',
      dayjsOffset: '-0000',
      KLineChartTimezone: 'Etc/UTC',
    },
    {
      name: t('夏威夷檀香山'),
      offset: 'UTC-10',
      dayjsOffset: '-1000',
      KLineChartTimezone: 'Pacific/Honolulu',
    },
    {
      name: t('朱诺'),
      offset: 'UTC-9',
      dayjsOffset: '-0900',
      KLineChartTimezone: 'America/Juneau',
    },
    {
      name: t('洛杉矶'),
      offset: 'UTC-8',
      dayjsOffset: '-0800',
      KLineChartTimezone: 'America/Los_Angeles',
    },
    {
      name: t('温哥华'),
      offset: 'UTC-8',
      dayjsOffset: '-0800',
      KLineChartTimezone: 'America/Vancouver',
    },
    {
      name: t('丹佛'),
      offset: 'UTC-7',
      dayjsOffset: '-0700',
      KLineChartTimezone: 'America/Denver',
    },
    {
      name: t('凤凰城'),
      offset: 'UTC-7',
      dayjsOffset: '-0700',
      KLineChartTimezone: 'America/Phoenix',
    },
    {
      name: t('芝加哥'),
      offset: 'UTC-6',
      dayjsOffset: '-0600',
      KLineChartTimezone: 'America/Chicago',
    },
    {
      name: t('墨西哥城'),
      offset: 'UTC-6',
      dayjsOffset: '-0600',
      KLineChartTimezone: 'America/Mexico_City',
    },
    {
      name: t('圣萨尔瓦多'),
      offset: 'UTC-6',
      dayjsOffset: '-0600',
      KLineChartTimezone: 'America/El_Salvador',
    },
    {
      name: t('波哥大'),
      offset: 'UTC-5',
      dayjsOffset: '-0500',
      KLineChartTimezone: 'America/Bogota',
    },
    {
      name: t('利马'),
      offset: 'UTC-5',
      dayjsOffset: '-0500',
      KLineChartTimezone: 'America/Lima',
    },
    {
      name: t('纽约'),
      offset: 'UTC-5',
      dayjsOffset: '-0500',
      KLineChartTimezone: 'America/New_York',
    },
    {
      name: t('多伦多'),
      offset: 'UTC-5',
      dayjsOffset: '-0500',
      KLineChartTimezone: 'America/Toronto',
    },
    {
      name: t('加拉加斯'),
      offset: 'UTC-4',
      dayjsOffset: '-0400',
      KLineChartTimezone: 'America/Caracas',
    },
    {
      name: t('布宜诺斯艾利斯'),
      offset: 'UTC-3',
      dayjsOffset: '-0300',
      KLineChartTimezone: 'America/Argentina/Buenos_Aires',
    },
    {
      name: t('圣地亚哥'),
      offset: 'UTC-4',
      dayjsOffset: '-0400',
      KLineChartTimezone: 'America/Santiago',
    },
    {
      name: t('圣保罗'),
      offset: 'UTC-3',
      dayjsOffset: '-0300',
      KLineChartTimezone: 'America/Sao_Paulo',
    },
    {
      name: t('都柏林'),
      offset: 'UTC',
      dayjsOffset: '-0000',
      KLineChartTimezone: 'Europe/Dublin',
    },
    {
      name: t('里斯本'),
      offset: 'UTC',
      dayjsOffset: '-0000',
      KLineChartTimezone: 'Europe/Lisbon',
    },
    {
      name: t('伦敦'),
      offset: 'UTC',
      dayjsOffset: '-0000',
      KLineChartTimezone: 'Europe/London',
    },
    {
      name: t('雷克雅未克'),
      offset: 'UTC',
      dayjsOffset: '-0000',
      KLineChartTimezone: 'Atlantic/Reykjavik',
    },
    {
      name: t('阿姆斯特丹'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Amsterdam',
    },
    {
      name: t('贝尔格莱德'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Belgrade',
    },
    {
      name: t('柏林'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Berlin',
    },
    {
      name: t('布鲁塞尔'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Brussels',
    },
    {
      name: t('哥本哈根'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Copenhagen',
    },
    {
      name: t('拉各斯'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Africa/Lagos',
    },
    {
      name: t('卢森堡'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Luxembourg',
    },
    {
      name: t('马德里'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Madrid',
    },
    {
      name: t('马耳他'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Malta',
    },
    {
      name: t('奥斯陆'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Oslo',
    },
    {
      name: t('巴黎'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Paris',
    },
    {
      name: t('罗马'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Rome',
    },
    {
      name: t('斯德哥尔摩'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Stockholm',
    },
    {
      name: t('华沙'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Warsaw',
    },
    {
      name: t('苏黎世'),
      offset: 'UTC+1',
      dayjsOffset: '+0100',
      KLineChartTimezone: 'Europe/Zurich',
    },
    {
      name: t('雅典'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Europe/Athens',
    },
    {
      name: t('开罗'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Africa/Cairo',
    },
    {
      name: t('赫尔辛基'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Europe/Helsinki',
    },
    {
      name: t('耶路撒冷'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Asia/Jerusalem',
    },
    {
      name: t('约翰内斯堡'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Africa/Johannesburg',
    },
    {
      name: t('里加'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Europe/Riga',
    },
    {
      name: t('塔林'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Europe/Tallinn',
    },
    {
      name: t('维尔纽斯'),
      offset: 'UTC+2',
      dayjsOffset: '+0200',
      KLineChartTimezone: 'Europe/Vilnius',
    },
    {
      name: t('巴林'),
      offset: 'UTC+3',
      dayjsOffset: '+0300',
      KLineChartTimezone: 'Asia/Bahrain',
    },
    {
      name: t('伊斯坦布尔'),
      offset: 'UTC+3',
      dayjsOffset: '+0300',
      KLineChartTimezone: 'Europe/Istanbul',
    },
    {
      name: t('科威特'),
      offset: 'UTC+3',
      dayjsOffset: '+0300',
      KLineChartTimezone: 'Asia/Kuwait',
    },
    {
      name: t('莫斯科'),
      offset: 'UTC+3',
      dayjsOffset: '+0300',
      KLineChartTimezone: 'Europe/Moscow',
    },
    {
      name: t('卡塔尔'),
      offset: 'UTC+3',
      dayjsOffset: '+0300',
      KLineChartTimezone: 'Asia/Qatar',
    },
    {
      name: t('利雅得'),
      offset: 'UTC+3',
      dayjsOffset: '+0300',
      KLineChartTimezone: 'Asia/Riyadh',
    },
    {
      name: t('德黑兰'),
      offset: 'UTC+3:30',
      dayjsOffset: '+0330',
      KLineChartTimezone: 'Asia/Tehran',
    },
    {
      name: t('迪拜'),
      offset: 'UTC+4',
      dayjsOffset: '+0400',
      KLineChartTimezone: 'Asia/Dubai',
    },
    {
      name: t('马斯喀特'),
      offset: 'UTC+4',
      dayjsOffset: '+0400',
      KLineChartTimezone: 'Asia/Muscat',
    },
    {
      name: t('阿什哈巴德'),
      offset: 'UTC+5',
      dayjsOffset: '+0500',
      KLineChartTimezone: 'Asia/Ashkhabad',
    },
    {
      name: t('加尔各答'),
      offset: 'UTC+5:30',
      dayjsOffset: '+0530',
      KLineChartTimezone: 'Asia/Kolkata',
    },
    {
      name: t('阿拉木图'),
      offset: 'UTC+6',
      dayjsOffset: '+0600',
      KLineChartTimezone: 'Asia/Almaty',
    },
    {
      name: t('曼谷'),
      offset: 'UTC+7',
      dayjsOffset: '+0700',
      KLineChartTimezone: 'Asia/Bangkok',
    },
    {
      name: t('胡志明市'),
      offset: 'UTC+7',
      dayjsOffset: '+0700',
      KLineChartTimezone: 'Asia/Ho_Chi_Minh',
    },
    {
      name: t('雅加达'),
      offset: 'UTC+7',
      dayjsOffset: '+0700',
      KLineChartTimezone: 'Asia/Jakarta',
    },
    {
      name: t('重庆'),
      offset: 'UTC+8',
      dayjsOffset: '+0800',
      KLineChartTimezone: 'Asia/Chongqing',
    },
    {
      name: t('香港'),
      offset: 'UTC+8',
      dayjsOffset: '+0800',
      KLineChartTimezone: 'Asia/Hong_Kong',
    },
    {
      name: t('珀斯'),
      offset: 'UTC+8',
      dayjsOffset: '+0800',
      KLineChartTimezone: 'Australia/Perth',
    },
    {
      name: t('上海'),
      offset: 'UTC+8',
      dayjsOffset: '+0800',
      KLineChartTimezone: 'Asia/Shanghai',
    },
    {
      name: t('新加坡'),
      offset: 'UTC+8',
      dayjsOffset: '+0800',
      KLineChartTimezone: 'Asia/Singapore',
    },
    {
      name: t('台北'),
      offset: 'UTC+8',
      dayjsOffset: '+0800',
      KLineChartTimezone: 'Asia/Taipei',
    },
    {
      name: t('首尔'),
      offset: 'UTC+9',
      dayjsOffset: '+0900',
      KLineChartTimezone: 'Asia/Seoul',
    },
    {
      name: t('东京'),
      offset: 'UTC+9',
      dayjsOffset: '+0900',
      KLineChartTimezone: 'Asia/Tokyo',
    },
    {
      name: t('布里斯班'),
      offset: 'UTC+10',
      dayjsOffset: '+1000',
      KLineChartTimezone: 'Australia/Brisbane',
    },
    {
      name: t('阿德莱德'),
      offset: 'UTC+10:30',
      dayjsOffset: '+1030',
      KLineChartTimezone: 'Australia/Adelaide',
    },
    {
      name: t('悉尼'),
      offset: 'UTC+11',
      dayjsOffset: '+1100',
      KLineChartTimezone: 'Australia/Sydney',
    },
    {
      name: t('诺福克岛'),
      offset: 'UTC+12',
      dayjsOffset: '+1200',
      KLineChartTimezone: 'Pacific/Norfolk',
    },
    {
      name: t('新西兰'),
      offset: 'UTC+13',
      dayjsOffset: '+1300',
      KLineChartTimezone: 'Pacific/Auckland',
    },
    {
      name: t('托克劳'),
      offset: 'UTC+13',
      dayjsOffset: '+1300',
      KLineChartTimezone: 'Pacific/Tokelau',
    },
    {
      name: t('查塔姆群岛'),
      offset: 'UTC+13:45',
      dayjsOffset: '+1345',
      KLineChartTimezone: 'Pacific/Chatham',
    },
    // { name: t('菲尼克斯'), offset: 'UTC-7', dayjsOffset: '-0700' , KLineChartTimezone:""}, // 美国
    // { name: t('圣萨尔瓦多'), offset: 'UTC-6', dayjsOffset: '-0600', KLineChartTimezone:"" }, // 美国
    // { name: t('利马'), offset: 'UTC-5', dayjsOffset: '-0500', KLineChartTimezone:"" }, // 美国
    // { name: t('芝加哥'), offset: 'UTC-5', dayjsOffset: '-0500' , KLineChartTimezone:""}, // 美国
    // { name: t('多伦多'), offset: 'UTC-4', dayjsOffset: '-0400', KLineChartTimezone:"" }, // 美国
    // { name: t('加拉加斯'), offset: 'UTC-4', dayjsOffset: '-0400' , KLineChartTimezone:""}, // 美国
    // { name: t('锯地'), offset: 'UTC-8', dayjsOffset: '+0800' , KLineChartTimezone:""}, // 美州
    // { name: t('伦敦'), offset: 'UTC-4', dayjsOffset: '-0400', KLineChartTimezone:"Europe/London" }, // 英国伦敦
    // { name: t('圣地亚哥'), offset: 'UTC-4', dayjsOffset: '-0400', KLineChartTimezone:"" }, // 美国
    // { name: t('布宜诺斯艾利斯'), offset: 'UTC-3', dayjsOffset: '-0300', KLineChartTimezone:"" }, // 巴西
    // { name: t('圣保罗'), offset: 'UTC-3', dayjsOffset: '-0300', KLineChartTimezone:"" }, // 巴西
    // { name: t('雷克雅未克'), offset: 'UTC-1', dayjsOffset: '-0100', KLineChartTimezone:"" }, // 美国
    // { name: t('东京'), offset: 'UTC+9', dayjsOffset: '+0900', KLineChartTimezone:"" }, // 日本
    // { name: t('首尔'), offset: 'UTC+9', dayjsOffset: '+0900', KLineChartTimezone:"" }, // 韩国
    // { name: t('河内'), offset: 'UTC+7', dayjsOffset: '+0700', KLineChartTimezone:"" }, // 越南
    // { name: t('新德里'), offset: 'UTC+5:30', dayjsOffset: '+0530', KLineChartTimezone:"" }, // 印度
  ];
  // 当前系统世界时区
  const [klineTimeZone, setKlineTimeZone] = useLocalStorage({
    key: 'klineTimeZone',
    defaultValue: '',
  });
  // 当前地区是否在 时区列表中
  const findTimezone = timezones.find(
    (item) =>
      item.KLineChartTimezone ===
      Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
  );
  useEffect(() => {
    if (!klineTimeZone) {
      setKlineTimeZone(
        findTimezone?.KLineChartTimezone ?? ProjectSetting.chartTimezone,
      );
    }
  }, []);
  // 当组件挂载和卸载时，监听和取消监听窗口大小改变事件
  useEffect(() => {
    // 定义处理窗口大小改变的函数
    const handleResize = () => {
      requestAnimationFrame(() => {
        // 根据窗口宽度判断是否为移动设备
        setIsMobile(window.innerWidth < 640);
      });
    };
    // 添加窗口大小改变事件的监听
    window.addEventListener('resize', handleResize);
    // 首次渲染后立即判断一次设备类型
    handleResize();
    // 组件卸载时移除事件监听
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 红涨绿跌设置
  const [upDownColorConfig, setUpDownColorConfig]: [
    'upGreen' | 'upRed', // 1绿涨红跌,默认 ， 2 红涨绿跌
    (value: string) => void,
  ] = useStrignStorage({
    key: CacheEnum.APP_UPDOWNCOLOR_CONFIG, // 全部
    defaultValue: 'upGreen', // 绿涨红跌,默认
  });

  //当前涨的颜色 类名 - 文字颜色
  const currentUpClass: 'text-errorColor' | 'text-successColor' =
    useMemo(() => {
      return upDownColorConfig === 'upGreen'
        ? 'text-successColor'
        : 'text-errorColor';
    }, [upDownColorConfig]);

  //当前跌的颜色 类名- 文字颜色
  const currentDownClass: 'text-errorColor' | 'text-successColor' =
    useMemo(() => {
      return upDownColorConfig === 'upGreen'
        ? 'text-errorColor'
        : 'text-successColor';
    }, [upDownColorConfig]);

  //当前涨的颜色 类名 - bg
  const currentUpBgClass: 'bg-errorColor' | 'bg-successColor' = useMemo(() => {
    return upDownColorConfig === 'upGreen'
      ? 'bg-successColor'
      : 'bg-errorColor';
  }, [upDownColorConfig]);

  //当前跌的颜色 类名- bg
  const currentDownBgClass: 'bg-errorColor' | 'bg-successColor' =
    useMemo(() => {
      return upDownColorConfig === 'upGreen'
        ? 'bg-errorColor'
        : 'bg-successColor';
    }, [upDownColorConfig]);

  //当前涨的颜色
  const riseColor = useMemo(() => {
    return upDownColorConfig === 'upGreen' ? 'successColor' : 'errorColor';
  }, [upDownColorConfig]);

  //当前跌的颜色
  const fallColor = useMemo(() => {
    return upDownColorConfig === 'upGreen' ? 'errorColor' : 'successColor';
  }, [upDownColorConfig]);

  const [AppKey, setAppKey] = useState<any>(1);

  // 刷新订单，用于订单组件重绘
  const reLoadApp = () => {
    setAppKey((AppKey: any) => AppKey + 1);
  };

  const [orderKey, setOrderKey] = useState<any>(1);

  const { run: reLoadOrder } = useDebounceFn(
    () => {
      setOrderKey((AppKey: any) => AppKey + 1);
    },
    {
      wait: 200, // 增加防抖 兼容下后端频繁推送
    },
  );
  // 是否能发送合约订单请求(layout里面设置值，否则由于缓存会导致不正常)
  const [contractOrderReady, setContractOrderReady] = useState(true);
  // 是否能发送币币订单同上
  const [coinOrderReady, setCoinOrderReady] = useState(true);

  const [isHomeSort, setIsHomeSort] = useState(false); // 初始状态将设备设置为移动设备
  const [isPcSort, setIsPcSort] = useState(false); // 初始状态将设备设置为移动设备

  // 追踪当前排序的列和顺序
  const [sortState, setSortState] = useState<any>({
    priceSort: 0, // 初始状态为无排序
    upDownSort: 0,
    upDownRangSort: 0,
  });

  // 滚动锁
  const [lockScroll, setLockScroll] = useState(false); // 滚动锁
  const [appGlobalKey, setAppGlobalKey] = useState(); // 滚动锁

  //
  const { run: globalRefresh } = useDebounceFn(
    () => {
      setAppGlobalKey((k: any) => k + 1);
    },
    {
      wait: 100,
    },
  );
  const isPc = useMemo(() => {
    return !isMobile;
  }, [isMobile]);

  const pathname = useMemo(() => {
    return window.location.pathname;
  }, [window.location.pathname]);

  const [successModalVisible, setSuccessModalVisible] = useState(false); // 控制弹框显示状态
  const [reReNameTitle, setReReNameTitle] = useState(''); // 是否实名ok
  const [reReNameText, setReReNameText] = useState(''); // 是否实名ok


  // 当前股票或者合约的行情
  const  [localChoiceContractSocket, setLocalChoiceContractSocket] = useLocalStorage({
    key: CacheEnum.APP_CHOICE_CONTRACT_SOCKET, 
    defaultValue: null, 
  });
  
  return {
    isMobile, // 返回设备是否为移动设备的判断结果
    isPc,
    upDownColorConfig,
    setUpDownColorConfig,
    currentUpClass, // 当前涨的颜色类名
    currentDownClass, // 当前跌的颜色类名
    currentUpBgClass, // 当前涨的背景颜色类名
    currentDownBgClass, // 当前跌的背景颜色类名
    riseColor,
    fallColor,
    AppKey,
    reLoadApp, //可调用刷新app状态，不是页面刷新
    orderKey,
    reLoadOrder,
    pathname,
    sortState,
    setSortState,
    isHomeSort,
    setIsHomeSort,
    isPcSort,
    setIsPcSort,
    lockScroll,
    setLockScroll,
    contractOrderReady,
    setContractOrderReady,
    coinOrderReady,
    setCoinOrderReady,
    coinSelected,
    setcoinSelected,
    timezones,
    klineTimeZone,
    setKlineTimeZone,
    appGlobalKey,
    globalRefresh, // 全局刷新 app

    sortType,
    setSortType,
    localChoiceContractSocket, 
    setLocalChoiceContractSocket,
    successModalVisible,
    setSuccessModalVisible,
    reReNameTitle, setReReNameTitle,
    reReNameText, setReReNameText
  };
};
