import {
    useLocalStorageState as ahooksUseLocalStorageState,
    useSessionStorageState
} from "ahooks";
/**
 * Storage 的封装
 * 参数一 LocalStorage key
 * 参数一 defaultValue 默认值
 */

type iprops = {
    key: string;
    defaultValue?: any;
}
// string
export const useStrignStorage: any = ({ key, defaultValue }: iprops) => {
    const relult: any = ahooksUseLocalStorageState<any>(
        key,
        {
            serializer: (v) => v ?? "",
            deserializer: (v) => v,
            defaultValue: defaultValue ?? "",
        }
    );
    return relult
}
//LocalStorage
export const useLocalStorage: any = ({ key, defaultValue }: iprops) => {
    const relult: any = ahooksUseLocalStorageState<any>(
        key,
        {
            defaultValue: defaultValue ?? null,
        }
    );
    return relult
}
// SessionStorage
export const useSessionStorage: any = ({ key, defaultValue }: iprops) => {
    const relult: any = useSessionStorageState<any>(
        key,
        {
            defaultValue: defaultValue ?? null,
        }
    );
    return relult
}

/**
 * @returns  拿到 useReq cacheKey 缓存的值
 */
export const useReqStorage: any = ({ key, defaultValue }: iprops) => {
    const [data, setData]: any = ahooksUseLocalStorageState<any>(
        key,
        {
            defaultValue: defaultValue ?? null,
        }
    );
    return [data?.data ?? defaultValue ?? null, setData]
}

