import ProjectSetting from '@/setting/projectSetting';
import { isDevMode } from '@/utils/env';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import hi from './hi.json';
import it from './it.json';
import ja from './ja.json';
import th from './th.json';
import zh from './zh-TW.json';

const storageLang = localStorage.getItem('i18nextLng');
export const defaultLocale = storageLang ?? ProjectSetting.APP_DEFAULT_LOCALE;
const missingKeys: any = localStorage.getItem('debug_missingKeys') ?? [];
console.log(
  storageLang?.includes('zh') ? 'zh' : storageLang ?? defaultLocale,
  '==========',
);
export const localesObject = {
  en,
  zh,
  ja,
  de,
  es,
  hi,
  it,
  fr,
  th,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: storageLang?.includes('zh')
      ? 'zh'
      : storageLang ?? defaultLocale,
    interpolation: {
      escapeValue: false,
    },
    lng: defaultLocale,
    resources: localesObject,
    parseMissingKeyHandler: function (key) {
      const containsChinese = /[\u4e00-\u9fa5]/.test(key); // 检查是否包含中文字符
      return containsChinese ? 'null' : key; // 如果不包含中文则返回原本的 key，否则返回 'null'
    },
    saveMissing: true, // 开启缺失键保存功能
    missingKeyHandler: function (_lngs, _ns, key: any) {
      // 新增：从缓存中获取丢失的键数组
      // 当找不到键时执行的函数
      if (!missingKeys.includes(key)) {
        if (isDevMode()) {
          const saveMissing = localStorage.getItem('saveMissing') ?? 'true';
          if (saveMissing === 'true') {
            // message.info('请处理国际化遗漏 key [ ' + key + ' ]');
          }
          let missingKeysObj: any = {};
          missingKeys.push(key);
          missingKeys?.forEach((element: any) => {
            missingKeysObj[element] = element;
          });

          console.log(
            JSON.stringify(missingKeysObj),
            '国际化配置没有找到键的！！！',
          );
          localStorage.setItem('missingKeys', missingKeys); // 将更新后的数组保存到缓存
        }
      }
    },
  });

export default i18n;
