import '@/global.less';
import { history, matchRoutes } from '@umijs/max';
import { errorConfig } from './services/net/requestConfig';
// 导入Swiper和Swiper样式
import 'swiper/css';
import 'swiper/css/pagination'; // 导入分页器样式
// 导入Swiper的Pagination模块
import CacheEnum from '@/enums/cacheEnum'; // 确保引入了自动播放样式
import PageEnum from '@/enums/pageEnum';
import React from 'react';
import { AliveScope, autoFixContext } from 'react-activation';
import jsxDevRuntime from 'react/jsx-dev-runtime';
import jsxRuntime from 'react/jsx-runtime';
import 'swiper/css/autoplay';

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/service-worker.js')
//       .then(() => {
//         console.log('serviceWorker 注册成功');
//       })
//       .catch(() => {
//         console.error('serviceWorker 注册失败');
//       });
//   });
// }

/***
 *配置文件 umi运行时会调用这些方法 包括 路由切换、请求拦截
 */

// 判断是否是PC设备的函数
function isPC() {
  const userAgent = window.navigator.userAgent;
  const isMobile = /Android|iPhone|iPad|iPod|Mobile/i.test(userAgent);
  return !isMobile; // 如果不是移动设备则认为是PC设备
}

//路由切换钩子--类似于window.addEventListener('change', () => {})
export function onRouteChange({ clientRoutes, location }: any) {
  
  const route: any = matchRoutes(clientRoutes, location.pathname)?.pop()?.route;
  const appInfo = JSON.parse(
    localStorage.getItem(CacheEnum.APP_BASE_INFO) ?? '{}',
  );
  if (route) {
    if (!route?.isTab) {
      window.scrollTo(0, 0);
    }
    if (appInfo?.APP_BASE_NAME) document.title = appInfo?.APP_BASE_NAME;
  }

  //如果已经登录，跳转到首页，不必重新登录
  if (route?.path === PageEnum.LOGIN) {
    const token = localStorage.getItem(CacheEnum.AUTHORIZATION);
    const refreshToken = localStorage.getItem(CacheEnum.REFRESHTOKEN);
    if (token && refreshToken) {
      if (isPC()) {
        history.replace(PageEnum.PCHOME); // 替换为PC首页
      } else {
        history.replace(PageEnum.HOME);
      }
    }
  }

  /**
    禁用图片，拖拽，右键菜单
   */

  const disableImageDrag = (e: Event) => {
    e.preventDefault();
  };

  const images = document.querySelectorAll('img');
  images.forEach((img: any) => {
    img.setAttribute('draggable', 'false');
    img.ondragstart = function () {
      return false;
    };
    img.style.userDrag = 'none';
    img.style.WebkitUserDrag = 'none';
    img.addEventListener('dragstart', disableImageDrag);
  });

  const handleContextMenu = (e: Event) => {
    // 判断目标是否为图片元素
    if ((e.target as HTMLElement).tagName === 'IMG') {
      e.preventDefault(); // 禁用右键菜单
    }
  };

  document.addEventListener('contextmenu', handleContextMenu);

  // 清理事件监听器
  return () => {
    document.removeEventListener('contextmenu', handleContextMenu);
  };
}

export function rootContainer(container: any) {
  return React.createElement(AliveScope, null, container);
}

/**
 * 遇到问题：但是只要在使用了useModel的页面，再使用KeepAlive，
 * 都会报错：Uncaught TypeError: Cannot read properties of null (reading 'dispatcher')（没有使用useModel的页面，没有报错）。后来在gitHub查到还需要添加一个配置：
 */
autoFixContext(
  [jsxRuntime, 'jsx', 'jsxs', 'jsxDEV'],
  [jsxDevRuntime, 'jsx', 'jsxs', 'jsxDEV'],
);

//不能删除 删除会导致页面变为umi的样子
export const layout: any = () => {
  return {
    pure: true,
    title: '',
  };
};

/**
 * @name request 配置，可以配置错误处理
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};

/**
 * render方法 其他框架一般在main当中，可打印查看实现，全局只会调用一次的方法
 */
export function render(oldRender: any) {
  // 关闭警告打印语句
  console.warn = function () {};
  console.error = function () {};
  oldRender();
}
