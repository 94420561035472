const gethostname = () =>
  typeof window !== 'undefined' ? window.location.hostname : '';

export const devList = ['10.0.0.77', '127.0.0.1'];
export const useServer = true;
// 开发环境端口
export const httpPort = '48111';
// 开发环境端口 socket
export const socketPort = '48112';
// 开发环境端口 IM
export const imSocketPort = '48113';

const NetEnum = Object.freeze({
  /*----开发环境----*/
  DEV_API_URL: useServer
    ? `https://us6-uk1-h5.yanshi.lol`
    : `http://${devList[0]}:${httpPort}`,
  DEV_WSS_URL: useServer
    ? `wss://us6-uk1-h5.yanshi.lol/app-websocket?Authorization=`
    : `ws://${devList[0]}:${socketPort}/app-websocket?Authorization=`,

  // im websocket地址1
  DEV_IM_SOCKET_WSS_URL: useServer
    ? `wss://us6-uk1-h5.yanshi.lol/im-websocket?Authorization=`
    : `ws://${devList[0]}:${imSocketPort}/im-websocket?Authorization=`,
  //上传
  UPLOAD_URL: useServer
    ? 'https://us6-uk1-h5.yanshi.lol/api/app-api/infra/file/upload'
    : `http://${devList[0]}:${httpPort}/app-api/infra/file/upload`,

  /*----线上环境----*/
  // http地址
  PRO_API_URL: '',
  // websocket地址
  PRO_WSS_URL: `wss://${gethostname()}/app-websocket?Authorization=`,
  // im websocket地址1
  PRO_IM_SOCKET_WSS_URL: `wss://${gethostname()}/im-websocket?Authorization=`,
  PRO_UPLOAD_URL: '/api/app-api/infra/file/upload',
});

export default NetEnum;
