const base = '/api/app-api';

enum RequestUrl {
  testUrl = '/adas',
  /**
   |---------------------------论坛--------------------------------------|
   */

  //获取字典
  getDictionaryUrl = base + '/system/dict-data/list',
  //邮箱注册
  emailRegisterUrl = base + '/system/auth/email-register',
  // 首页的消息
  getHomeNotice = base + '/notice/text-type/get-home-notice',
  //手机号注册
  phoneRegisterUrl = base + '/system/auth/sms-register',
  //刷新token
  refreshToken = base + '/system/auth/refresh-token',
  // 通过充值通道id查询充值通道信息
  getRechargeChannelByIdUrl = base + '/pay/recharge-channel/get',
  // 通道id查询群聊列表
  getGroupListUrl = base + '/pay/recharge-channel/group-list',
  // 论坛列表
  getPageListForum = base + '/notice/forum-message/page',
  // 获取资金相关数据接口
  fundingOverviewSocketMapUrl = base + '/pay/funds/get-statistics',
  // 论坛详情
  getDetailForum = base + '/notice/forum-message/get',
  // 获取贷款专属客服
  getCustomerServiceUrl = base + '/system/customer-server/get',
  // 判断 是否重复登录
  validRepeatLoginUrl = base + '/system/auth/valid-repeat-login',
  // 论坛点赞
  thumbsUp = base + '/notice/forum-message/thumbs-up',
  // 借贷额度
  loanDataUrl = base + '/pay/loan-data/info',
  // 借贷 创建
  createloanUrl = base + '/pay/loan-data/loan',
  // 借贷 历史
  loanPage = base + '/pay/loan-data/page',
  // 论坛查看
  look = base + '/notice/forum-message/look',
  // 商家充值 接口获取币种
  getRechargeCoinUrl = base + '/pay/recharge-channel/coin',
  // 获取持仓或者委托中的币种id和对应的杠杆
  positionAndEntrustmentUrl = base + '/pay/order-contract/position-level-map',
  //获取C2C列表
  C2CListUrl = base + '/pay/recharge-channel/page',
  // 根据用户id查询用户头像和客服昵称
  getUserInfoCustomerServiceUrl = base + '/im/group/avatar-list',
  //获取订单列表
  getOrderListUrl = base + '/runner/order-relation/page',
  //注册
  register = base + '/system/auth/email-register',
  // 退出登录
  loginOut = base + '/system/auth/logout',
  //找回密码
  retrievePasswordUrl = base + '/system/auth/reset-pwd',
  //选中客服
  selectCustomerServiceUrl = base + '/system/customer-server/get',
  // 签名上传地址
  uploadSignatureUrl = base + '/system/member-sign/upload',
  // 决绝退还
  rejectRefundUrl = base + '/system/sys-internal-beta-order/refusal-to-return',
  // 内测弹窗提示
  closedBetaUrl = base + '/system/sys-internal-beta-order/refund-fee',
  //获取订单详情
  loginUrl = base + '/system/auth/login',
  //获取验证码
  getCaptcha = base + '/system/captcha/img-captcha',
  //获取虚拟币充值通道
  getRechargeVirtualCurrencyChannelUrl = base +
    '/pay/recharge-channel/virtual-list',
  //获取银行卡充值通道
  getBankRechargeChannelUrl = base + '/pay/recharge-channel/bank-card-list',
  // 提前结算
  earlySettlementUrl = base + '/pay/ai-order/early-settlement',
  // 发送邮件
  sendMail = base + '/system/auth/send-captcha',
  // 币种精度列表
  getCoinPrecisionUrl = base + '/pay/symbol/get-symbol-accuracy',
  //银行列表
  getBankListUrl = base + '/pay/bank/bank-list',
  //新增银行卡
  addBankUrl = base + '/pay/bank-card/create-bank-card',
  //修改银行卡
  editBankUrl = base + '/pay/bank-card/update-bank-card-info',
  //获取单个银行卡
  getBankCardInformationUrl = base + '/pay/bank-card/get',
  //获取银行卡列表
  getPersonBankListUrl = base + '/pay/bank-card/bank-card-list',

  exchangeConfigUrl = base + '/pay/exchange-config/list',
  // 获取充值提现汇率列表
  getExchangeRateUrl = base + '/pay/coin/coin-list-rate ',
  //获取轮播图
  getCarouselImageUrl = base + '/system/rotation-chart/list',
  //获取交易通道列表
  getChannelListUrl = base + '/pay/recharge-channel/list',
  //验证通道密码
  verifyChannelPasswordUrl = base + '/pay/recharge/verify',
  //获取用户Udun充值地址
  getUdunRechargeAddressUrl = base + '/pay/recharge/udun-address',
  //获取充值信息
  rechargeInformationUrl = base + '/pay/recharge/get',
  //上传付款凭证
  uploadPaymentVoucherUrl = base + '/pay/recharge/submit',
  //充值法币
  rechargeFiatCurrencyUrl = base + '/pay/recharge',
  //获取虚拟币地址
  getPersonVirtualListUrl = base + '/pay/virtual-wallet/curent-member-list',
  // 虚拟网络列表
  getVirtualListUrl = base + '/system/virtual-network/list',
  //新增虚拟卡
  addVirtualUrl = base + '/pay/virtual-wallet/create',
  //修改虚拟卡
  editVirtualUrl = base + '/pay/virtual-wallet/update',
  //获取提现手续费
  getWithdrawalFeesUrl = base + '/pay/withdrawal/fee',
  //获取单个虚拟卡
  getIndividualVirtualCardInformationUrl = base + '/pay/virtual-wallet/get',
  //获取卡片值
  getCardValueUrl = base + '/system/config/query-value',
  //获取菜单列表
  getMenuListUrl = base + '/system/view-control/list',
  //一键平仓
  closeAPositionWithOneClick = base + '/pay/order-contract/close-one-click',

  //获取现货和合约币种
  spotAndContractCurrenciesUrl = base + '/pay/coin/coin-list',
  //获取所有币种
  getAllCurrenciesUrl = base + '/pay/coin/coin-list-all',
  //获取币种下的网络
  getCoinNetworkUrl = base + '/system/virtual-network/virtual-network-list',

  // 股票列表
  getTradingSymbolsUrl = base + '/pay/symbol/search',

  //创建股票订单
  createOrderUrl = base + '/pay/order-contract/create',

  //撤销股票订单
  closeOrderUrl = base + '/pay/order-stock/revoke',

  //平仓订单
  closingOrderUrl = base + '/pay/order-stock/complete',

  //获取合约订单列表
  getStockOrderListUrl = base + '/pay/order-stock/page',
  //获取当前合约下的最大可交易数和最小交易数和费率
  getContractOrderConfigUrl = base + '/pay/symbol-stock/get',
  //获取合约订单详情
  getOrderDetailUrl = base + '/pay/order-stock/detail',
  //历史订单详情
  historicalOrderDetailsUrl = base + '/pay/funds/detail',
  //获取国际化文本
  getPrivacyAgreementUrl = base + '/notice/text-type/text',
  //获取帮助中心列表
  getHelpCenterListUrl = base + '/notice/text-type/help-page',
  //获取帮助中心详情
  getHelpCenterDetailUrl = base + '/notice/text-type/detail',
  //意见反馈
  submitFeedbackUrl = base + '/system/feedback/create',
  //实名认证
  userRealname = base + '/pay/realname/create',
  //修改邮箱
  modifyEmailUrl = base + '/system/member/update-email',
  //修改手机号
  modifyMobilePhoneNumberUrl = base + '/system/member/update-phone',
  //修改用户头像和昵称
  modifyUserInformationUrl = base + '/system/member/update-avatar-name',
  // 归还体验金
  returnExperiencePriceUrl = base + '/pay/experience-fund/send-back',
  //购买现货
  buyCoinUrl = base + '/pay/order-coin/purchase-actual-commodities',
  //获取用户信息
  getUserInfoUrl = base + '/system/member/get-current-member',
  //获取新闻列表
  getNewListUrl = base + '/notice/text-type/news-page',
  /**
   * # 新闻详情接口地址
   */
  getNewDetailUrl = base + '/notice/text-type/detail',

  //股票列表
  getOrderUrl = base + '/pay/order-join/page',
  //修改用户密码
  changeUserPasswordUrl = base + '/system/auth/update-pwd',
  //修改支付密码
  changePaymentPasswordUrl = base + '/system/member/update-pay-pwd',

  //撤销现货 订单
  closeCoinOrder = base + '/pay/order-coin/revoke/',

  //现货列表
  coinOrderUrl = base + '/pay/order-contract/page',

  //钱包资金
  userWallet = base + '/pay/wallet/list-type',

  //钱包资金
  userWalletFund = base + '/pay/wallet/list-fund',

  //获取Ai列表
  getAIListUrl = base + '/pay/ai-order-config/page',

  // 获取邀请详情列表
  getInviteListUrl = base + '/pay/common-rebate/get-pay-common-rebate-page',

  // 我的邀请统计信息
  getInviteStatisticsUrl = base + '/pay/common-rebate/invitees-statistics',

  //购买AI
  buyAIUrl = base + '/pay/ai-order/buy',

  //钱包合约
  contractWallet = base + '/pay/wallet/contract',

  //法币钱包
  fiatCurrencyBalanceUrl = base + '/pay/wallet/fiat',

  //法币列表
  getFiatCurrencyBalanceUrl = base + '/pay/coin/fait-list',

  //钱包总览
  walletStatistic = base + '/pay/wallet/statistic',

  //钱包现货
  walletFund = base + '/pay/wallet/fund',

  //快速合约钱包现货
  fastWalletUrl = base + '/pay/wallet/quick-contract',

  //快速合约 订单 详情
  fastOrderDetailUrl = base + '/pay/order-contract-quick/detail',
  // 一键已读订单信息
  readOrderUrl = base + '/system/notice-msg/total-read',

  //资金记录
  fundsList = base + '/pay/funds/page',
  // 新资金流水
  fundsListNewUrl = base + '/pay/funds/page0',
  // 获取im群列表
  imGroupListUrl = base + '/im/group/group-list',
  // 申请Vip
  createVipOrderUrl = base + '/system/sys-member-vip/create',
  // ai订单记录
  aiOrderListUrl = base + '/pay/ai-order/page',
  // ai子订单
  aiChildOrderListUrl = base + '/pay/ai-order/sub-page',
  //ai详情
  aiOrderDetailUrl = base + '/pay/ai-order/sub-detail',
  // ai 子仓列表
  aiPageSubUrl = base + '/pay/ai-order-sub/page-sub',
  // ai 子仓详情
  aiChildOrderDetailSubUrl = base + '/pay/ai-order-sub/get',
  //获取充值列表
  rechargeInformationListUrl = base + '/pay/recharge/page',

  //获取提现记录
  withdrawalListUrl = base + '/pay/withdrawal/page',

  //图表数据
  getCandlestick = base + '/exchange/candlestick/data',

  //交易数据
  getChartPageInfo = base + '/exchange/candlestick/24-hour-stat',

  //交易数据
  coinDetail = base + '/pay/coin/detail',

  //获取可用余额
  getAvailableBalanceUrl = base + '/pay/wallet/get-by-coin',
  //提现
  withdrawUrl = base + '/pay/withdrawal',
  //获取可选币种
  getOptionalCurrenciesUrl = base + '/pay/coin/contract-list',
  //确认划转
  transferUrl = base + '/pay/wallet/any-transfer',
  // 新增会员收藏币对
  addMemberCollect = base + '/pay/symbol-collect/create',
  // 分页获取会员收藏币对
  getMemberCollect = base + '/pay/symbol-collect/page',
  // 删除会员收藏币对
  deleteMemberCollect = base + '/pay/symbol-collect/delete',
  // 检查币对收藏状态
  checkCollect = base + '/pay/symbol-collect/check-collect',
  // 申购列表
  getSubscriptionList = base + '/pay/subscribe-config/partition-list',
  // 公告列表
  getNoticeList = base + '/notice/text-type/notice-page',
  // 公告类型
  getNoticeType = base + '/notice/text-type/index',
  // 标记已读
  sendReadMessageUrl = base + '/system/notice-msg/get',
  // 新闻列表
  getNewsList = base + '/notice/text-type/news-page',
  // 订单消息
  orderMessageUrl = base + '/system/notice-msg/page',
  // 申购购买
  buySubscription = base + '/pay/subscribe-order/buy',
  // 交易申购信息订单分页
  getTransactionSubscription = base + '/pay/subscribe-order/page',

  //获取国际化
  getLangList = base + '/system/i18n-type/list',
  //获取国际化
  getLangJson = base + '/system/i18n/json',

  //xiu改止盈止损
  updateTpSl = base + '/pay/order-contract/update-profit-loss',

  //限价平仓
  limitComplete = base + '/pay/order-contract/limit-complete',

  // 大宗列表
  getBulkList = base + '/pay/bulk-config/page',

  // 获取大宗详情
  getBulkDetail = base + '/pay/bulk-config/get',

  // 创建大宗订单
  createBulkOrder = base + '/pay/bulk-order/buy',

  // 增加浏览量
  addView = base + '/notice/forum-message/look',

  // 充值详情
  rechargeDetail = base + '/pay/recharge/detail',

  // 提现详情
  withdrawDetail = base + '/pay/withdrawal/detail',
  // 取消提现
  cancelWithdrawUrl = base + '/pay/withdrawal/cancel',
  //发送消息
  sendImMessageUrl = base + '/im/message/send',
  //撤回消息
  withdrawMessageUrl = base + '/im/message/revoke',

  // 在线客服 - 聊天列表
  chartList = base + '/im/group/chat-list',

  // 在线客服 - 聊天记录
  chartRecord = base + '/im/message/list',
  //获取工单
  getAWorkOrderUrl = base + '/im/work-order/get',
  //工单评分
  ticketScoringUrl = base + '/im/work-order/evaluation',
  //亚马逊S3上传文件
  uploadFilesS3Url = base + '/infra/file-config/temporary-credential',
  //获取区号
  getAreaCodeUrl = base + '/system/area-manage/list',
  //获取群id
  getGroupIdUrl = base + '/im/group/chat-list',
  //获取资金记录详情列表
  fundsListDetailUrl = base + '/pay/funds/detail-list',
  //获取充值记录详情列表
  rechargeDetailUrl = base + '/pay/recharge/detail-list',
  //获取提现记录详情列表
  withdrawalDetailUrl = base + '/pay/withdrawal/detail-list',

  //返佣-获取总奖励
  getRebateTotalUrl = base + '/pay/common-rebate/reward',
  //返佣-获取排行榜
  getRankedListUrl = base + '/pay/common-rebate/rank',
  //获取邀请码列表
  getInvitationCodeListUrl = base + '/system/sys-member-invite-code/page',
  //创建邀请码
  createInvitationCodeUrl = base + '/system/sys-member-invite-code/create',
  //修改邀请码
  modifyInvitationCodeUrl = base + '/system/sys-member-invite-code/update',
  //获取邀请码好友
  getInvitationCodeFriendUrl = base + '/system/sys-member-invite-code/friends',
  //获取会员邀请信息--等级和返佣比例
  getMemberInvitationInformationUrl = base + '/system/rebate-config/member',
  //获取当前默认邀请码
  getTheCurrentDefaultInvitationCodeUrl = base +
    '/system/sys-member-invite-code/default',
  //邀请等级列表和对应的佣金比例
  getTierListUrl = base + '/system/rebate-config/all',
  //获取外汇前三名K线
  getTopThreeKLinesInForeignExchangeUrl = base + '/pay/symbol/get-currency',
  // 获取提现通道
  getWithdrawalChannelUrl = base + '/pay/withdrawal-channel/list',
  // 获取虚拟钱包
  getVirtualWalletListUrl = base + '/pay/virtual-wallet/list-simple-all',
  //获取合约订单列表
  getContractOrderListUrl = base + '/pay/order-contract/page',
  //创建合约订单  根据 基币数量 下单
  createContractOrderUrl = base + '/pay/order-contract/create',
  //创建合约订单 根据 usdt 下单
  createSpecifyUrl = base + '/pay/order-contract/create-specify',
  // 设备登录确认[保持登录]
  confirmDeviceLoginUrl = base + '/system/auth/keep-online',
  // 设备登录确认[取消登录]
  cancelDeviceLoginUrl = base + '/system/auth/cancel-online',
  // 快速合约 ，下单
  createQuickOrderUrl = base + '/pay/order-contract-quick/buy',
  //平仓合约订单
  closingContractOrderUrl = base + '/pay/order-contract/complete',
  // 反选平仓
  reverseSelectionToClosePositionUrl = base + '/pay/order-contract/reverse',
  // 一键反向
  reverseAllUrl = base + '/pay/order-contract/reverse-all',
  //撤销合约订单
  closeContractOrderUrl = base + '/pay/order-contract/revoke',
  //获取合约订单详情
  getContractOrderDetialUrl = base + '/pay/order-contract/detail',

  //vip列表
  getvipLevelConfigUrl = base + '/pay/vip-config/list',

  //vip购买
  createVipOrder = base + '/pay/vip-purchase/buy',

  // 首页公告通知获取未读
  getUnreadNoticeUrl = base + '/notice/text-type/unread-announcement',

  // 首页公告通知获取已读
  getReadNoticeUrl = base + '/notice/text-operate/create',

  // 现货币种列表
  symbolCoinList = base + '/pay/symbol-coin/total-page',

  // 币币订单获取
  getCoinOrderList = base + '/pay/order-coin/order-page',
  // 闪兑详情
  getCoinTradeDetail = base + '/pay/flash-exchange/record',
  // 币币订单详情获取
  getCoinOrderDetail = base + '/pay/order-coin/order-detail',
  // 划转详情
  transferDetailsUrl = base + '/pay/funds/transfer-detail',
  // 获取分时图数据
  getTimeSharingChartUrl = base + '/exchange/rate/data',

  // 币种获取
  getCoinList = base + '/pay/coin/page',
  getAllCoinList = base + '/pay/coin/coin-list-by-name',

  // 闪兑创建
  createFlashExchange = base + '/pay/flash-exchange/create',

  // 获取闪兑详情
  getFlashExchangeDetail = base + '/pay/flash-exchange/detail',
  //获取币种下的资金
  getFundsUnderCurrencyUrl = base + '/pay/wallet/get-by-coin',
  //通过币种id获取币种Symbol
  getSymbolByCoinIdUrl = base + '/pay/coin/symbol-by-coin',
  //获取闪兑记录
  getFlashRedemptionRecordUrl = base + '/pay/flash-exchange/page',
  // 获取临时token
  getTempTokenUrl = base + '/system/auth/temporary-login',
  // 查询快速订单
  getFastContractOrderListUrl = base + '/pay/order-contract-quick/page',
  // 热力图
  getHeatmapListUrl = base + '/pay/symbol-contract/heatmap',
  // IEO 列表
  getIEOList = base + '/pay/subscribe-ieo/partition-list',
  // 一键认购 ipo
  oneClickSubscription = base + '/pay/subscribe-ipo/one-click-buy',
  // ieo 购买
  createIEOOrderUrl = base + '/pay/subscribe-ieo/buy',
  // 配售 购买
  createSubscriptionUrl = base + '/pay/subscribe-ration/buy',
  // 获取IEO订单详情
  getIEOOrderDetailsUrl = base + '/pay/subscribe-ieo/get',
  // IEO订单获取
  getIEOOrderUrl = base + '/pay/subscribe-ieo/page',

  // 获取vip信息
  getVipInfoUrl = base + '/pay/vip-purchase/detail',

  // 列表
  noticeList = base + '/notice/text-type/text-list',
  // 首页文章 对应的列表
  getJumpListUrl = base + '/notice/text-type/index-jump-list',

  //创建股票订单
  createStockOrderUrl = base + '/pay/order-stock/create',
}

export default RequestUrl;
