import { cn } from '@/utils';
import { useModel } from '@umijs/max';
import {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { animated, useSpring } from 'react-spring';

// 定义 Toast 数据类型
interface ToastData {
  title: string;
  content: string;
  icon?: string;
  unreadCount?: number;
  autoCloseSeconds?: number; // 自动关闭的秒数
  onClose?: () => void;
  onClick?: () => void;
  type?: 'info' | 'success' | 'error' | 'warning'; // 新增类型字段
}

// ToastMessage 组件
const ToastMessage = forwardRef((_, ref) => {
  const { isPc } = useModel('system');

  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState(1);
  const [toastData, setToastData] = useState<ToastData>({
    title: '',
    content: '',
    icon: '',
    unreadCount: 0,
    autoCloseSeconds: 3000000, // 默认自动关闭时间 3 秒
  });

  // 定义弹出和消失动画
  const animationStyle = useSpring({
    transform: isVisible ? 'translateY(0%)' : 'translateY(-150%)',
    opacity: isVisible ? 1 : 0,
    config: { tension: 220, friction: 20 }, // 可调节动画速度和弹性
  });

  // 自动关闭逻辑
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isVisible && toastData.autoCloseSeconds) {
      timer = setTimeout(() => {
        setIsVisible(false);
        toastData.onClose?.();
      }, toastData.autoCloseSeconds * 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isVisible, toastData.autoCloseSeconds, id]);

  // 暴露 open 和 close 方法
  useImperativeHandle(ref, () => ({
    open: (data: ToastData) => {
      setToastData((prevData) => ({
        ...data,
        unreadCount: data.unreadCount, // 更新未读数
        autoCloseSeconds: data.autoCloseSeconds ?? 3, // 设置自动关闭时间
      }));
      setIsVisible(true);
      setId((old) => old + 1);
      // 设置新计时器
    },
    close: () => setIsVisible(false),
  }));

  if (!isVisible && animationStyle.opacity.get() === 0) return null;

  return (
    <animated.div
      style={animationStyle}
      className="fixed top-4 right-4 z-[9999] left-4 flex justify-center"
      onClick={() => {
        toastData.onClose?.();
        setIsVisible(false);
        if (toastData?.onClick) {
          toastData.onClick();
        }
      }}
    >
      <div
        className={cn(
          ` rounded-lg p-3.5 w-full bg-bgColor dark:bg-backgroundAuxiliaryColor`,
          { ' w-[300px]': isPc, ' w-full': !isPc },
        )}
        style={{
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 15px 30px rgba(0, 0, 0, 0.1)',
        }}
      >
        {toastData.type === undefined && (
          <div className="flex justify-between items-center">
            <div className="font-semibold text-base flex items-center">
              {toastData.icon && (
                <img
                  src={toastData.icon}
                  className="w-[20px] h-[20px] mr-2"
                />
              )}
              {toastData.title}

              {/* 未读消息 */}
              {toastData.unreadCount && toastData.unreadCount > 0 && (
                <span className="ml-2 bg-[red] text-[#fff] text-xxs px-1.5 py-[2px] rounded-full">
                  {toastData.unreadCount}
                </span>
              )}
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsVisible(false);
                toastData.onClose?.();
              }}
              className="text-[25px] h-[25px] cursor-pointer"
            >
              ×
            </div>
          </div>
        )}

        <div className=" text-sm flex items-center break-all" style={{ wordWrap: 'break-word' }}>
          {toastData.type === 'success' && (
            <img
              src={require('@/assets/img/toast/success.png')}
              className="w-[20px] h-[20px] mr-2"
            />
          )}

          {toastData.type === 'error' && (
            <img
              src={require('@/assets/img/toast/error.png')}
              className="w-[20px] h-[20px] mr-2"
            />
          )}

          {toastData.type === 'warning' && (
            <img
              src={require('@/assets/img/toast/warning.png')}
              className="w-[20px] h-[20px] mr-2"
            />
          )}

          {toastData.type === 'info' && (
            <img
              src={require('@/assets/img/toast/info.png')}
              className="w-[20px] h-[20px] mr-2"
            />
          )}


          {toastData.content}
        </div>
      </div>
    </animated.div>
  );
});

// 创建全局 ref 和 toast 对象
const toastRef = createRef<{
  open: (data: ToastData) => void;
  close: () => void;
}>();

// 新增方法，分别处理 info、success、error 类型的 Toast
export const toast = {
  open: (data: ToastData) => {
    toastRef.current?.open(data);
  },
  close: () => {
    toastRef.current?.close();
  },
  info: (content: string, options?: Partial<ToastData>) => {
    toastRef.current?.open({
      title: 'Info',
      content,
      type: 'info',
      ...options,
    });
  },
  success: (content: string, options?: Partial<ToastData>) => {
    toastRef.current?.open({
      title: 'Success',
      content,
      type: 'success',
      ...options,
    });
  },
  error: (content: string, options?: Partial<ToastData>) => {
    toastRef.current?.open({
      title: 'Error',
      content,
      type: 'error',
      ...options,
    });
  },
  warning: (content: string, options?: Partial<ToastData>) => {
    toastRef.current?.open({
      title: 'warning',
      content,
      type: 'warning',
      ...options,
    });
  },
};

// 导出组件
export default function GlobalToast() {
  return <ToastMessage ref={toastRef} />;
}
