import { useDebounceFn, useDocumentVisibility, useUpdateEffect } from 'ahooks';
import { useEffect, useState } from 'react';

const usePageRefreshOnVisible = (initialPageKey = 1, debounceWait = 200) => {
  const [pageKey, setPageKey] = useState(initialPageKey);
  const documentVisibility: any = useDocumentVisibility();

  // 定义防抖函数，控制页面刷新
  const { run: reload } = useDebounceFn(
    () => {
      setPageKey((prevState) => prevState + 1); // 增加 pageKey 触发刷新
    },
    { wait: debounceWait },
  );

  // 监听 document 可见性变化
  useUpdateEffect(() => {
    if (documentVisibility === 'visible' && navigator.onLine) {
      reload();
    }
  }, [documentVisibility, reload]);

  return pageKey; // 返回当前的 pageKey
};

export default usePageRefreshOnVisible;
