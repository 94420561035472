// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_2 from '/opt/app/agent/work/1c1075d149fd04e/src/models/areaCode';
import model_3 from '/opt/app/agent/work/1c1075d149fd04e/src/models/cacheAvatarList';
import model_5 from '/opt/app/agent/work/1c1075d149fd04e/src/models/compontentSetting';
import model_7 from '/opt/app/agent/work/1c1075d149fd04e/src/models/exchange';
import model_11 from '/opt/app/agent/work/1c1075d149fd04e/src/models/orderHistoryCache';
import model_12 from '/opt/app/agent/work/1c1075d149fd04e/src/models/routing';
import model_15 from '/opt/app/agent/work/1c1075d149fd04e/src/models/system';
import model_13 from '/opt/app/agent/work/1c1075d149fd04e/src/models/socket';
import model_10 from '/opt/app/agent/work/1c1075d149fd04e/src/models/language';
import model_8 from '/opt/app/agent/work/1c1075d149fd04e/src/models/imSocket';
import model_16 from '/opt/app/agent/work/1c1075d149fd04e/src/models/user';
import model_1 from '/opt/app/agent/work/1c1075d149fd04e/src/models/appInfo';
import model_4 from '/opt/app/agent/work/1c1075d149fd04e/src/models/coin';
import model_6 from '/opt/app/agent/work/1c1075d149fd04e/src/models/contract';
import model_14 from '/opt/app/agent/work/1c1075d149fd04e/src/models/stock';
import model_9 from '/opt/app/agent/work/1c1075d149fd04e/src/models/kline';
import model_17 from '/opt/app/agent/work/1c1075d149fd04e/src/pages/mine/aItrade/model';

export const models = {
model_2: { namespace: 'areaCode', model: model_2 },
model_3: { namespace: 'cacheAvatarList', model: model_3 },
model_5: { namespace: 'compontentSetting', model: model_5 },
model_7: { namespace: 'exchange', model: model_7 },
model_11: { namespace: 'orderHistoryCache', model: model_11 },
model_12: { namespace: 'routing', model: model_12 },
model_15: { namespace: 'system', model: model_15 },
model_13: { namespace: 'socket', model: model_13 },
model_10: { namespace: 'language', model: model_10 },
model_8: { namespace: 'imSocket', model: model_8 },
model_16: { namespace: 'user', model: model_16 },
model_1: { namespace: 'appInfo', model: model_1 },
model_4: { namespace: 'coin', model: model_4 },
model_6: { namespace: 'contract', model: model_6 },
model_14: { namespace: 'stock', model: model_14 },
model_9: { namespace: 'kline', model: model_9 },
model_17: { namespace: 'mine.aItrade.model', model: model_17 },
} as const
