// 事件总线事件名称枚举
export enum BusEnums {
  // 重新连接socketKey
  RECONNECT_SOCKET = 'RECONNECT_SOCKET',
  // 关闭所有socket连接
  CLOSE_ALL_SOCKET = 'CLOSE_ALL_SOCKET',
  // 消息确认丢失信息
  MESSAGE_LOST = 'messageLost',
  // 消息推送完成
  MESSAGE_PUSH_FINISH = 'messagePushFinish',
  // im获取历史会话弹窗打开和获取列表
  IM_HISTORY_SESSION_OPEN = 'imHistorySessionOpen'
}
