import type { RequestOptions } from '@@/plugin-request/request';
import { history, type RequestConfig } from '@umijs/max';
// import { message, notification } from 'antd';
import { BusEnums } from '@/enums/busEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { toast } from '@/layouts/components/ToastMessage';
import HttpSetting from '@/setting/httpSetting';
import ProjectSetting from '@/setting/projectSetting';
import { isDevMode } from '@/utils/env';
import eventBus from '@/utils/evevtBus';
import { toJsonData } from '@/utils/socket';
import * as queryString from 'querystring';
import { refreshTokenApi } from '../api/requestApi';
// import { DEV } from '../../../config/proxy';
const isDev = process.env.NODE_ENV === 'development';

// 错误处理方案： 错误类型
enum ErrorShowType {
  SILENT = 0,
  WARN_MESSAGE = 1,
  ERROR_MESSAGE = 2,
  NOTIFICATION = 3,
  REDIRECT = 9,
}

// 与后端约定的响应数据格式
interface ResponseStructure {
  success: boolean;
  data: any;
  errorCode?: number;
  errorMessage?: string;
  showType?: ErrorShowType;
}

// 定义一个变量来记录上次弹出错误消息的时间
let lastErrorMessageTimestamp = 0;
let lastStatusErrorMessageTimestamp = 0;
let lastRefreshTokenTimestamp = 0;
/**
 * @name 错误处理
 * pro 自带的错误处理， 可以在这里做自己的改动
 * @doc https://umijs.org/docs/max/request#配置
 */
export const errorConfig: RequestConfig = {
  // 错误处理： umi@3 的错误处理方案。
  errorConfig: {
    // 错误抛出
    errorThrower: (res) => {
      const { success, data, errorCode, errorMessage, showType } =
        res as unknown as ResponseStructure;
      if (!success) {
        const error: any = new Error(errorMessage as any);
        error.name = 'BizError';
        error.info = { errorCode, errorMessage, showType, data };
        throw error; // 抛出自制的错误
      }
    },
    // 错误接收及处理
    errorHandler: (error: any, opts: any) => {
      if (opts?.skipErrorHandler) throw error;
      // 我们的 errorThrower 抛出的错误。
      if (error.name === 'BizError') {
        const errorInfo: ResponseStructure | undefined = error.info;
        if (errorInfo) {
          const { errorMessage, errorCode } = errorInfo;
          switch (errorInfo.showType) {
            case ErrorShowType.SILENT:
              // do nothing
              break;
            case ErrorShowType.WARN_MESSAGE:
              toast.error(errorMessage || '');
              break;
            case ErrorShowType.ERROR_MESSAGE:
              toast.error(errorMessage || '');
              break;
            case ErrorShowType.NOTIFICATION:
              toast.error(errorMessage || '');

              break;
            case ErrorShowType.REDIRECT:
              // TODO: redirect
              break;
            default:
              toast.error(errorMessage || '');
          }
        }
      } else if (error.response) {
        // Axios 的错误
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围

        const currentTimestamp = Date.now();
        if (currentTimestamp - lastStatusErrorMessageTimestamp > 3000) {
          lastStatusErrorMessageTimestamp = currentTimestamp;
          // toast.error('Response status:' + error?.response.status);
        }
      } else if (error.request) {
        // 请求已经成功发起，但没有收到响应
        // \`error.request\` 在浏览器中是 XMLHttpRequest 的实例，
        // 而在node.js中是 http.ClientRequest 的实例
        toast.error('None response! Please retry.');
      } else {
        // 发送请求时出了点问题
        // message.error('Request error, please retry.');
      }
    },
  },

  // 请求拦截器
  requestInterceptors: [
    (config: RequestOptions | any) => {
      // 拦截请求配置，进行个性化处理。
      let url = config?.url;

      let token = '';
      try {
        const localToken = localStorage.getItem(CacheEnum.AUTHORIZATION);
        token =
          localToken && localToken !== 'undefined'
            ? toJsonData(localToken)
            : '';
      } finally {
      }

      // 尝试从 url 中获取 token
      const search = new URLSearchParams(window.location.search);
      const accessToken = search.get('access_token');
      if (accessToken) {
        token = accessToken;
        localStorage.setItem(CacheEnum.AUTHORIZATION, JSON.stringify(token));
      }
      const refreshToken = search.get('refresh_token');
      if (refreshToken) {
        localStorage.setItem(
          CacheEnum.REFRESHTOKEN,
          JSON.stringify(refreshToken),
        );
      }

      if ('/api/app-api/system/auth/refresh-token' === url) {
        token = '';
      }

      const lang =
        localStorage.getItem('i18nextLng') ?? ProjectSetting.APP_DEFAULT_LOCALE;

      if (isDevMode()) {
        if (HttpSetting.API_URL.includes('https')) {
          url = HttpSetting.API_URL + config?.url;
        } else {
          url = (HttpSetting.API_URL + config?.url).replace('/api/', '/');
        }
      }
      const mockApi = window.localStorage.getItem('window.apiUrl');
      if (mockApi) {
        url = (mockApi + config?.url).replace('/api/', '/');
      }

      const headers = {
        ...config.headers,
        language: lang,
        Authorization: token ? 'Bearer ' + token : '',
        userType: 'main',
      };

      return { ...config, url, headers };
    },
  ],

  // 响应拦截器
  responseInterceptors: [
    (response: any) => {
      const { data, config } = response as any;
      const currentTimestamp = Date.now();

      /**
       * 401 刷新token
       * 409 退出重新登录
       */

      //这里是更新刷新token的逻辑
      if (data?.code && data.code === 401) {
        if (currentTimestamp - lastRefreshTokenTimestamp > 5000) {
          lastRefreshTokenTimestamp = currentTimestamp;

          const localToken = localStorage.getItem(CacheEnum.REFRESHTOKEN);
          const refreshToken = localToken ? JSON.parse(localToken) : '';
          refreshTokenApi({ refreshToken, noMsg: true }).then((res: any) => {
            const { code, data } = res || {};
            const { accessToken, refreshToken } = data || {};
            if (code === 0) {
              if (accessToken) {
                localStorage.setItem(
                  CacheEnum.AUTHORIZATION,
                  JSON.stringify(accessToken),
                );

                eventBus.emit(BusEnums.RECONNECT_SOCKET, accessToken);
              }
              if (refreshToken) {
                localStorage.setItem(
                  CacheEnum.REFRESHTOKEN,
                  JSON.stringify(refreshToken),
                );
              }

              window.FlutterTokens?.postMessage(JSON.stringify(data));
            }
          });
        }
      } else if (data?.code && data.code === 409) {
        // 如果当前时间和上次错误消息的时间差大于3秒，则显示错误消息
        if (currentTimestamp - lastErrorMessageTimestamp > 3000) {
          lastErrorMessageTimestamp = currentTimestamp;
          if (!window.location.href.includes(PageEnum.LOGIN)) {
            if (window.location.pathname === '/') {
              localStorage.removeItem(CacheEnum.AUTHORIZATION);
            } else {
              localStorage.removeItem(CacheEnum.AUTHORIZATION);
              localStorage.removeItem(CacheEnum.REFRESHTOKEN);
              // 重定向到登录页面
              if (
                window.location.pathname !== '/pc_home' &&
                window.location.pathname !== '/' &&
                window.location.pathname !== '/proxy'
              ) {
                eventBus.emit(BusEnums.CLOSE_ALL_SOCKET);
                history.push(PageEnum.LOGIN);
              }
            }
          }
        }
      } else if (data?.code && data.code !== 0) {
        // 对于其他错误，直接显示错误消息
        /**
         * noMsg: true,
         * 如果不不想看到后端的报错信息，可以在接口传一个 noMsg: true,
         */
        if (config?.params?.noMsg !== true && config?.data?.noMsg !== true) {
          toast.error(data.msg);
        }
      }

      return response;
    },
  ],

  //请求序列化，用于get请求数组方式下
  paramsSerializer(params) {
    return queryString.stringify(params);
  },
};
