export class IndexedDBHelper {
  private dbName: string;
  private storeName: string;
  private version: number;

  constructor(dbName: string, storeName: string, version = 1) {
    this.dbName = dbName;
    this.storeName = storeName;
    this.version = version;
  }

  // 打开数据库
  private openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.version);

      request.onerror = () => reject("Failed to open IndexedDB");
      request.onsuccess = () => resolve(request.result);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName, { keyPath: "id", autoIncrement: true });
        }
      };
    });
  }

  // **设置（覆盖）整个表内容**
  async setItems(items: any[]) {
    const db = await this.openDB();

    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction(this.storeName, "readwrite");
      const store = transaction.objectStore(this.storeName);

      // 1. **清空旧数据**
      const clearRequest = store.clear();

      clearRequest.onsuccess = () => {
        // 2. **批量插入新数据**
        items.forEach((item) => store.add(item));
        resolve();
      };

      clearRequest.onerror = () => reject("Failed to clear old data");
    });
  }

  // **获取所有数据**
  async getItems(): Promise<any[]> {
    const db = await this.openDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.getAll();

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject("Failed to retrieve data");
    });
  }

  // **删除某个数据**
  async deleteItem(id: number) {
    const db = await this.openDB();
    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction(this.storeName, "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.delete(id);

      request.onsuccess = () => resolve();
      request.onerror = () => reject("Failed to delete item");
    });
  }

  // **清空整个数据库表**
  async clear() {
    const db = await this.openDB();
    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction(this.storeName, "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.clear();

      request.onsuccess = () => resolve();
      request.onerror = () => reject("Failed to clear store");
    });
  }
}
