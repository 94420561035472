import { BusEnums } from '@/enums/busEnum';
import {
  ConfuseCompletedOrderOrderTypeEnum, CustomerServiceTypeEnum,
  VerifiedStatesEnum,
} from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import {
  fastWalletApi,
  fiatCurrencyBalanceApi,
  fundingOverviewSocketMapApi,
  getTempTokenApi,
  getUserInformation, imGroupListApi,
  validRepeatLoginApi,
  walletFundApi,
  walletStatisticApi,
  logoutApi
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { clearStorageExcept, generateRandomString } from '@/utils';
import eventBus from '@/utils/evevtBus';
import { calculateProfitLoss } from '@/utils/trade';
import { history, useModel } from '@umijs/max';
import {
  useDebounceFn,
  useMount,
  usePrevious,
  useThrottleEffect,
  useUpdateEffect,
} from 'ahooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { chatDB } from '@/models/imSocket';
import { toast } from '@/layouts/components/ToastMessage';
import { useTranslation } from 'react-i18next';


// User接口定义了用户信息的数据结构
export interface User {
  status: number; // 用户状态
  phone: null | string; // 用户电话，可能为null
  walletBalance: number; // 钱包余额
  sysUserId: null | number; // 系统用户ID，可能为null
  memberTime: number; // 会员开始时间
  memberDueDate: number; // 会员到期时间
  totalValue: null | number; // 总资产值，可能为null
  todayProfitAndLoss: null | number; // 今日盈亏，可能为null
  historyProfitAndLoss: null | number; // 历史盈亏，可能为null
  usdExchangeJpy: number; // USD对JPY的汇率
  auditStatus: number; // 审核状态
  socketId: string; // WebSocket的ID
  netWorth: number; // 净值
  occupationMargin: number; // 占用保证金
  availableMargin: number; // 可用保证金
  marginProportion: number; // 保证金比例
  userId: number; // 用户ID
  invitationNo: number; // 邀请码
  avatar: string; // 头像URL
}

// 默认导出的函数初始化并管理用户信息和相关数据
export default () => {
  const { t } = useTranslation();

  // 使用model来管理WebSocket连接
  const { connectWebSocket, onclose, setSocketId, refreshUserInfoKey } = useModel('socket');
  const { disconnectSocket, setImMessage, setImGroupList } = useModel('imSocket');
  const { globalRefresh } = useModel('system');

  // const { imDisconnect } = useModel('imSocket');
  // 使用local storage来管理用户信息
  const [user, setUser] = useLocalStorage({
    key: CacheEnum.APP_USER,
    defaultValue: {},
  });



  /*------------------------------------新项目的资金页面所需要的数据-------------------------------------------------------------*/
  const [fundingOverviewSocketMapApiData, setFundingOverviewSocketMapApiData] =
    useLocalStorage({
      key: CacheEnum.APP_FUNDSAPI,
      defaultValue: {},
    });

  const {
    // data: fundingOverviewSocketMapApiData = {} as any,
    runAsync: fundingOverviewSocketMapReq,
    loading: fundLoading,
  } = useReq(() => fundingOverviewSocketMapApi(), {
    loadingDefault: true,
    manual: true,
    ready:!!user?.id,
    onSuccess(data) {
      // 接口成功返回后，将数据保存到缓存中
      setFundingOverviewSocketMapApiData(data);
    },
  });
  // 资金相关(socket和接口融合-socket优先) 持仓列表中的盈亏自己去订单盈亏里面查询替换（保证最后统计的盈亏和订单是一致的）
  const {
    fundingOverviewSocketMap,
    getSocketRowByOrderId,
    order,
    setFundingOverviewSocketMap,
    getSocketRowByName,
    sendMsg,
    isSub,
    lastMessageTime,
  }: any = useModel('socket') || {};

  // 监听 fundingOverviewSocketMap 的变化，当其有更新时，也更新缓存
  useEffect(() => {
    if (fundingOverviewSocketMap) {
      setFundingOverviewSocketMapApiData((prevData: any) => ({
        ...prevData,
        ...fundingOverviewSocketMap,
      }));
    }
  }, [fundingOverviewSocketMap, setFundingOverviewSocketMapApiData]);

  const renderFunds = useMemo(() => {
    // 如果socket有数据提前返回优先
    return {
      // 总览
      overview: {
        // 总资产
        total:
          fundingOverviewSocketMap?.overview?.ta ??
          fundingOverviewSocketMapApiData?.overview?.ta,
      },
      // 合约
      contract: {
        // ec
        ec: fundingOverviewSocketMap?.contract?.ec ?? fundingOverviewSocketMapApiData?.contract?.ec,
        // 合约总资产
        total:
          fundingOverviewSocketMap?.contract?.ta ??
          fundingOverviewSocketMapApiData?.contract?.ta,
        // 合约总持仓价值
        totalPositionValue:
          fundingOverviewSocketMap?.contract?.tpv ??
          fundingOverviewSocketMapApiData?.contract?.tpv,
        // 合约总浮动盈亏
        totalProfitAndLoss:
          fundingOverviewSocketMap?.contract?.tfpl ??
          fundingOverviewSocketMapApiData?.contract?.tfpl,
        // 合约总可用资产
        totalAvailable:
          fundingOverviewSocketMap?.contract?.taa ??
          fundingOverviewSocketMapApiData?.contract?.taa,
        // 合约总冻结金额
        totalFrozenAmount:
          fundingOverviewSocketMap?.contract?.tfa ??
          fundingOverviewSocketMapApiData?.contract?.tfa,
        // 持仓列表
        plList: (
          fundingOverviewSocketMap?.contract?.plList ??
          fundingOverviewSocketMapApiData?.contract?.plList
        )?.map?.((item: any) => {
          const findItem = getSocketRowByOrderId(item?.id);
          if (findItem?.id)
            return {
              id: findItem?.id,
              plAmount: findItem?.plAmount,
              plRatio: findItem?.plRatio,
            };
          return item;
        }),
      },
      // 币币
      coin: {
        // 币币总资产
        total:
          fundingOverviewSocketMap?.coin?.ta ??
          fundingOverviewSocketMapApiData?.coin?.ta,
        // 币币总可用资产
        totalAvailable:
          fundingOverviewSocketMap?.coin?.taa ??
          fundingOverviewSocketMapApiData?.coin?.taa,
        // 币币总冻结金额
        totalFrozenAmount:
          fundingOverviewSocketMap?.coin?.tfa ??
          fundingOverviewSocketMapApiData?.coin?.tfa,
        // 币币列表
        walletList:
          fundingOverviewSocketMap?.coin?.walletList ??
          fundingOverviewSocketMapApiData?.coin?.walletList,
      },
    };
  }, [
    fundingOverviewSocketMap,
    fundingOverviewSocketMapApiData,
    order,
    location.pathname,
  ]);

  // 判断用户是否重复登录
  // const [isRepeatLogin, setIsRepeatLogin] = useState(false);
  const {
    data: isRepeatLogin = {} as any,
    runAsync: validRepeatLoginReq,
    mutate: setValidRepeatLogin,
  } = useReq(() => validRepeatLoginApi(), {
    manual: true,
    onSuccess(data) {
      if (data) {
        eventBus.emit('OTHER_LOGIN', data);
      }
    },
  });

  // 总盈亏
  const [contractProfitAndLoss, setContractProfitAndLoss] = useState(0);
  const lock = useRef(false);
  // useEffect(() => {
  //   // 这个推送不会很 频繁
  //   const profit = renderFunds?.contract?.plList?.reduce(
  //     (acc: number, cur: any) => {
  //       return acc + (cur?.plAmount ?? 0);
  //     },
  //     0,
  //   );
  //   lock.current = true;
  //   setTimeout(() => {
  //     lock.current = false;
  //   }, 500);
  //   if (!contractProfitAndLoss) {
  //     setContractProfitAndLoss(profit || 0);
  //   }
  // }, [renderFunds]);

  const orderListMargin = useMemo(() => {
    // 全部订单的保证金 总和
    let earnestMoney = 0;
    if (renderFunds?.contract?.plList?.length) {
      earnestMoney = renderFunds?.contract?.plList?.reduce(
        (acc: number, cur: any) => {
          return acc + cur?.earnestMoney;
        },
        0,
      );
    }
    return earnestMoney;
  }, [renderFunds]);

  useEffect(() => {
    if (renderFunds?.contract?.plList?.length) {
      sendMsg(
        `{"type":"SUB","data": ${JSON.stringify(
          renderFunds?.contract?.plList?.map((i: any) => i?.subscribeSymbol),
        )}}`,
      );
    }
  }, [orderListMargin, isSub]);

  const { reLoadOrder } = useModel('system');

  useThrottleEffect(
    () => {
      reLoadOrder();
      // 增加 节流 兼容下后端频繁推送 bug
      // 监听订单 总 保证金 去刷新 合约订单列表
    },
    [orderListMargin, renderFunds?.contract?.ec],
    { wait: 998 },
  );

  useUpdateEffect(() => {
    if (lock.current) return;
    // 这个推送不会很 频繁
    const profit = renderFunds?.contract?.plList?.reduce(
      (acc: number, cur: any) => {
        const socketData = getSocketRowByName(cur?.subscribeSymbol);
        return (
          acc +
          calculateProfitLoss({
            marketPrice: socketData?.a,
            openPrice: cur?.endPrice,
            leverage: cur?.lever,
            margin: cur?.earnestMoney,
            isLong: cur?.direction === 1, // 是否 开多 direction=1 买多 ，direction=2 买空
          })
        );
      },
      0,
    );
    setContractProfitAndLoss(profit || 0);
  }, [lastMessageTime]);

  // 合约可用资产
  const contractAvailableFunds = useMemo(() => {
    if (
      contractProfitAndLoss < 0 &&
      Math.abs(contractProfitAndLoss) > renderFunds?.contract.totalPositionValue
    ) {
      return (
        renderFunds?.contract?.totalAvailable +
        contractProfitAndLoss +
        renderFunds?.contract.totalPositionValue
      );
    }
    return renderFunds?.contract?.totalAvailable;
  }, [renderFunds, contractProfitAndLoss]);

  // 获取合约订单盈亏(订单调用)
  const getContractProfitAndLoss = useCallback(
    (id: string) => {
      const findItem = renderFunds?.contract?.plList?.find(
        (item: any) => item?.id === id,
      );
      return findItem ?? {};
    },
    [renderFunds, order],
  );
  const { setUserAmount, setFundingOverviewSocket } = useModel('socket');

  //钱包余额 总览
  const {
    data: walletStatistic = {} as any,
    runAsync: walletStatisticReq,
    mutate: setWalletStatistic,
  } = useReq(() => walletStatisticApi(), {
    manual: true,
    cacheKey: CacheEnum.APP_WALLETSTATISTIC,
  });

  //钱包余额 合约
  const {
    data: contractData = {} as any,
    runAsync: fiatCurrencyBalanceRequest,
    mutate: setWalletData,
  } = useReq(() => fiatCurrencyBalanceApi(), {
    manual: true,
    cacheKey: CacheEnum.APP_CONTRACTWALLETAPI,
  });

  //钱包余额 币币
  const {
    data: coinsFunds = {} as any,
    runAsync: walletFundReq,
    mutate: setWalletCoin,
  } = useReq(() => walletFundApi(), {
    manual: true,
    cacheKey: CacheEnum.APP_WALLETFUNDAPI,
    throttleWait: 2000,
    staleTime: 2 * 1000, // 缓存超时时间
  });

  //钱包余额 快速合约
  const {
    data: fastWalletFunds = {
      total: 0,
      walletBalance: 0,
      positionAmount: 0,
    } as any,
    runAsync: fastWalletFundReq,
  } = useReq(() => fastWalletApi(), {
    manual: true,
    cacheKey: CacheEnum.APP_FASTWALLETAPI,
  });

  // 快速合约 钱包
  const fastWallet: any = useMemo(() => {
    return fastWalletFunds ?? {};
  }, [fastWalletFunds]);

  // 刷新钱包方法
  const { run: refreshWallet } = useDebounceFn(
    () => {
      fundingOverviewSocketMapReq();
      setTimeout(() => {
        walletFundReq();
      });
    },
    {
      wait: 999, // 一秒钟最多触发一次
    },
  );

  //登录提交
  const [token] = useLocalStorage({
    key: CacheEnum.AUTHORIZATION,
  });
  //用户信息
  const { runAsync: requestUserInfo } = useReq(
    () => {
      // 在登录或注册页面时不请求用户信息
      // get-current-member
      // if (['/login', '/register'].includes(window.location.pathname))
      //   return null;
      return getUserInformation();
    },
    {
      // ready: localStorage.getItem(CacheEnum.APP_LOGIN_TYPE) === '2',
      manual:
        window.location.pathname === '/register' ||
        window.location.pathname === '/login' ||
        window.location.pathname === '/forgot',
      staleTime: 1 * 1000, // 缓存超时时间
      formatResult: (res: any) => res, // 结果格式化函数
      onSuccess: (res: any, [params]) => {
        const data = res?.data ?? {};
        if (data) {
          if (!params?.noFund) {
            refreshWallet();
            walletFundReq();
          }
          setUser(data);
          globalRefresh();
          // fiatCurrencyBalanceRequest();

          // walletStatisticReq();
          // fastWalletFundReq();

          //在初始化的时候尝试 链接 soket
          const localStorageToken = localStorage.getItem(
            CacheEnum.AUTHORIZATION,
          );
          if (localStorageToken) {
            const localToken = JSON.parse(
              localStorage.getItem(CacheEnum.AUTHORIZATION) as string,
            );
            connectWebSocket(localToken);
          }

          if (window?.$crisp) {
            window.$crisp.push(['set', 'user:email', ['john.doe@crisp.chat']]);
          }
        }
      },
      onError(res: any) {
        // 处理401错误，清除授权信息
        if (res?.code === 401) {
          localStorage.removeItem(CacheEnum.AUTHORIZATION);
          return;
        }
      },
    },
  );

  useEffect(() => {
    // 刷新用户信息
    requestUserInfo();
  }, [refreshUserInfoKey]);

  useMount(() => {
    //在初始化的时候尝试 链接 soket
    const localStorageToken = localStorage.getItem(CacheEnum.AUTHORIZATION);
    if (localStorageToken) {
      try {
        const localToken = JSON.parse(
          localStorage.getItem(CacheEnum.AUTHORIZATION) as string,
        );
        if (localToken) connectWebSocket(localToken);
      } catch (error) {
      }
    }
  });

  useEffect(() => {
    if (window?.$crisp) {
      try {
        if (user?.userId) {
          window.$crisp.push(['set', 'user:id', [user?.id]]);
          window.$crisp.push(['set', 'user:email', [user?.email ?? '']]);
          window.$crisp.push(['set', 'user:phone', [user?.phone ?? '']]);
          window.$crisp.push([
            'set',
            'user:nickname',
            [user?.realName ?? user?.phone ?? user?.email ?? ''],
          ]);
        } else {
          const rid = generateRandomString(9);
          window.$crisp.push(['set', 'user:email', [rid]]);
          window.$crisp.push(['set', 'user:phone', [rid]]);
          window.$crisp.push(['set', 'user:nickname', ['未登录用户' + rid]]);
        }
      } catch (e) {
        console.log(e, '$crisp');
      }

      // 获取 id 为 crisp-chatbox 的元素
      // 找到所有带有 data-has-unread 属性的子元素
      const unreadElements = document.querySelectorAll('cc-157aw');
      // 遍历这些元素并设置样式为隐藏
      unreadElements.forEach((element: any) => {
        element.style.display = 'none !important'; // 设置元素隐藏
      });
    }
  }, [window.ssq, user]);

  // 在令牌变更时尝试使用令牌重新连接WebSocket
  useEffect(() => {
    if (token) {
      connectWebSocket(token);
    }
  }, [token]);
  // 使用model来管理Socket连接中的用户金额信息
  const { userSoketAmount, fundingOverviewSocket = {} } = useModel('socket');

  // 合约金额
  const userAmount: any = useMemo(() => {
    let res = contractData ?? {};
    // 如果有来自Socket的用户金额信息，则更新余额字段
    if (userSoketAmount || fundingOverviewSocket?.contractAmount) {
      res = {
        ...res,
        available: userSoketAmount?.available, // 可用余额
        freeze: userSoketAmount?.freeze, // 冻结金额
        historyPlAmount: contractData?.historyPlAmount, // 历史盈亏
        plAmount: userSoketAmount?.plAmount, // 未实现盈亏
        total: userSoketAmount?.walletBalance, // 总资产
        walletBalance: userSoketAmount?.walletBalance, // 余额
        earnestMoney:
          fundingOverviewSocket?.contractAmount ??
          userSoketAmount?.earnestMoney, // 余额
        unrealizedProfitLoss: userSoketAmount?.unrealizedProfitLoss, // 余额
      };
    }
    return res;
  }, [userSoketAmount, contractData, fundingOverviewSocket]);

  // 总览金额
  const userOverview: any = useMemo(() => {
    let walletStatisticTmp = walletStatistic ?? {};

    if (walletStatistic?.total) {
      walletStatisticTmp.totalAmount = walletStatistic?.total;
    }

    if (fundingOverviewSocket?.totalAmount) {
      walletStatisticTmp.totalAmount = fundingOverviewSocket.totalAmount;
    }
    if (walletStatistic?.dayPl) {
      walletStatisticTmp.freeze = walletStatistic?.dayPl; // 今日盈亏
    }
    return walletStatisticTmp;
  }, [fundingOverviewSocket, walletStatistic?.total]);

  // 关闭各个socket链接
  const closeSocket = () => {
    // 关闭WebSocket连接
    onclose();
    disconnectSocket();
  };

  useEffect(() => {
    eventBus.on(BusEnums.CLOSE_ALL_SOCKET, closeSocket);
    return () => {
      eventBus.off(BusEnums.CLOSE_ALL_SOCKET, closeSocket);
    };
  });

// 退出登录接口
  const { runAsync: logOutReq } = useReq(logoutApi, {
    manual: true,
    onSuccess: () => {
    },
    onError: (res: any) => {
    //  toast.error(t("退出登录失败")); 
    }
  })

  // 退出登录
  const logOut = () => {
    // 点击退出登录按钮时，清除sessionStorage和localStorage，并跳转到登录页。
    setUser({});
    setImMessage([]);
    setImGroupList([]);
    setFundingOverviewSocketMap({});
    setFundingOverviewSocketMapApiData({});
    setWalletStatistic({});
    setWalletData({});
    setWalletCoin({});
    setContractProfitAndLoss(0);
    setFundingOverviewSocket(undefined);
    setUserAmount(undefined);
    setUser({});

    try {
       logOutReq()
      // 清除资金缓冲
      sessionStorage.clear();
      chatDB.clear();
      // 清理缓存 但是 指定保留的键值
      clearStorageExcept([
        'i18nextLng',
        'LANGUAGE',
        'window.imSoketUrl',
        'window.soketUrl',
        'window.apiUrl',
        'apiUrlHistory',
        'soketUrlHistory',
        'imSoketUrlHistory',
        CacheEnum.APP_IM_READ_MESSAGE_ID,
      ]);
      closeSocket();
      history.replace(PageEnum.LOGIN);
    } finally {
      // console.log('退出登录 执行报错');
    }
  };
  // url pathname
  const pathname = window.location.pathname;
  const prePathname = usePrevious(pathname);

  useEffect(() => {
    // 监听路由，在某些情况，比如 token过期导致的 退出登录，需要断开soket
    if (
      prePathname !== undefined &&
      prePathname !== PageEnum.LOGIN &&
      pathname === PageEnum.LOGIN
    ) {
      logOut();
    }
  }, [pathname]);

  // 6/5 需求：不需要登录进入聊天
  const { connect } = useModel('imSocket');

  // 发送请求获取临时请求
  const { runAsync: getTempTokenApiIm } = useReq(getTempTokenApi, {
    manual: true,
    onSuccess: (res: any) => {
      if (res) {
        localStorage.setItem(CacheEnum.APP_LOGIN_TYPE, '1');
        localStorage.setItem(
          CacheEnum.AUTHORIZATION,
          JSON.stringify(res?.accessToken),
        );
        localStorage.setItem(
          CacheEnum.REFRESHTOKEN,
          JSON.stringify(res?.refreshToken),
        );
        setSocketId(res?.accessToken);
        connect();
      }
    },
  });
  const [isRealname, setIsRealname] = useState(true);

  useEffect(() => {
    if (user) {
      if (user?.realnameStatus === VerifiedStatesEnum.REAL) {
        setIsRealname(true);
      } else if (![null, undefined].includes(user?.realnameStatus)) {
        setIsRealname(false);
      }
    }
  }, [user?.realnameStatus]);
  // const isRealname: boolean = useMemo(() => {
  //   // 返回 用户 是否已经 实名
  //   if (user) {
  //     return user?.realnameStatus === VerifiedStatesEnum.REAL;
  //   }
  //   return false;
  // }, [user?.realnameStatus]);


  // 返回管理的用户信息、请求用户信息函数和用户金额信息
  return {
    user,
    fundLoading,
    requestUserInfo,
    userAmount,
    isRepeatLogin,
    setValidRepeatLogin,
    validRepeatLoginReq,
    logOut,
    fiatCurrencyBalanceRequest,
    userOverview,
    walletStatisticReq,
    coinsFunds,
    walletFundReq,
    refreshWallet,
    getTempTokenApiIm,
    isRealname,
    fastWallet,
    renderFunds,
    fastWalletFundReq, // 快速合约钱包接口
    contractProfitAndLoss,
    getContractProfitAndLoss,
    fundingOverviewSocketMapReq,
    fundingOverviewSocketMapApiData,
    contractAvailableFunds,

  };
};
