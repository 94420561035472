import {
  CompletedOrderOrderProcessEnum,
  ImMessageTypeEnum,
} from '@/enums/businessEnum';
import {
  fundsListDetailApi,
  rechargeDetailApi,
  withdrawalDetailApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { toJsonData } from '@/utils/socket';
import { useState } from 'react';

/*
 * 客服聊天订单缓存
 * */
export default () => {
  //查询聊天记录接口请求
  // 资金记录
  const { runAsync: fundingRecordsReq } = useReq(fundsListDetailApi, {
    formatResult: (res: any) => {
      return res?.data ?? [];
    },
    manual: true,
  }) as any;
  // 充值
  const { runAsync: rechargeReq } = useReq(rechargeDetailApi, {
    formatResult: (res: any) => {
      return res?.data ?? [];
    },
    manual: true,
  }) as any;
  // 提现
  const { runAsync: withdrawReq } = useReq(withdrawalDetailApi, {
    formatResult: (res: any) => {
      return res?.data ?? [];
    },
    manual: true,
  }) as any;

  //聊天订单缓存
  const [historyOrderDetail, setHistoryOrderDetail] = useState({
    [CompletedOrderOrderProcessEnum.ALL]: [],
    [CompletedOrderOrderProcessEnum.ADD]: [],
    [CompletedOrderOrderProcessEnum.SUB]: [],
  });
  const changeHistoryOrderDetail = (
    type: CompletedOrderOrderProcessEnum,
    res: any[],
  ) => {
    setHistoryOrderDetail((prevState) => {
      return {
        ...prevState,
        [type]: [...prevState[type], ...res],
      };
    });
  };
  //已经缓存的资金记录ids
  const [historyOrderDetailIds, setHistoryOrderDetailIds] = useState<any[]>([]);
  //已经缓存的充值记录ids
  const [historyRechargeDetailIds, setHistoryRechargeDetailIds] = useState<
    any[]
  >([]);
  //已经缓存的提现记录ids
  const [historyWithdrawalDetailIds, setHistoryWithdrawalDetailIds] = useState<
    any[]
  >([]);
  //发送请求获取数据存到本地
  const getHistoryOrderDetail = (
    type: CompletedOrderOrderProcessEnum,
    ids: string[],
  ) => {
    if (+type === +CompletedOrderOrderProcessEnum.ALL) {
      fundingRecordsReq({ ids,  noMsg: true }).then(
        (res: any[]) => {
          changeHistoryOrderDetail(type, res);
          setHistoryOrderDetailIds((prevState) => {
            return [...prevState, ...ids];
          });
        },
      );
    } else if (+type === +CompletedOrderOrderProcessEnum.ADD) {
      rechargeReq({ ids, noMsg: true }).then((res: any[]) => {
        changeHistoryOrderDetail(type, res);
        setHistoryRechargeDetailIds((prevState) => {
          return [...prevState, ...ids];
        });
      });
    } else {
      withdrawReq({ ids, noMsg: true }).then((res: any[]) => {
        changeHistoryOrderDetail(type, res);
        setHistoryWithdrawalDetailIds((prevState) => {
          return [...prevState, ...ids];
        });
      });
    }
  };
  //根据最新消息列表筛选出未缓存的id，然后调用getHistoryOrderDetail方法将数据存到本地
  const findNotCachedSavaData = (msgList: any[]) => {
    //资金记录ids
    const fundingRecordsIds: any[] = [];
    //充值记录ids
    const rechargeRecordsIds: any[] = [];
    //提现记录ids
    const withdrawalRecordsIds: any[] = [];
    //根据type缓存进不同ids
    const pushTypeIds = (type: string, id: number) => {
      //判断当前缓存数组里面是否有资金记录id
      if (+type === +CompletedOrderOrderProcessEnum.ALL) {
        if (
          !fundingRecordsIds.includes(id) &&
          !historyOrderDetailIds.includes(id)
        ) {
          fundingRecordsIds.push(id);
        }
        //判断当前缓存数组里面是否有充值记录id
      } else if (+type === +CompletedOrderOrderProcessEnum.ADD) {
        if (
          !rechargeRecordsIds.includes(id) &&
          !historyRechargeDetailIds.includes(id)
        ) {
          rechargeRecordsIds.push(id);
        }
        //判断当前缓存数组里面是否有提现记录id
      } else {
        if (
          !historyWithdrawalDetailIds.includes(id) &&
          !historyWithdrawalDetailIds.includes(id)
        ) {
          withdrawalRecordsIds.push(id);
        }
      }
    };
    //根据新的ids调用请求方法
    const getTypeHistoryOrderDetail = (
      type: CompletedOrderOrderProcessEnum,
      newIds: string[],
    ) => {
      if (newIds.length > 0) {
        getHistoryOrderDetail(type, newIds);
      }
    };

    //遍历渲染数组拿到当前未缓存的id数组
    msgList?.forEach((i) => {
      const payload = toJsonData(i.payload);
      const data = toJsonData(payload?.data);
      //普通发送订单里面查找未保存的id
      if (payload?.type === ImMessageTypeEnum.ORDER) {
        pushTypeIds(data?.type, data?.id);
      }
      //回复里面查找未缓存的订单id---如果有replyId说明是回复
      if (data?.replyId && data?.content?.type === ImMessageTypeEnum.ORDER) {
        pushTypeIds(data?.content?.data?.type, data?.content?.data?.id);
      }
    });

    //筛选出为缓存的ids调用请求方法；方法请求成功后再把id存入到缓存数组中
    getTypeHistoryOrderDetail(
      CompletedOrderOrderProcessEnum.ALL,
      fundingRecordsIds,
    );
    getTypeHistoryOrderDetail(
      CompletedOrderOrderProcessEnum.ADD,
      rechargeRecordsIds,
    );
    getTypeHistoryOrderDetail(
      CompletedOrderOrderProcessEnum.SUB,
      withdrawalRecordsIds,
    );
  };

  //组件中根据type和id获取订单详情
  const getHistoryOrderDetailById = (
    type: CompletedOrderOrderProcessEnum,
    id: string,
  ) => {
    return historyOrderDetail[type]?.find((item: any) => item.id === id);
  };

  return {
    historyOrderDetail,
    setHistoryOrderDetail,
    historyOrderDetailIds,
    setHistoryOrderDetailIds,
    historyRechargeDetailIds,
    setHistoryRechargeDetailIds,
    historyWithdrawalDetailIds,
    setHistoryWithdrawalDetailIds,
    getHistoryOrderDetail,
    getHistoryOrderDetailById,
    findNotCachedSavaData,
  };
};
