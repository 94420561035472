import { CompletedOrderOrderStatusEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import SocketEnum from '@/enums/socketEnum';
import { toast } from '@/layouts/components/ToastMessage';
import { DataType } from '@/models/socket';
import { history } from '@umijs/max';
import { t } from 'i18next';
import RenderUtil from './RenderUtil';

interface AccountBalance {
  status: number;
  afterBalance: number;
  availableBalance: number;
  beforeBalance: number;
  fee: number;
  freezeBalance: number;
  fundsId: string;
  operateBalance: number;
  operateType: number;
  coinName: string;
  remark?: string;
}

export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(blob);
  });
}

/**
 * 解压 GZIP 数据
 * @param {ArrayBuffer | Uint8Array} compressedData - GZIP 压缩的二进制数据
 * @returns {string | {}} 解压后的字符串
 */
export function decompressGzip(
  compressedData: ArrayBuffer | Uint8Array,
): string | any {
  // try {
  //   // 确保传入数据是 Uint8Array 格式
  //   const uint8Array =
  //     compressedData instanceof ArrayBuffer
  //       ? new Uint8Array(compressedData) // 如果是 ArrayBuffer，先转换
  //       : compressedData;
  //
  //   // 使用 pako 解压数据
  //   const decompressed = pako.inflate(uint8Array, { to: 'string' });
  //
  //   return decompressed; // 返回解压后的字符串
  // } catch (error) {
  //   console.error('GZIP 解压失败:', error);
  //   return null;
  // }
}

/**
 * 充值 成功 socket 推送
 */
export const toastRechargeMsg = (data: AccountBalance) => {
  if (data?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS) {
    toast.error(t('充值审核不通过，请点击消息查看详情'));
  } else {
    if (location.pathname === PageEnum.TRANSACTION) {
      history.replace(PageEnum.FUNDS);
    }
    if (data?.operateBalance) {
      toast.success(
        t('充值已到账') +
          RenderUtil.formatNum(data?.operateBalance, 2) +
          data?.coinName +
          ' , ' +
          t('钱包余额') +
          ': ' +
          RenderUtil.formatNum(data?.availableBalance, 2) +
          data?.coinName,
      );
    }
  }
};

/**
 * 提现 成功 socket 推送
 */
export const toastWithdrawalMsg = (data: AccountBalance) => {
  // if (data?.status === +CompletedOrderOrderStatusEnum.WAITING_FOR_REVIEW) {
  //   toast.success(t('充值审核中，请点击消息查看详情'));
  // }
  if (+data?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS) {
    toast.error(
      t('提现审核不通过') +
        (data?.remark ? ': ' + data?.remark : t('点击查看详情')),
    );
  } else {
    toast.success(
      t('提现已到账') +
        RenderUtil.formatNum(data?.operateBalance, 2) +
        data?.coinName +
        ' , ' +
        t('当前余额') +
        RenderUtil.formatNum(data?.availableBalance, 2) +
        data?.coinName +
        ' , ',
    );
  }
};

// 订单 socket 推送合成方法
export const toastOrderMsg = (type: string, data: any) => {
  if (type) {
    switch (type) {
      case SocketEnum.CONTRACT_FORCED_LIQUIDATION:
        return toast.success(t('强平已完成'));
      case SocketEnum.ISOLATED_CONTRACT_FORCED_LIQUIDATION:
        return toast.success(
          `${t('订单号为：{{orderId}} 的订单，已完成强平', {
            orderId: data?.no,
          })}`,
        );
      case SocketEnum.CONTRACT_LIMIT:
        return toast.success(
          `${t('订单号为：{{orderId}} 的订单，限价合约订单完成', {
            orderId: data?.no,
          })}`,
        );
      case SocketEnum.CONTRACT_STOP_PROFIT_LOSS:
        return toast.success(
          `${t('订单号为：{{orderId}} 的订单，止盈止损成功', {
            orderId: data?.no,
          })}`,
        );
      case SocketEnum.CONTRACT_STOP_PROFIT:
        return toast.success(
          `${t('订单号为：{{orderId}} 的订单，止盈成功', {
            orderId: data?.no,
          })}`,
        );
      case SocketEnum.CONTRACT_STOP_LOSS:
        return toast.success(
          `${t('订单号为：{{orderId}} 的订单，止损成功', {
            orderId: data?.no,
          })}`,
        );
      case SocketEnum.CONTRACT_LEAD_TRADING:
        return toast.success(
          `${t('订单号为：{{orderId}} 的订单，跟单完成', {
            orderId: data?.no,
          })}`,
        );
      case SocketEnum.CONTRACT_LEAD_TRADING_COMPLETE:
        return toast.success(
          `${t('订单号为：{{orderId}} 的订单，平仓完成', {
            orderId: data?.no,
          })}`,
        );
      case SocketEnum.COIN_LIMIT:
        return toast.success(
          `${t('限价币币订单已成交', {
            orderId: data?.no,
          })}`,
        );
    }
  }
};

//转换json
export const toJsonData = (jsonString: string) => {
  let jsonData;

  try {
    jsonData = JSON.parse(jsonString);
  } catch (e) {
    // console.log(e);
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return jsonData;
  }
};

export function updateList(
  data: any,
  currentList: DataType[],
  setListFunction: any,
  compareKey: any,
) {
  const newData = data?.data ?? [];
  if (newData.length) {
    setListFunction((old: any) => {
      let updatedList = [...old];
      newData?.forEach((newItem: any) => {
        const index = updatedList.findIndex(
          (item: any) => item[compareKey] === newItem[compareKey],
        );
        if (index !== -1) {
          updatedList[index] = newItem;
        } else {
          updatedList.push(newItem);
        }
      });
      return updatedList;
    });
  }
}
