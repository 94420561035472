enum CacheEnum {
  //记住密码账号
  USER_TOKEN = 'USER_TOKEN',
  //记住密码密码
  USER_PASSWORD = 'USER_PASSWORD',
  // 币种存入key coin.t
  APP_LOCAL_COIN = 'APP_LOCAL_COIN',
  // 资金记录详情
  APP_FUNDS_RECORD_DETAIL = 'APP_FUNDS_RECORD_DETAIL',
  PAGE_HOME_COIN_LIST = 'PAGE_HOME_COIN_LIST',
  //现货币种缓存
  LIST_OF_CURRENCY_TYPES = 'LIST_OF_CURRENCY_TYPES',
  APP_FAVORITES = 'APP_FAVORITES',
  APP_LOCAL_EXCHANGE_DATA = 'APP_LOCAL_EXCHANGE_DATA',
  // 交易对存入key contract.t
  APP_LOCAL_STOCK = 'APP_LOCAL_STOCK',
  APP_LOCAL_CONTRACT = 'APP_LOCAL_CONTRACT',
  // im传参信息
  APP_IM_DATA = 'APP_IM_DATA',
  // 注册手机验证码
  APP_REGISTER_CODE = 'APP_REGISTER_CODE',
  // 注册邮箱验证码
  APP_REGISTER_CODE_EMAIL = 'APP_REGISTER_CODE_EMAIL',
  // 登录账号
  APP_LOGIN_NAME = 'APP_LOGIN_NAME',
  // 验证码
  APP_CODE = 'APP_CODE',
  // 匿名客服未读
  APP_ANONYMOUS_UNREAD = 'APP_ANONYMOUS_UNREAD',
  // 已读的消息id
  APP_IM_READ_MESSAGE_ID = 'APP_IM_READ_MESSAGE_ID',
  // 缓存头像
  APP_AVATAR = 'APP_AVATAR',
  // 登录密码
  APP_LOGIN_PASSWORD = 'APP_LOGIN_PASSWORD',
  // 找回密码手机验证码
  APP_FORGOT_CODE = 'APP_FORGOT_CODE',
  // 修改用户信息手机验证码
  APP_MODIFY_CODE = 'APP_MODIFY_CODE',
  // 修改用户信息邮箱验证码
  APP_MODIFY_CODE_EMAIL = 'APP_MODIFY_CODE_EMAIL',
  // 找回密码邮箱验证码
  APP_FORGOT_CODE_EMAIL = 'APP_FORGOT_CODE_EMAIL',
  PAGE_HOME_CONTRACT_LIST = 'PAGE_HOME_CONTRACT_LIST',
  PAGE_HOME_FASTCONTRACT = 'PAGE_HOME_FASTCONTRACT',
  APP_CHOICE_COIN_SELECTED = 'APP_CHOICE_COIN_SELECTED',
  // 当前币种的行情
  APP_CHOICE_CONTRACT_SOCKET = 'APP_CHOICE_CONTRACT_SOCKET',
  //币币列表
  LIST_OF_STOCK_TYPES = 'LIST_OF_STOCK_TYPES',
  // 缓存群主id
  APP_LOCAL_GROUP_ID = 'APP_LOCAL_GROUP_ID',
  // kline存入key kline.t
  KLINE_DATA_USER = 'KLINE_DATA_USER',
  // 语言存入key language.t
  I18LANGUAGE = 'LANGUAGE',
  APP_ARRANGEMENT_LANGUAGE = 'APP_ARRANGEMENT_LANGUAGE',
  APP_ARRANGEMENT_I18N = 'APP_ARRANGEMENT_I18N',
  // socket存入key socket.t
  MARKET_LIST_DATA = 'MARKET_LIST_DATA',
  SOCKET_ID_DEPTH_DATA = 'SOCKET_ID_DEPTH_DATA',
  SOCKET_ID_TRADE_DATA = 'SOCKET_ID_TRADE_DATA',
  APP_LOCAL_COIN_LIST = 'APP_LOCAL_COIN_LIST',
  APP_LOCAL_CONTRACT_LIST = 'APP_LOCAL_CONTRACT_LIST',
  APP_CONTRACT_STATISTICS = 'APP_CONTRACT_STATISTICS',
  APP_UPDATE_POSITION_DATA = 'APP_UPDATE_POSITION_DATA',
  APP_TICKLES_DATA = 'APP_TICKLES_DATA',
  APP_SELECT_COIN_DATA = 'APP_SELECT_COIN_DATA',
  //消息未数字
  APP_UNREAD_MESSAGE_COUNT = 'APP_UNREAD_MESSAGE_COUNT',
  // 主题存入key system.t
  APP_THEME = 'APP_THEME',
  // 用户存入key user.t
  APP_USER = 'APP_USER',
  AUTHORIZATION = 'AUTHORIZATION',
  // 资金和浮动盈亏
  APP_USER_AMOUNT = 'APP_USER_AMOUNT',
  // 是否隐藏小于1美元
  APP_HIDE_LOW_AMOUNT = 'APP_HIDE_LOW_AMOUNT',
  REFRESHTOKEN = 'REFRESHTOKEN',
  APP_USER_NAME = 'APP_USER_NAME',
  APP_SEARCH_TYPE = 'APP_SEARCH_TYPE',
  // 资金数据
  APP_FUNDS = 'APP_FUNDS',
  // 接口获取资金数据
  APP_FUNDSAPI = 'APP_FUNDSAPI',
  APP_WALLETSTATISTIC = 'APP_WALLETSTATISTIC', //余额，总览
  APP_WALLETFUNDAPI = 'APP_WALLETFUNDAPI', //余额，现货 资金页面
  APP_FASTWALLETAPI = 'APP_FASTWALLETAPI', //余额，快速合约 资金页面
  APP_CONTRACTWALLETAPI = 'APP_CONTRACTWALLETAPI', //余额，合约钱包
  //C2C订单步骤
  APP_C2C_ORDER_STEPS = 'APP_C2C_ORDER_STEPS', //余额，合约钱包
  //是否显示金额
  APP_SHOW_AMOUNT = 'APP_SHOW_AMOUNT',
  //充值信息
  APP_RECHARGE_INFORMATION = 'APP_RECHARGE_INFORMATION',
  //AI 列表
  APP_AI_LIST = 'APP_AI_LIST',
  ORDER_TABSELECTED = 'ORDER_TABSELECTED',
  // 邀请二维码
  APP_INVITATION_CODE = 'APP_INVITATION_CODE', 
  //App基本信息
  APP_BASE_INFO = 'APP_BASE_INFO',
  // 币种精度列表
  APP_COIN_PRECISION = 'APP_COIN_PRECISION',
  //反手提示
  APP_REVERSE_TIPS = 'APP_REVERSE_TIPS',
  //一键平仓提示是否关闭
  APP_CLOSE_REVERSE_TIPS = 'APP_CLOSE_REVERSE_TIPS',
  //只平当前提示是否关闭
  APP_ONLY_CLOSE_TIPS = 'APP_ONLY_CLOSE_TIPS',
  // 菜单缓存
  APP_MENU_LIST = 'APP_MENU_LIST',
  // 大宗交易
  APP_LOCAL_BLOCK_TRADE = 'BLOCK_TRADE',
  PAGE_HOME_BLOCK_TRADE_LIST = 'PAGE_HOME_BLOCK_TRADE_LIST',
  // 多设备确认弹窗默认持久化时间
  APP_MULTIPLE_DEVICE_CONFIRM = 'APP_MULTIPLE_DEVICE_CONFIRM',
  // 资金记录tab缓存
  APP_FUNDS_RECORD_TAB = 'APP_FUNDS_RECORD_TAB',
  //当前汇率的缓存
  APP_CURRENT_EXCHANGE_RATE_SETTING = 'APP_CURRENT_EXCHANGE_RATE_SETTING',
  APP_CURRENT_EXCHANGE_RATE_FUNDS = 'APP_CURRENT_EXCHANGE_RATE_FUNDS',
  //红涨绿跌设置
  APP_UPDOWNCOLOR_CONFIG = 'APP_UPDOWNCOLOR_CONFIG',
  //是否通过引导
  APP_IS_GUIDE = 'APP_IS_GUIDE',
  //大宗详情
  APP_BLOCK_TRADE_DETAIL = 'APP_BLOCK_TRADE_DETAIL',
  //申购详情
  APP_SUBSCRIBE_DETAIL = 'APP_SUBSCRIBE_DETAIL',
  //历史订单详情
  APP_HISTORY_ORDER_DETAIL = 'APP_HISTORY_ORDER_DETAIL',
  //客服聊天未读
  APP_CUSTOMER_SERVICE_UNREAD = 'APP_CUSTOMER_SERVICE_UNREAD',
  //C2C聊天未读
  APP_C2C_UNREAD = 'APP_C2C_UNREAD',
  //订单历史记录缓存
  APP_ORDER_RECORD = 'APP_ORDER_RECORD',
  //资金记录ids
  APP_FUNDS_RECORD_IDS = 'APP_FUNDS_RECORD_IDS',
  //充值记录ids
  APP_RECHARGE_RECORD_IDS = 'APP_RECHARGE_RECORD_IDS',
  //提现记录ids
  APP_WITHDRAWAL_RECORD_IDS = 'APP_WITHDRAWAL_RECORD_IDS',
  //注册页面缓存
  APP_REGISTER_CACHE = 'APP_REGISTER_CACHE',
  // 合约多币种持仓
  APP_CONTRACT_POSITION_LIST = 'APP_CONTRACT_POSITION_LIST',
  // 现货多币种持仓
  APP_COIN_POSITION_LIST = 'APP_COIN_POSITION_LIST',
  //找回密码数据缓存
  APP_FIND_PASSWORD_CACHE = 'APP_FIND_PASSWORD_CACHE',
  //IM消息缓存
  APP_IM_MESSAGE_CACHE = 'APP_IM_MESSAGE_CACHE',
  //版本缓存
  APP_VERSION_CACHE = 'APP_VERSION_CACHE',
  // 登录类型  2是正常登录 1是不需要登录直接进入在线客服
  APP_LOGIN_TYPE = 'APP_LOGIN_TYPE',
  APP_FASTCONTRACT = 'APP_FASTCONTRACT',
  SOCKET_COIN_DEPTH_DATA = "SOCKET_COIN_DEPTH_DATA",
  // 普通客服最后消息
  APP_SERVICE_LAST_MESSAGE = "APP_SERVICE_LAST_MESSAGE",
  // 充值客服最后消息
  APP_RECHARGE_SERVICE_LAST_MESSAGE = "APP_RECHARGE_SERVICE_LAST_MESSAGE",
}

export default CacheEnum;
