import { useState } from 'react';


export default () => {

  const [preRouter,setPreRouter] = useState('');


  return {
    preRouter,
    setPreRouter
  };
};
