import { getIntl } from '@umijs/max';
import type { ClassValue } from 'clsx';

import { toJsonData } from '@/utils/socket';
import clsx from 'clsx';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { twMerge } from 'tailwind-merge';

// 解析 后端的 bankCard 字段
export const findJsonValue = (field: string, state: any) => {
  return state?.bankCard
    ? toJsonData(state.bankCard)?.find?.((it: any) => it.field === field)?.value
    : '';
};

export const clearStorageExcept = (keysToKeep: string[]) => {
  // 保留localStorage中的指定键值
  const localStorageValues: Record<string, string | null> = {};
  keysToKeep.forEach((key) => {
    localStorageValues[key] = localStorage.getItem(key);
  });

  // 清理localStorage
  localStorage.clear();

  // 重新设置指定键值
  Object.keys(localStorageValues).forEach((key) => {
    if (localStorageValues && localStorageValues[key] !== null) {
      localStorage.setItem(key, localStorageValues[key] as string);
    }
  });
};
// export function decodeHTMLEntitiesOptimized(input: any) {
//   if (input === undefined || input === null) return '';
//   if (!decodeHTMLEntitiesOptimized.textArea) {
//     decodeHTMLEntitiesOptimized.textArea = document.createElement('textarea');
//   }
//   const textArea: any = decodeHTMLEntitiesOptimized.textArea;
//   textArea.innerHTML = input;
//   return textArea.value;
// }
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// 新版的压缩图片方案
export async function compressImageAlternative(
  file: File,
  maxWidth = 1024,
  quality = 0.9,
  targetType = 'image/jpeg', // 输出目标格式，默认使用 JPEG
) {
  try {
    // 使用 createImageBitmap 生成更高效的位图
    const imgBitmap = await createImageBitmap(file);

    let width = imgBitmap.width;
    let height = imgBitmap.height;

    // 计算新的尺寸
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }

    // 创建 OffscreenCanvas，如果浏览器不支持，则回退到普通 Canvas
    const isOffscreenCanvasSupported = 'OffscreenCanvas' in window;
    const canvas = isOffscreenCanvasSupported
      ? new OffscreenCanvas(width, height)
      : document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    // 绘制图像到 Canvas
    ctx.drawImage(imgBitmap, 0, 0, width, height);

    // 转换为 Blob
    let blob: Blob | null;
    if (isOffscreenCanvasSupported && canvas instanceof OffscreenCanvas) {
      blob = await canvas.convertToBlob({
        type: targetType,
        quality,
      });
    } else {
      blob = await new Promise<Blob | null>((resolve) => {
        (canvas as HTMLCanvasElement).toBlob(resolve, targetType, quality);
      });
    }

    if (!blob) throw new Error('Blob creation failed');

    // 创建压缩后的文件
    return new File([blob], file.name.replace(/\.\w+$/, '.jpg'), {
      type: targetType,
    });
  } catch (error) {
    console.log(error);
    throw new Error(`Image compression failed: ${error}`);
  }
}
// 判断文本是否是超链接
export const isLink = (text:string) =>{
  const regex = /(https?:\/\/[^\s]+)/g;
  const matches = text?.match?.(regex);
  return matches && matches.length > 0;
}
export const exportExcel = async (data: any, fileName?: string) => {
  const module = await import('json-as-xlsx');
  const settings = {
    fileName: fileName ?? 'export',
    extraLength: 3,
    writeMode: 'writeFile',
    writeOptions: {},
    RTL: true,
  };
  module.default(data, settings); // 使用 module.default 调用函数
};

//解析 后端的字符
export function decodeHTMLEntitiesOptimized(input: any) {
  if (input === undefined || input === null) return '';
  if (!decodeHTMLEntitiesOptimized.textArea) {
    decodeHTMLEntitiesOptimized.textArea = document.createElement('textarea');
  }
  const textArea: any = decodeHTMLEntitiesOptimized.textArea;
  textArea.innerHTML = input;
  return textArea.value;
}

export const renderI18Template = (
  template: string,
  params: any,
): { __html: string } => {
  const renderedTemplate = template.replace(/{{(\w+)}}/g, (match, p1) => {
    const paramValue = params[p1];
    if (typeof paramValue === 'string' || typeof paramValue === 'number') {
      return String(paramValue);
    } else if (React.isValidElement(paramValue)) {
      return ReactDOMServer.renderToStaticMarkup(paramValue);
    } else {
      return '';
    }
  });

  return { __html: renderedTemplate };
};
// 工具函数：将毫秒转换为 hh:mm:ss 格式
export const formatCountdown = (ms: number): string => {
  const hours = String(Math.floor(ms / (1000 * 60 * 60))).padStart(2, '0'); // 小时
  const minutes = String(
    Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60)),
  ).padStart(2, '0'); // 分钟
  const seconds = String(Math.floor((ms % (1000 * 60)) / 1000)).padStart(
    2,
    '0',
  ); // 秒
  return `${hours}:${minutes}:${seconds}`;
};

export function i18n(k: any) {
  return getIntl().formatMessage({ id: k }) ?? k;
}

/**
 * @description 生成一个指定长度的随机字符串，由数字和小写字母组成。
 * @param length 生成的随机字符串的长度。
 * @returns 生成的随机字符串。
 */
export function generateRandomString(length: number) {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
  const timestamp = new Date().getTime().toString();
  const charactersLength = characters.length;
  const timestampLength = timestamp.length;
  const randomValues = new Uint32Array(length);
  const randomBytes = crypto.getRandomValues(randomValues);
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = randomBytes[i] % charactersLength;
    result += characters.charAt(randomIndex);

    // 在随机位置插入时间戳的字符
    if (i === Math.floor(length / 2)) {
      for (let j = 0; j < timestampLength; j++) {
        const timestampIndex = randomBytes[i + j] % charactersLength;
        result += timestamp.charAt(timestampIndex);
      }
    }
  }

  return result;
}

/**
 * 手动解析url 参数
 * 下面 为 使用demo
 const urlState = useMemo(() => {
 return parseQueryString(window.location.search);
 }, [window.location.search]);
 */

export function parseQueryString(queryString: string) {
  const params = new URLSearchParams(queryString);
  const result: any = {};

  for (const [key, value] of params.entries()) {
    result[key] = value;
  }

  return result;
}


export const getIsWebView = () =>{
  let userAgent = navigator.userAgent;

  // Android 平台
  if (/Android/i.test(userAgent)) {
    if (userAgent.indexOf('wv') > -1 || userAgent.indexOf('Chrome') > -1) {
      return true; // 可能是 WebView
    }
  }

  // iOS 平台
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    // 检查 iOS WebView 或 PWA（Progressive Web App）
    if (window?.navigator?.standalone || userAgent.indexOf('Safari') === -1) {
      return true; // 可能是 WebView
    }
  }

  // 通用检测，检查 WebView 特征
  if (window?.Android || window?.webkit || window?.MSStream) {
    return true; // 有可能是 WebView
  }

  // 检查是否支持 WebView 中的特定功能，如 `window.Android` 和 `window.webkit`
  try {
    if (typeof window !== 'undefined' && window?.navigator) {
      if (window?.navigator?.standalone || userAgent.indexOf('wv') > -1 || userAgent.indexOf('WebView') > -1) {
        return true; // WebView 中
      }
    }
  } catch (e) {
    // 出现错误时，也可以作为 WebView 中的判定条件
    return true;
  }

  return false; // 默认返回不是 WebView
}
