// umiAPI/React
import { useState } from 'react';
import { useModel } from 'umi';

import { useReq } from '@/services/net/request';
//
import { useTranslation } from 'react-i18next';


// 理财列表
enum FINANCIAL_MANAGEMENT_LIST_ENUM {
    // 量化理财
    QUANTIFICATION_FINANCIAL_MANAGEMENT = '0',
    // 定期理财
    REGULAR_FINANCIAL_MANAGEMENT = '1',
  }
  
export default function () {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT);
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });

  return {
    currentTab,
    setCurrentTab,
    pageParams,
    setPageParams,
  };
}
