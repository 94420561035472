enum SocketEnum {
  /**
   * 这枚举包含全部的 socket 返回类型
   */
    // 止盈成功
  CONTRACT_STOP_PROFIT = 'CONTRACT_STOP_PROFIT',

  // 止损成功
  CONTRACT_STOP_LOSS = 'CONTRACT_STOP_LOSS',

  // 实时买卖价格，时间
  EXCHANGE_DEPTH = 'EXCHANGE_DEPTH',

  //返回的市场信息 ，数组格式
  EXCHANGE_RATE = 'EXCHANGE_RATE',

  //订单
  CONTRACT_ORDER = 'CONTRACT_ORDER',

  //资金
  ACCOUNT_INFO = 'ACCOUNT_INFO',
  //股票订单
  STOCK_ORDER = 'STOCK_ORDER',

  // 资金
  FAIT_INFO = 'FAIT_INFO',
  // 合约
  CONTRACT_INFO = 'CONTRACT_INFO',

  /**
   * 充值审核通知
   */
  RECHARGE_SYSTEM_NOTICE = 'RECHARGE_SYSTEM_NOTICE',
  /**
   * vip审核通知
   */
  VIP_AUDIT = 'VIP_AUDIT',
  /**
   * 提现审核通知
   */
  WITHDRAWAL_SYSTEM_NOTICE = 'WITHDRAWAL_SYSTEM_NOTICE',
  /**
   * 内测弹窗
   */
  INTERNAL_BETA_ORDER_EXPIRED = 'INTERNAL_BETA_ORDER_EXPIRED',
  /**
   * 体验金到期
   */
  EXPERIENCE_FUND_EXPIRED = 'EXPERIENCE_FUND_EXPIRED',
  /**
   * 钱包金额变更通知
   */
  WALLET_UPDATE_SYSTEM_NOTICE = 'WALLET_UPDATE_SYSTEM_NOTICE',
  /**
   * 未读消息通知
   */
  UNREAD_MESSAGE_SYSTEM_NOTICE = 'UNREAD_MSG_COUNT',
  // 账户停用
  ACCOUNT_DELETE = 'ACCOUNT_DELETE',
  // 账户退出登陆
  ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT',
  // 后台平仓
  CONTRACT_BACKEND_CPMPLEAE = 'CONTRACT_BACKEND_CPMPLEAE',
  VERSION = 'VERSION_UPDATE',

  /**
   * 全仓合约订单强平
   */
  CONTRACT_FORCED_LIQUIDATION = 'CONTRACT_FORCED_LIQUIDATION',
  /**
   * 逐仓合约订单强平
   */
  ISOLATED_CONTRACT_FORCED_LIQUIDATION = 'ISOLATED_CONTRACT_FORCED_LIQUIDATION',
  /**
   * 限价合约订单完成
   */
  CONTRACT_LIMIT = 'CONTRACT_LIMIT',

  /**
   * 合约订单止盈止损完成
   */
  CONTRACT_STOP_PROFIT_LOSS = 'CONTRACT_STOP_PROFIT_LOSS',
  /**
   * 合约带单跟单完成
   */
  CONTRACT_LEAD_TRADING = 'CONTRACT_LEAD_TRADING',
  /**
   * 合约带单平仓完成
   */
  CONTRACT_LEAD_TRADING_COMPLETE = 'CONTRACT_LEAD_TRADING_COMPLETE',
  /**
   * 币币委托成功
   */
  COIN_LIMIT_BY = 'COIN_LIMIT_BY',

  /**
   * 后台消息推送
   */
  ADMIN_ACTIVE = 'ADMIN_ACTIVE',

  // 更新k线 ，返回订阅列表
  EXCHANGE_CANDLESTICK = 'EXCHANGE_CANDLESTICK',
  // 多设备登录类型
  FORCE_LOGOUT = 'FORCE_LOGOUT',
  GET_FUNDS = 'GET_FUNDS',
  OTHER_LOGIN = 'OTHER_LOGIN',
  // 设备登录确认
  CAN_LOGIN = 'CAN_LOGIN',
  /**
   * 币币限价完成
   *
   */
  COIN_LIMIT = 'COIN_LIMIT',
  /**
   * 更新资金
   */
  WALLET_UPDATE = 'WALLET_UPDATE',
  /**
   * 后端推送资金相关数据
   */
  NEW_ACCOUNT_STATISTICS = 'NEW_ACCOUNT_STATISTICS',
  EXCHANGE_COIN_DEPTH = 'EXCHANGE_COIN_DEPTH',
  REFRESH_INTERFACE = 'REFRESH_INTERFACE',
  // 合约资金
  FUNDING_RATE = 'FUNDING_RATE',
  // 刷新用户信息
  MEMBER_INFO_UPDATE = 'MEMBER_INFO_UPDATE',
  // 实名拒绝
  REAL_NAME_REJECT = 'REAL_NAME_REJECT',
  // 提现拒绝
  WITHDRAW_REJECT = 'WITHDRAW_REJECT',
  // 通知弹窗
  APP_NOTICE = 'APP_NOTICE'
}

export default SocketEnum;
