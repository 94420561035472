import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import { useLocalStorage } from '@/hooks/useStorage';
import { getTradingSymbolsApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useEffect, useState } from 'react';

export interface TradePair {
  transactionAccuracy: number;
  alias: string;
  baseId: string;
  baseSymbol: string;
  coin: string[];
  commission: number;
  commissionType: number;
  createTime: number;
  exchange: string;
  exchangeId: string;
  exchangeSymbol: string;
  id: string;
  imgUrl: string | null;
  name: string;
  priceAccuracy: number;
  remark: string | null;
  sort: number;
  startTime: number | null;
  status: number;
  symbol: string;
  symbolId: string;
  type: number;
  visible: number;
}

export default () => {

  const { sendMsg, isSub } = useModel('socket');
  // 用于首页更新数据选择数据【二级菜单】
  const [choiceCoinSelected,setChoiceCoinSelected] = useState("")
  //选中币种
  const [localCoin, setLocalCoin]: [coin: TradePair, setCoin: any] =
    useLocalStorage({
      key: CacheEnum.APP_LOCAL_COIN,
      defaultValue: null,
    });

  // 异步获取合约缓存进本地
  const {
    data: coinListData = [] as any,
    run: getTheListOfCoins,
    loading: coinListLoading,
  } = useReq(getTradingSymbolsApi, {
    loadingDefault: false,
    manual: true,
    // cacheKey: 'coinListData',
    formatResult: (res: any) => {
      const { list } = res?.data || [];
      return list ?? [];
    },
    onSuccess: (res: any) => {
      // 成功获取数据后，更新本地存储和发送订阅消息
    },
  });

  const pageKey = usePageRefreshOnVisible();

  useEffect(() => {

    // 初始化 订阅 和设置数据
    if (!localCoin?.id && coinListData?.length) {
      sendMsg(
        `{"type":"SUB","data": ${JSON.stringify(
          coinListData?.map?.((i: { symbol: any }) => i?.symbol),
        )}}`,
      );
      if (!localCoin) {
        setLocalCoin(coinListData[0]);
      } else {
        const finditem = coinListData.find?.((i: any) => i?.id === localCoin?.id);
        if (!finditem) {
          setLocalCoin(coinListData[0]);
        }
      }
    } else if (coinListData?.length) {
      const find = coinListData?.find?.(
        (item: any) => localCoin?.id === item?.id,
      );
      if (find) {
        setLocalCoin(find);
      } else {
        sendMsg(
          `{"type":"SUB","data": [${JSON.stringify(localCoin?.symbol)}]}`,
        );
      }
    }
  }, [coinListData, pageKey, isSub]);

  // 获取用户信息
  const { user } = useModel('user');

  const { sortState, isHomeSort, coinSelected, sortType } = useModel('system');

  // // 监听用户信息变化
  useEffect(() => {
    if ((user?.id && !coinListData.length) || !localCoin) {
      getTheListOfCoins({
        pageNo: 1,
        pageSize: 15,
        plate: coinSelected,
        type: GetCoinListTypeEnum.COIN,
      });
    }
  }, [user]);

  useUpdateEffect(() => {
    if (user?.id && isHomeSort && sortType === GetCoinListTypeEnum.COIN) {
      getTheListOfCoins({
        pageNo: 1,
        pageSize: 15,
        plate: coinSelected,
        type: GetCoinListTypeEnum.COIN,
        ...sortState,
      });
    }
  }, [isHomeSort]);

  useUpdateEffect(() => {
    if (user?.id  && isHomeSort && sortType === GetCoinListTypeEnum.COIN) {
      getTheListOfCoins({
        pageNo: 1,
        pageSize: 15,
        plate: coinSelected,
        type: GetCoinListTypeEnum.COIN,
        ...sortState,
      });
    }
  }, [sortState, coinSelected]);

  useUpdateEffect(() => {
    if (window.location.pathname==="/home") {
      getTheListOfCoins({
        pageNo: 1,
        pageSize: 15,
        plate: choiceCoinSelected,
        type: GetCoinListTypeEnum.COIN,
        ...sortState,
      });
    }
  }, [coinSelected]);

  return {
    coinListData,
    coinListLoading,
    localCoin,
    choiceCoinSelected,
    setChoiceCoinSelected,
    setLocalCoin,
    getTheListOfCoins,
  };
};
