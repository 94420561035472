import RequestUrl from '@/services/api/requestUrl';
import { BaseGet, BasePost, BasePut } from '@/services/net/request';

// 这个文件输出全部接口

//------------------system--------------------------
//注册会员
export async function registerApi(params: any) {
  return BasePost(RequestUrl.register, params);
}

// 退出登录
export async function logoutApi() {
  return BaseGet(RequestUrl.loginOut); 
}

// 获取币种精度列表
export async function getCoinPrecisionApi() {
  return BaseGet(RequestUrl.getCoinPrecisionUrl);
}

//刷新token
export async function refreshTokenApi(params: any) {
  return BaseGet(RequestUrl.refreshToken, params);
}
// 获取 首页弹框消息
export async function getHomeNoticeApi(data?: any) {
  return BaseGet(RequestUrl.getHomeNotice, data);
}

// 通过充值通道id查询充值通道信息
export async function getRechargeChannelByIdApi(params: any) {
  return BaseGet(RequestUrl.getRechargeChannelByIdUrl, params);
}

// 通道id查询群聊列表
export async function getGroupListApi(params: any) {
  return BaseGet(RequestUrl.getGroupListUrl, params);
}

// 根据用户id查询用户头像和客服昵称
export async function getUserInfoCustomerServiceApi(data:any) {
  return BasePost(RequestUrl.getUserInfoCustomerServiceUrl,data);
}

// 判断是否重复登录
export async function validRepeatLoginApi() {
  return BasePost(RequestUrl.validRepeatLoginUrl);
}

// 获取贷款专属客服
export async function getCustomerServiceApi(params: any) {
  return BaseGet(RequestUrl.getCustomerServiceUrl, params);
}

// 拒绝退还
export async function rejectRefundApi(params: any) {
  return BaseGet(RequestUrl.rejectRefundUrl, params);
}

// 内测弹窗确认接口
export async function closedBetaApi(params: any) {
  return BaseGet(RequestUrl.closedBetaUrl, params);
}

// 提前结算
export async function earlySettlementApi(params: any) {
  return BasePut(RequestUrl.earlySettlementUrl, params);
}

// 商家充值 获取充值类型的币种
export async function getRechargeTypeApi(params: any) {
  return BaseGet(RequestUrl.getRechargeCoinUrl, params);
}

//获取C2C列表
export async function getC2CListApi(params: any) {
  return BaseGet(RequestUrl.C2CListUrl, params);
}

// 获取资金相关数据接口

export async function fundingOverviewSocketMapApi() {
  return BaseGet(RequestUrl.fundingOverviewSocketMapUrl);
}

//忘记密码
export async function retrievePasswordApi(params: any) {
  return BasePost(RequestUrl.retrievePasswordUrl, params);
}

// 借贷额度 page
export async function loanPageApi(params: any) {
  return BaseGet(RequestUrl.loanPage, params);
}

// 获取持仓或者委托中的币种id和对应的杠杆
export async function positionAndEntrustmentApi(params?: any) {
  return BaseGet(RequestUrl.positionAndEntrustmentUrl, params);
}

//选中客服
export async function selectCustomerServiceApi(data: any) {
  return BaseGet(RequestUrl.selectCustomerServiceUrl, data);
}

// 上传签名
export async function uploadSignatureApi(data: any) {
  return BasePost(RequestUrl.uploadSignatureUrl, data, { headers: { 'Content-Type': 'multipart/form-data' } });
}

// 借贷额度信息
export async function loanDataUrlApi(params: any) {
  return BaseGet(RequestUrl.loanDataUrl, params);
}

// 借贷额度信息
export async function createloanUrlApi(params: any) {
  return BasePost(RequestUrl.createloanUrl, params);
}

//获取验证码
export async function getCaptchaApi(params: any) {
  return BaseGet(RequestUrl.getCaptcha, params);
}

//获取虚拟币充值通道
export async function getRechargeVirtualCurrencyChannelApi(data: any) {
  return BaseGet(RequestUrl.getRechargeVirtualCurrencyChannelUrl, data);
}

//获取银行卡充值通道
export async function getBankRechargeChannelApi(data: any) {
  return BaseGet(RequestUrl.getBankRechargeChannelUrl, data);
}

//发送邮件
export async function sendMailApi(params: any) {
  return BaseGet(RequestUrl.sendMail, params);
}

//登录
export async function loginApi(data: any) {
  return BasePost(RequestUrl.loginUrl, data);
}

//字典获取接口
export async function getDictionaryApi(params: any) {
  return BaseGet(RequestUrl.getDictionaryUrl, params);
}

//确认划转
export async function transferApi(data: any) {
  return BasePost(RequestUrl.transferUrl, data);
}

//------------------system--------------------------

//------------------mine--------------------------
//获取汇率列表
export async function exchangeConfigApi(data: any) {
  return BaseGet(RequestUrl.exchangeConfigUrl, data);
}

// 获取充值提现汇率列表
export async function getExchangeRateApi(data: any) {
  return BaseGet(RequestUrl.getExchangeRateUrl, data);
}

//获得当前会员的所有可用银行卡信息列表
export async function getPersonBankListApi() {
  return BaseGet(RequestUrl.getPersonBankListUrl);
}

// 银行列表
export async function getBankListApi() {
  return BaseGet(RequestUrl.getBankListUrl);
}

//新增银行卡
export async function addBankApi(data: any) {
  return BasePost(RequestUrl.addBankUrl, data);
}

//修改银行卡
export async function editBankApi(data: any) {
  return BasePost(RequestUrl.editBankUrl, data);
}

//获取单个银行卡信息
export async function getBankCardInformationApi(data: any) {
  return BaseGet(RequestUrl.getBankCardInformationUrl, data);
}

//获得当前会员的所有可用虚拟卡信息列表
export async function getPersonVirtualListApi() {
  return BaseGet(RequestUrl.getPersonVirtualListUrl);
}

// 虚拟网络列表
export async function getVirtualListApi() {
  return BaseGet(RequestUrl.getVirtualListUrl);
}

//新增虚拟卡
export async function addVirtualApi(data: any) {
  return BasePost(RequestUrl.addVirtualUrl, data);
}

//修改虚拟卡
export async function editVirtualApi(data: any) {
  return BasePost(RequestUrl.editVirtualUrl, data);
}

//获取单个虚拟卡信息
export async function getIndividualVirtualCardInformationApi(data: any) {
  return BaseGet(RequestUrl.getIndividualVirtualCardInformationUrl, data);
}

//获取提现手续费
export async function getWithdrawalFeesApi(data: any) {
  return BaseGet(RequestUrl.getWithdrawalFeesUrl, data);
}

//获取费率
export async function getCardValueApi(data: any) {
  return BaseGet(RequestUrl.getCardValueUrl, data);
}

//获取所有菜单配置
export async function getMenuListApi(data: any) {
  return BaseGet(RequestUrl.getMenuListUrl, data);
}

//一键平仓
export async function closeAPositionWithOneClickApi(data: any) {
  return BasePost(RequestUrl.closeAPositionWithOneClick, data);
}

//获取轮播图
export async function getCarouselImageApi(data: any) {
  return BaseGet(RequestUrl.getCarouselImageUrl, data);
}

//获取充值通道列表
export async function getChannelListApi(data: any) {
  return BaseGet(RequestUrl.getChannelListUrl, data);
}

//验证通道密码
export async function verifyChannelPasswordApi(data: any) {
  return BasePost(RequestUrl.verifyChannelPasswordUrl, data);
}

//获取用户Udun充值地址
export async function getUdunRechargeAddressApi(data: any) {
  return BaseGet(RequestUrl.getUdunRechargeAddressUrl, data);
}

//获取充值信息
export async function rechargeInformationApi(data: any) {
  return BaseGet(RequestUrl.rechargeInformationUrl, data);
}

//上传付款凭证
export async function uploadPaymentVoucherApi(data: any) {
  return BasePost(RequestUrl.uploadPaymentVoucherUrl, data);
}

//点击充值拿到二维码地址
export async function rechargeFiatCurrencyApi(data: any) {
  return BasePost(RequestUrl.rechargeFiatCurrencyUrl, data);
}

//获取隐私协议
export async function getPrivacyAgreementApi(data: any) {
  return BaseGet(RequestUrl.getPrivacyAgreementUrl, data);
}

// 一键已读订单信息
export async function readOrderApi(data: any) {
  return BaseGet(RequestUrl.readOrderUrl, data);
}

//获取帮助中心列表
export async function getHelpCenterListApi(data: any) {
  return BaseGet(RequestUrl.getHelpCenterListUrl, data);
}

// 归还体验金
export async function returnExperiencePriceApi() {
  return BasePost(RequestUrl.returnExperiencePriceUrl);
}

//获取帮助中心详情
export async function getHelpCenterDetailApi(data: any) {
  return BaseGet(RequestUrl.getHelpCenterDetailUrl, data);
}

//新增意见反馈
export async function submitFeedbackApi(data: any) {
  return BasePost(RequestUrl.submitFeedbackUrl, data);
}

//实名认证
export async function userRealnameApi(data: any) {
  return BasePost(RequestUrl.userRealname, data);
}

//修改邮箱
export async function modifyEmailApi(data: any) {
  return BasePut(RequestUrl.modifyEmailUrl, data);
}

//修改手机号
export async function modifyMobilePhoneNumberApi(data: any) {
  return BasePut(RequestUrl.modifyMobilePhoneNumberUrl, data);
}

//修改头像和昵称
export async function modifyUserInformationApi(data: any) {
  return BasePut(RequestUrl.modifyUserInformationUrl, data);
}

//购买现货
export async function buyCoinApi(data: any) {
  return BasePost(RequestUrl.buyCoinUrl, data);
}

//获取订单列表 （股票）
export async function getOrderList(data: any) {
  return BaseGet(RequestUrl.getOrderUrl, data);
}

//修改用户密码
export async function changeUserPasswordApi(data: any) {
  return BasePost(RequestUrl.changeUserPasswordUrl, data);
}

//修改支付密码
export async function changePaymentPasswordApi(data: any) {
  return BasePost(RequestUrl.changePaymentPasswordUrl, data);
}

//获取AI订单精简信息列表
export async function getAIListApi(data: any) {
  return BaseGet(RequestUrl.getAIListUrl, data);
}

// 我的邀请统计信息
export async function getInviteStatisticsApi() {
  return BaseGet(RequestUrl.getInviteStatisticsUrl);
}

// 获取邀请详情列表
export async function getInviteListApi(data: any) {
  return BaseGet(RequestUrl.getInviteListUrl, data);
}

//购买AI订单
export async function buyAIApi(data: any) {
  return BasePost(RequestUrl.buyAIUrl, data);
}

//------------------mine--------------------------

//------------------user--------------------------
// 获取用户信息
export async function getUserInformation() {
  return BaseGet(RequestUrl.getUserInfoUrl + '',{noMsg: true});
}

//------------------user--------------------------

//------------------股票--------------------------
// 获得交易合约符号信息分页
export async function getTradingSymbolsApi(data: any) {
  return BaseGet(RequestUrl.getTradingSymbolsUrl, data);
}

//更新止盈止损
export async function updateTpSlApi(data: any) {
  return BasePost(RequestUrl.updateTpSl, data, { params: data });
}

//合约
export async function fiatCurrencyBalanceApi() {
  return BaseGet(RequestUrl.contractWallet);
}

//钱包总览
export async function walletStatisticApi() {
  return BaseGet(RequestUrl.walletStatistic);
}

//钱包现货 -2
export async function walletFundApi() {
  return BaseGet(RequestUrl.walletFund);
}

//快速合约钱包
export async function fastWalletApi() {
  return BaseGet(RequestUrl.fastWalletUrl);
}

//快速合约 订单详情
export async function fastOrderDetailApi(data: any) {
  return BaseGet(RequestUrl.fastOrderDetailUrl, data);
}

// 资金记录
export async function fundsListApi(data: any) {
  return BaseGet(RequestUrl.fundsList, data);
}

// 新资金流水
export async function fundsListNewApi(data: any) {
  return BaseGet(RequestUrl.fundsListNewUrl, data);
}

// 获取im群列表
export async function imGroupListApi(data: any) {
  return BaseGet(RequestUrl.imGroupListUrl, data);
}

// 申请Vip
export async function applyVipApi(data: any) {
  return BaseGet(RequestUrl.createVipOrderUrl, data);
}

// ai订单记录
export async function aiOrderListApi(params: any) {
  return BaseGet(RequestUrl.aiOrderListUrl, params);
}

// ai子订单
export async function aiChildOrderListApi(params: any) {
  return BaseGet(RequestUrl.aiChildOrderListUrl, params);
}

//ai详情
export async function aiOrderDetailApi(data: any) {
  return BaseGet(RequestUrl.aiOrderDetailUrl, data);
}

// ai 子仓列表
export async function aiPageSubApi(data: any) {
  return BaseGet(RequestUrl.aiPageSubUrl, data);
}

// ai 子仓 详情
export async function aiChildOrderDetailSubApi(data: any) {
  return BaseGet(RequestUrl.aiChildOrderDetailSubUrl, data);
}

//获取充值记录
export async function rechargeInformationListApi(data: any) {
  return BaseGet(RequestUrl.rechargeInformationListUrl, data);
}

//获取提现记录
export async function withdrawalListApi(data: any) {
  return BaseGet(RequestUrl.withdrawalListUrl, data);
}

//创建订单
export async function createOrderApi(data: any) {
  return BasePost(RequestUrl.createOrderUrl, data);
}

//撤销订单
export async function closeOrderApi(data: any) {
  return BaseGet(RequestUrl.closeOrderUrl, data);
}

//平仓合约订单
export async function closingOrderApi(data: any) {
  return BaseGet(RequestUrl.closingOrderUrl, data);
}

//获取订单列表
export async function getStockOrderListApi(data: any) {
  return BaseGet(RequestUrl.getStockOrderListUrl, data);
}

//获取订单详情
export async function getOrderDetail(data: any) {
  return BaseGet(RequestUrl.getOrderDetailUrl, data);
}

//获取历史订单详情
export async function historicalOrderDetailsApi(data: any) {
  return BaseGet(RequestUrl.historicalOrderDetailsUrl, data);
}

//------------------home--------------------------
//获取新闻列表
export async function getNewListApi(data: any) {
  return BaseGet(RequestUrl.getNewListUrl, data);
}

/**
 * # 获取新闻详情
 * @param id
 * @returns
 */
export async function getNewsDetail(data: any) {
  return BaseGet(RequestUrl.getNewDetailUrl, data);
}

// 获取K线
export async function getCandlestickApi(data: any) {
  return BaseGet(RequestUrl.getCandlestick, data);
}

// 获取交易数据
export async function getChartPageInfoApi(data: any = {}) {
  return BaseGet(RequestUrl.getChartPageInfo, data);
}

// 符号详情
export async function getCoinDetailApi(data: any) {
  return BaseGet(RequestUrl.coinDetail, data);
}

// 论坛分页
export async function getForumMessagePage(data: any) {
  return BaseGet(RequestUrl.getPageListForum, data);
}

// 论坛详情
export async function getForumDetail(params: any) {
  return BaseGet(RequestUrl.getDetailForum, params);
}

// 论坛点赞
export async function formThumbsUp(params: any) {
  return BaseGet(RequestUrl.thumbsUp, params);
}

// 论坛查看
export async function ForumLook(params: any) {
  return BaseGet(RequestUrl.look, params);
}

//------------------home--------------------------

//------------------funds--------------------------
//获取可用余额
export async function getAvailableBalanceApi(data: any) {
  return BaseGet(RequestUrl.getAvailableBalanceUrl, data);
}

//提现
export async function withdrawApi(data: any) {
  return BasePost(RequestUrl.withdrawUrl, data);
}

//获取可选币种
export async function getOptionalCurrenciesApi(data: any) {
  return BaseGet(RequestUrl.getOptionalCurrenciesUrl, data);
}

//获取现货和合约币种
export async function spotAndContractCurrenciesApi(data: any) {
  return BaseGet(RequestUrl.spotAndContractCurrenciesUrl, data);
}

//获取所有币种
export async function getAllCurrenciesApi(data?: any) {
  return BaseGet(RequestUrl.getAllCurrenciesUrl, data);
}

//获取币种下的网络
export async function getCoinNetworkApi(data: any) {
  return BaseGet(RequestUrl.getCoinNetworkUrl, data);
}

//------------------funds--------------------------

/**
 * # 新增会员收藏币对
 */
export async function addMemberCollectApi(data: any) {
  return BasePost(RequestUrl.addMemberCollect, data);
}

/**
 * # 分页获取会员收藏币对
 */
export async function getMemberCollectApi(data: any) {
  return BaseGet(RequestUrl.getMemberCollect, data);
}

/**
 * # 删除会员收藏币对
 */
export async function deleteMemberCollectApi(data: any) {
  return BaseGet(RequestUrl.deleteMemberCollect, data);
}

/**
 * # 检查币对收藏状态
 */

export async function checkCollectApi(data: any) {
  return BaseGet(RequestUrl.checkCollect, data);
}

// --------------------申购--------------------------
/**
 * # 申购列表
 */
export async function getSubscriptionListApi(data: any) {
  return BaseGet(RequestUrl.getSubscriptionList, data);
}

// --------------------申购--------------------------
// --------------------消息--------------------------
/**
 * # 公告列表
 */
export async function getNoticeListApi(data: any) {
  return BaseGet(RequestUrl.getNoticeList, data);
}

/**
 * # 新闻列表
 */
export async function getNewsListApi(data: any) {
  return BaseGet(RequestUrl.getNewsList, data);
}

/**
 * # 订单消息
 */
export async function orderMessageApi(data: any) {
  return BaseGet(RequestUrl.orderMessageUrl, data);
}

//消息类型
export async function getNoticeType(data: any) {
  return BaseGet(RequestUrl.getNoticeType, data);
}

//已读标记
export async function sendReadMessageApi(data: any) {
  return BaseGet(RequestUrl.sendReadMessageUrl, data);
}

// --------------------国际化--------------------------
export async function getLangListApi(data: any) {
  return BaseGet(RequestUrl.getLangList, data);
}

// 或者某一个语言的国际化json
export async function getLangJsonApi(data: any) {
  return BaseGet(RequestUrl.getLangJson, data);
}

//限制价格平仓
export async function limitCompleteApi(data: any) {
  return BaseGet(RequestUrl.limitComplete, data);
}

// --------------------大宗--------------------------
/**
 * # 大宗列表
 */
export async function getBulkListApi(data: any) {
  return BaseGet(RequestUrl.getBulkList, data);
}

/**
 * # 获取大宗详情
 */
export async function getBulkDetailApi(data: any) {
  return BaseGet(RequestUrl.getBulkDetail, data);
}

/**
 * # 创建大宗订单
 */
export async function createBulkOrderApi(data: any) {
  return BasePost(RequestUrl.createBulkOrder, data);
}

// --------------------大宗--------------------------

/**
 * # 申购购买
 */
export async function buySubscription(data: any) {
  return BasePost(RequestUrl.buySubscription, data);
}

/**
 * # 交易申购信息订单分页
 */
export async function getTransactionSubscription(data: any) {
  return BaseGet(RequestUrl.getTransactionSubscription, data);
}

/**
 * # 增加浏览量
 */
export async function addView(data: any) {
  return BaseGet(RequestUrl.addView, data);
}

/**
 * # 充值详情
 */
export async function rechargeDetail(data: any) {
  return BaseGet(RequestUrl.rechargeDetail, data);
}

/**
 * # 提现详情
 */
export async function withdrawDetail(data: any) {
  return BaseGet(RequestUrl.withdrawDetail, data);
}
/**
 * # 取消提现
 */
export async function cancelWithdrawApi(params: any) {
  return BasePut(`${RequestUrl.cancelWithdrawUrl}/${params?.id}`);
}
// --------------------在线客服--------------------------
/**
 * 发送消息
 */
export async function sendImMessageApi(data: any) {
  return BasePost(RequestUrl.sendImMessageUrl, data);
}

/**
 * 撤回消息
 */
export async function withdrawMessageApi(data: any) {
  return BasePost(RequestUrl.withdrawMessageUrl, data);
}

/**
 * 获取列表
 */
export async function getChartList(data: any) {
  return BaseGet(RequestUrl.chartList, data);
}

/**
 * 获取消息列表
 */
export async function getChartMessageList(data: {
  groupId: string;
  timestamp: undefined | null;
}) {
  return BasePost(RequestUrl.chartRecord, data);
}

/**
 * 获取资金记录详情列表
 */
export async function fundsListDetailApi(data: any) {
  return BasePost(RequestUrl.fundsListDetailUrl, data);
}

/**
 * 获取充值记录详情列表
 */
export async function rechargeDetailApi(data: any) {
  return BasePost(RequestUrl.rechargeDetailUrl, data);
}

/**
 * 获取提现记录详情列表
 */
export async function withdrawalDetailApi(data: any) {
  return BasePost(RequestUrl.withdrawalDetailUrl, data);
}

/**
 * 获取工单
 */
export async function getAWorkOrderApi(data: any) {
  return BaseGet(RequestUrl.getAWorkOrderUrl, data);
}

/**
 * 工单评分
 */
export async function ticketScoringApi(data: any) {
  return BasePost(RequestUrl.ticketScoringUrl, data);
}

/**
 * 亚马逊S3上传文件
 */
export async function uploadFilesS3Api(data: any) {
  return BasePost(RequestUrl.uploadFilesS3Url, data);
}

/**
 * 获取区号
 * */
export async function getAreaCodeApi(data: any) {
  return BaseGet(RequestUrl.getAreaCodeUrl, data);
}

/**
 * 获取返佣总奖励
 * */
export async function getRebateTotalApi(data: any) {
  return BaseGet(RequestUrl.getRebateTotalUrl, data);
}

/**
 * 获取返佣排行榜
 * */
export async function getRankedListApi(data: any) {
  return BaseGet(RequestUrl.getRankedListUrl, data);
}

/**
 * 获取邀请码列表
 * */
export async function getInvitationCodeListApi(data: any) {
  return BaseGet(RequestUrl.getInvitationCodeListUrl, data);
}

/**
 * 创建邀请码
 * */
export async function createInvitationCodeApi(data: any) {
  return BasePost(RequestUrl.createInvitationCodeUrl, data);
}

/**
 * 修改邀请码
 * */
export async function modifyInvitationCodeApi(data: any) {
  return BasePut(RequestUrl.modifyInvitationCodeUrl, data);
}

/**
 * 获取邀请码好友
 * */
export async function getInvitationCodeFriendApi(data: any) {
  return BaseGet(RequestUrl.getInvitationCodeFriendUrl, data);
}

/**
 * 获取会员邀请信息--等级和返佣比例
 * */
export async function getMemberInvitationInformationApi(data: any) {
  return BaseGet(RequestUrl.getMemberInvitationInformationUrl, data);
}

/**
 * 获取当前默认邀请码
 * */
export async function getTheCurrentDefaultInvitationCodeApi(data: any) {
  return BaseGet(RequestUrl.getTheCurrentDefaultInvitationCodeUrl, data);
}

/**
 * 邀请等级列表和对应的佣金比例
 * */
export async function getTierListApi(data: any) {
  return BaseGet(RequestUrl.getTierListUrl, data);
}

/**
 * 获取外汇前三名K线数据
 * */
export async function getTopThreeKLinesInForeignExchangeApi(data: any) {
  return BaseGet(RequestUrl.getTopThreeKLinesInForeignExchangeUrl, data);
}

/**
 * 提现通道获取
 */
export async function getWithdrawalChannelApi(data: any) {
  return BaseGet(RequestUrl.getWithdrawalChannelUrl, data);
}

/**
 * 虚拟钱包列表
 */
export async function getVirtualWalletListApi(data: any) {
  return BaseGet(RequestUrl.getVirtualWalletListUrl, data);
}

//获取合约订单列表
export async function getContractOrderListApi(data: any) {
  return BaseGet(RequestUrl.getContractOrderListUrl, data);
}

//获取快速合约订单列表
export async function getFastContractOrderListApi(data: any) {
  return BaseGet(RequestUrl.getFastContractOrderListUrl, data);
}

//获取当前合约下的最大可交易数和最小交易数和费率
export async function getContractOrderConfigApi(data: any) {
  return BaseGet(RequestUrl.getContractOrderConfigUrl, data);
}

//创建合约订单  根据 基币数量 下单
export async function createContractOrderApi(data: any) {
  return BasePost(RequestUrl.createContractOrderUrl, data);
}

//创建合约订单 根据 usdt 下单
export async function createSpecifyUrlApi(data: any) {
  return BasePost(RequestUrl.createSpecifyUrl, data);
}

// 设备登录确认[保持登录]
export async function confirmDeviceLoginApi() {
  return BasePost(RequestUrl.confirmDeviceLoginUrl);
}

// 设备登录确认[取消登录]
export async function cancelDeviceLoginApi() {
  return BasePost(RequestUrl.cancelDeviceLoginUrl);
}

// 创建快速合约订单
export async function createQuickOrderUrlApi(data: any) {
  return BasePost(RequestUrl.createQuickOrderUrl, data);
}

//平仓合约订单
export async function closingContractOrderApi(data: any) {
  return BasePost(RequestUrl.closingContractOrderUrl, data);
}

// 反选平仓
export async function reverseSelectionToClosePositionApi(data: any) {
  return BaseGet(RequestUrl.reverseSelectionToClosePositionUrl, data);
}

// 一键反向
export async function reverseAllApi(data: any) {
  return BaseGet(RequestUrl.reverseAllUrl, data);
}

//撤销合约订单
export async function closeContractOrderApi(data: any) {
  return BaseGet(RequestUrl.closeContractOrderUrl, data);
}

//获取合约订单详情
export async function getContractOrderDetail(data: any) {
  return BaseGet(RequestUrl.getContractOrderDetialUrl, data);
}

// vip 列表
export async function getvipLevelConfig(params?: any) {
  return BaseGet(RequestUrl.getvipLevelConfigUrl, params);
}

//vip购买
export async function createVipOrder(params?: any) {
  return BasePost(RequestUrl.createVipOrder, params);
}

// 首页获取未读公告
export async function getUnreadNotice(params?: any) {
  return BaseGet(RequestUrl.getUnreadNoticeUrl, params);
}

// 首页公告通知获取已读
export async function getReadNotice(data?: any) {
  return BasePost(RequestUrl.getReadNoticeUrl, data);
}

// 币币列表
export async function symbolCoinListApi(data: any) {
  return BaseGet(RequestUrl.symbolCoinList, data);
}

//取消现货交易
export async function closeCoinOrderApi(data: any) {
  return BaseGet(RequestUrl.closeCoinOrder + data);
}

//获取订单列表
export async function getCoinOrderList(data: any) {
  return BaseGet(RequestUrl.coinOrderUrl, data);
}

// 币币订单获取
export async function getCoinOrderPageList(params: any) {
  return BaseGet(RequestUrl.getCoinOrderList, params);
}

// 币币订单详情
export async function getCoinOrderDetail(data: any) {
  return BaseGet(RequestUrl.getCoinOrderDetail, data);
}

// 闪兑详情
export async function getCoinTradeDetailApi(data: any) {
  return BaseGet(RequestUrl.getCoinTradeDetail, data);
}

// 划转详情
export async function transferDetailsApi(data: any) {
  return BaseGet(RequestUrl.transferDetailsUrl, data);
}

// 币种获取
export async function getCoinList(params: any) {
  return BaseGet(RequestUrl.getCoinList, params);
}

export async function getAllCoinList(params: any) {
  return BaseGet(RequestUrl.getAllCoinList, params);
}

// 闪兑创建
export async function createCoinTrade(data: any) {
  return BasePost(RequestUrl.createFlashExchange, data);
}

//邮箱注册
export async function emailRegisterApi(params: any) {
  return BasePost(RequestUrl.emailRegisterUrl, params);
}

//手机号注册
export async function phoneRegisterApi(params: any) {
  return BasePost(RequestUrl.phoneRegisterUrl, params);
}

// 获取闪兑详情
export async function getCoinTradeDetail(params?: any) {
  return BaseGet(RequestUrl.getFlashExchangeDetail, params);
}

// 分时图
export async function getTimeSharingChartApi(data: any) {
  return BaseGet(RequestUrl.getTimeSharingChartUrl, data);
}

// 获取币种下的资金
export async function getFundsUnderCurrencyApi(data: any) {
  return BaseGet(RequestUrl.getFundsUnderCurrencyUrl, data);
}

//通过币种id获取币种Symbol
export async function getSymbolByCoinIdApi(data: any) {
  return BaseGet(RequestUrl.getSymbolByCoinIdUrl, data);
}

//闪兑记录
export async function getFlashRedemptionRecordApi(data: any) {
  return BaseGet(RequestUrl.getFlashRedemptionRecordUrl, data);
}

// 获取临时token
export async function getTempTokenApi(data?: any) {
  return BasePost(RequestUrl.getTempTokenUrl, data);
}

// 热力图
export async function getHeatmapListApi(data?: any) {
  return BaseGet(RequestUrl.getHeatmapListUrl, data);
}

/**
 * ieo 列表
 */
export async function getIEOPageList(params: any) {
  return BaseGet(RequestUrl.getIEOList, params);
}

export async function buySubscriptionAll(params: any) {
  return BaseGet(RequestUrl.oneClickSubscription, params);
}

// ieo 购买
export async function createIEOOrderApi(data: any) {
  return BasePost(RequestUrl.createIEOOrderUrl, data);
}

// 配售购买
export async function createSubscriptionApi(data: any) {
  return BasePost(RequestUrl.createSubscriptionUrl, data);
}

/** 获取IEO订单详情
 *
 */
export async function getIEOOrderDetailsApi(data: any) {
  return BaseGet(RequestUrl.getIEOOrderDetailsUrl, data);
}

/**
 * # IEO订单获取
 */
export async function getIEOOrderApi(data: any) {
  return BaseGet(RequestUrl.getIEOOrderUrl, data);
}

// 获取vip信息
export async function getVipInfoApi(data: any) {
  return BaseGet(RequestUrl.getVipInfoUrl, data);
}

// 获取 文章 列表
export async function getNoticeList(data?: any) {
  return BaseGet(RequestUrl.noticeList, data);
}

// 获取 文章分类
export async function getJumpListApi(data?: any) {
  return BaseGet(RequestUrl.getJumpListUrl, data);
}

//法币列表
export async function getFiatCurrencyBalanceApi(data?: any) {
  return BaseGet(RequestUrl.getFiatCurrencyBalanceUrl, data);
}


//创建订单 - 股票
export async function createStockOrderApi(data: any) {
  return BasePost(RequestUrl.createStockOrderUrl, data);
}
