enum PageEnum {
  // 登录
  LOGIN = '/login',
  // 注册
  REGISTERED = '/register',
  //引导页
  GUIDE = '/guide',
  //忘记密码
  FORGOT = '/forgot',
  //选择区号
  CHOOSE_AREA = '/chooseArea',
  // 选择国籍
  CHOOSE_NATIONALITY = '/chooseNationality',
  // 首页
  HOME = '/home',
  //市场
  MARKET = '/market',
  // 订单
  ORDER = '/order',
  // 币对资金详情
  COIN_FUNDS_DETAIL = '/order/coin/fund_detail',
  ORDER2 = '/order2',
  //大宗订单详情
  BULK_DETAIL = '/order/bulk/detail',
  // 三方网页
  THIRD_PARTY_WEB_PAGES = '/third_party_web_pages',
  // VIP
  APPLY_VIP = '/vip',
  //合约分享
  CONTRACT_SHARE = '/contractTrade/contractSharing',
  //申购订单详情
  SUBSCRIPTION_DETAIL = '/order/subscription/detail',
  //  订单详情
  ORDER_DETAIL = '/order/detail/:id',
  // 交易
  TRANSACTION = '/transaction',
  // 合约
  CONTRACT = '/contract',
  //股票
  STOCK = '/stock',
  // 资金
  FUNDS = '/funds',
  //搜索
  SEARCH = '/search',
  //图表
  CHART = '/chart',
  //个人中心
  MINE = '/mine',
  // 返佣 邀请好友
  INVITEFRIENDS = '/invite/inviteFriends',
  // 客服列表
  SERVICE_LIST = '/service_list',
  // 返佣 邀请二维码
  INVITEQRCODE = '/invite/QRCode',
  // 返佣详情
  REBATE_DETAIL = '/rebate_detail',
  //C2C 商家列表
  C2C_LIST = '/c2c/list',
  //c2c 购买
  C2C_BUY = '/c2c/buy',
  // 订单消息详情
  ORDER_MESSAGE_DETAIL = '/message/order_detail',
  //提现
  WITHDRAW = '/funds/withdraw',
  //提现成功提示
  WITHDRAW_SUCCESS = '/funds/withdraw/success',
  //购买AI
  BUY_AI = '/buy_ai',
  USER_LANGUAGE = '/mine/userLanguage',
  //跟单
  FOLLOW = '/mine/social',
  //实名认证
  // VERIFIED = '/mine/verified',
  // 新实名认证
  VERIFIED = '/mine/verified',
  //文本预览
  TEXT_PREVIEW = '/text_preview',
  //银行卡
  BANK = '/mine/bank',
  //添加收款方式
  ADD_COLLECTION = '/mine/add_payment_method',
  //申购
  SUBSCRIPTION = '/mine/subscription',
  // AI交易
  AITRADE = '/mine/aItrade',
  //资金
  Funds = '/funds',
  //汇率
  EXCHANGE_RATE = '/exchange_rate',
  //选择币种
  CURRENCY = '/funds/currency',
  //充值币种
  RECHARGE_CURRENCY = '/funds/recharge_currency',
  //充值币种确认
  DEPOSIT_CURRENCY_CONFIRMATION = '/funds/recharge_confirmation',
  //充值
  TopUp = '/funds/topUp',
  //充值订单
  TopUp_Order = '/funds/top_up_order',
  //法币
  FiatCurrency = '/funds/fiatCurrency',
  //订单历史
  ORDER_HISTORY = '/funds/order_history',
  //历史订单详情
  ORDER_HISTORY_DETAIL = '/funds/order_history_detail',
  //订购历史搜索
  ORDER_HISTORY_SEARCH = '/funds/order_history_search',
  //第三方充值
  THIRD_PARTY_RECHARGE = '/funds/third_party_recharge',
  // 划转
  TRANSFER = '/funds/transfer',
  // 闪兑
  SWAP = '/funds/swap',

  //设置中心
  SETTINGS = '/mine/settings',

  //客服
  SERVICE = '/mine/service',

  //涨跌色
  UPDOWNCOLOR = '/mine/upDownColorConfig',

  //帮助中心
  HELPCENTER = '/mine/help_center',

  //帮助中心 文章
  HELPARTICLE = '/mine/helpArticle',

  //帮助中心文章详情
  HELPARTICLEDETAIL = '/mine/helpArticle_detail',

  //帮助中心 反馈
  HELPFEEDBACK = '/mine/helpFeedback',

  //主题
  THEME = '/mine/theme',

  //理财列表
  FINANCELISTVIEW = '/funds/financeListView',

  //购买申购
  BUYAI = '/funds/buyai',

  //购买申购
  BUYSUBSCRIBE = '/funds/buySubscribe',

  /**
   * 购买申购订单详情页buySubscribeOrderDetail
   */
  BUYSUBSCRIBEORDER = '/funds/buySubscribeOrderDetail',

  /**
   * # 新闻详情
   */
  NEWSDETAIL = '/message/newsDetail',

  /**
   * # 公告详情
   */
  ANNOUNCEMENTDETAIL = '/message/announcementDetail',

  /**
   * # 修改密码
   */
  CHANGEPASSWORD = '/mine/changePassword',

  //修改用户信息
  CHANGEUSERINFORMATION = '/mine/changeUserInformation',

  //更改支付密码
  CHANGEPAYMENTPASSWORD = '/mine/changePayPassword',

  //订单详情
  CONTRACT_ORDER_DETAIL = '/order/detail',

  // 论坛详情
  FORUMDETAIL = '/home/forumDetail',

  // 消息通知
  MESSAGE = '/message',

  // 公告
  ANNOUNCEMENT = '/announcement',

  // 新闻
  NEWS = '/news',
  //订单消息
  ORDERMESSAGE = '/message/order',

  // 大宗交易
  BLOCKTRADE = '/blockTrade',

  //订单详情(充值提现)
  ORDERHISTORYDETAIL = '/funds/orderHistoryDetail/:id',

  //订单详情（买入、卖出）
  TRADINGHISTORYDETAIL = '/funds/tradingHistoryDetail',

  //引导页
  BOOTPAGE = '/bootPage',

  //vip
  VIP = '/mine/vip',

  //在线客服
  ONLINESUPPORT = '/mine/onlinesupport',
  //邀请
  INVITE = '/invite',
  //返佣排名
  REBATE_RANKING = '/rebateRanking',
  //邀请设置
  INVITATION_SETTINGS = '/invitation_settings',
  //vip升级
  UPGRADEPLAN = '/mine/upgradeplan',
  //AI订单
  AI_ORDER = '/mine/ai_order',
  //AI子订单
  AI_SUB_ORDER = '/mine/ai_child_Order',
  //AI订单详情
  AI_ORDER_DETAIL = '/mine/ai_order_detail',
  //PChome
  PCHOME = '/pc_home',
  //外部客服
  EXTERNAL_SERVICE = '/external_service',
  //热力图
  HEATMAP = '/heatmap',
  LOANS = '/funds/LOANS',
  // ieo订单
  IEOORDER = '/ieoOrder',
  // 时区选择
  TIMEZONESELECTOR = '/timezoneselector',
  // 论坛
  FORUM = 'forum',
  //文章
  NOTICELIST = 'noticelist',
  //隐私协议列表
  PRIVACY_POLICY = '/privacy_policy',
  //学院列表
  COLLEGE_LIST = '/college_list',
  //学院详情
  COLLEGE_DETAIL = '/college_detail',
  // 借贷记录
  LOANS_RECORD = '/funds/loans/log',
  // pc页面  IEO
  PC_IEO = '/pc/ieo',

  XRPTOPUP = 'xrp_topup',
}

export default PageEnum;
