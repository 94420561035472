// @ts-nocheck

import { request } from '@umijs/max';
import { useRequest } from 'ahooks';
import type { Options, Result, Service } from 'ahooks/lib/useRequest/src/types';
import { useState } from 'react';

export async function BaseGet(
  url: string,
  params: any = {},
  options: any = {},
) {
  return request(url, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

export async function BasePost(url: string, body = {}, options = {}) {
  return request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function BasePut(url: string, body = {}, options = {}) {
  return request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function BaseDelete(url: string, body = {}, options = {}) {
  return request(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

function getMock(): any {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 1000);
  });
}

const errCode = 'Response_Error';

/**
 *
 * @param ser 没有接口时,传递false
 * @param opt
 * @returns
 */
// 全局请求管理器
const requestManager = new Map<string, boolean>();
export function useReq<TData, TParams extends any[]>(
  ser: Service<TData, TParams> & any,
  opt:
    | Options<TData, TParams>
    | ({ formatResult?: any } & {
        defaultValue?: any;
        loadingDefault?: boolean;
      }) = {},
): Result<TData, TParams> {
  // 如果参数
  const formatDf = (res: any) => {
    if (res.code && res?.code !== 0) {
      res.resCode = res.code;
      res.code = errCode;
    }
    if (
      typeof res === 'object' &&
      res !== null &&
      [null, undefined, []].includes(res.data)
    ) {
      res.data = opt?.defaultValue;
    }

    if (res?.code === errCode) {
      return res;
    }
    return res?.data ?? res;
  };
  const {
    formatResult = formatDf,
    cacheKey = null,
    loadingDefault = true,
  } = opt || {};
  const [loading, setLoading] = useState(loadingDefault);

  return {
    ...useRequest(
      async (p): Promise<any> => {
        if (ser === null || ser === false) {
          return getMock();
        }
        let data = null;
        if (typeof ser === 'object') {
          data = await ser;
        }
        if (typeof ser === 'function') {
          data = await ser(p);
        }
        return formatResult(data) as Result<TData, TParams>;
      },
      {
        debounceWait: 300, // 防抖
        loadingDelay: 0, // 防屏幕闪动
        setCache: (data) =>
          localStorage.setItem(cacheKey, JSON.stringify(data ?? {})),
        getCache: () => JSON.parse(localStorage.getItem(cacheKey) || '{}'),
        onBefore() {
          setLoading(true);
          if (opt?.onBefore) opt.onBefore?.();
          return false;
        },
        ...opt,
        onSuccess: (result, params) => {
          // console.log(result?.code, 'result?.code ');
          // console.log(errCode, 'errCode ');
          // console.log(result?.code !== errCode)
          if (result?.code !== errCode) {
            if (opt?.onSuccess) opt.onSuccess?.(result, params);
          } else {
            if (opt?.onError) opt.onError?.(result, params);
          }
        },
        onFinally() {
          if (opt?.onFinally) opt.onFinally?.();
          setLoading(false);
        },
      },
    ),
    loading,
  };
}
