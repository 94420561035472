import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { getCardValueApi, getMenuListApi ,getCoinPrecisionApi} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useFavicon, useMount } from 'ahooks';
import { useEffect } from 'react';
import { useModel } from '@umijs/max';

/**
 * 使用自定义hook获取应用信息并存储到localStorage中。
 * 该hook不接受任何参数。
 *
 * @returns {Object} 返回一个包含appInfo的对象。
 */
export default () => {
  // 使用useReq钩子来请求获取卡片值的API，并在成功时将数据存储到localStorage中
  // 汇率列表
  const [appInfo, setAppInfo] = useLocalStorage({
    key: CacheEnum.APP_BASE_INFO,
    defaultValue: {},
  });
  const { user } = useModel('user');

  // 用于 公告信息语言确认【目前】
  const { language } = useModel('language');
  const { sendMsg, isSub } = useModel('socket');

  useEffect(() => {
    if (isSub) {
      sendMsg(
        JSON.stringify({
          type: 'language',
          data: language,
        }),
      );
    }
  }, [language, isSub]);

  useReq(getCardValueApi, {
    onSuccess(data:any) {
      // 成功获取数据后，将数据存储到localStorage的APP_BASE_INFO键中
      setAppInfo(data);
      document.title = data?.APP_BASE_NAME
      try {
        localStorage.setItem(CacheEnum.APP_BASE_INFO, JSON.stringify(data));
      } finally {
      }
    },
  });
  // 币种精度
  const [coinPrecision,setCoinPrecision] =useLocalStorage({
    key: CacheEnum.APP_COIN_PRECISION,
    defaultValue: [],
  })

  // 获取币种精度列表
  useReq(getCoinPrecisionApi,{
    onSuccess(data:any) {
      // 成功获取数据后，将数据存储到localStorage的APP_COIN_PRECISION键中
      setCoinPrecision(data);
    },
  })

  // 获取本地可使用的菜单列表
  const { data: menuList, runAsync:getMenuListReq  } = useReq(getMenuListApi, {
    manual: true,
    cacheKey: CacheEnum.APP_MENU_LIST,
  }) as any;
  useFavicon((appInfo as any)?.APP_BASE_LOGO);
  //  获取菜单列表
  useEffect( () => {
    if (user?.id && !menuList) {
      getMenuListReq();
    }
  }, [user, location.pathname]);

  // 筛选出可见的菜单
  const getShowMenuList = (list: any) => {
    if (!menuList) return [];
    return list?.filter((item: any) => {
      let findMenuItem: any;
      if (Array.isArray(menuList)) {
        findMenuItem = menuList?.find?.(
          (menu: any) => menu?.path === item?.path && item?.visible !== 0,
        );
      }
      if (!findMenuItem) {
        return true;
      } else {
        return findMenuItem.visible === 1;
      }
    });
  };

  return {
    appInfo, // 返回获取到的应用信息
    menuList,
    getShowMenuList,
    getMenuListReq,
    coinPrecision
  };
};
