import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage, useSessionStorage } from '@/hooks/useStorage';
import { exchangeConfigApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';

// 拼接符号参数
interface joinProps {
  price: any;
  currency: string;
  isJoin?: boolean;
  isShowCoinName?: boolean;
}

// 汇率转换参数
interface exchangeConverProps {
  price: any;
  currency?: string;
  defaultVal?: any;
  showMask?: boolean;
  isJoin?: boolean;
  isShowCoinName?: boolean;
  padZeroes?: boolean;
  len?: number;
}

// 首先，我所有的钱是印度卢比
// 用印度卢比/exchangeToUSD得到USD金额
// 美元×汇率＝展示价格

export default () => {
  // 显示隐藏资金
  const [isShow, setIsShow] = useSessionStorage({
    key: CacheEnum.APP_SHOW_AMOUNT,
    defaultValue: true,
  });
  //设置页选择汇率 1
  const [currentExchangeSetting, setCurrentExchangeSetting] = useLocalStorage({
    key: CacheEnum.APP_CURRENT_EXCHANGE_RATE_SETTING, // 全部
    defaultValue: '',
  });

  //资金页选择汇率 2
  const [currentExchangeFunds, setCurrentExchangeFunds] = useLocalStorage({
    key: CacheEnum.APP_CURRENT_EXCHANGE_RATE_FUNDS, // 全部
    defaultValue: '',
  });

  // 汇率列表
  const [exchange, setExchangeList] = useLocalStorage({
    key: 'CacheKey_getExchangeData',
    defaultValue: '',
  });

  //获取汇率列表
  const { runAsync: getExchangeData } = useReq(exchangeConfigApi, {
    // ready: localStorage.getItem(CacheEnum.APP_LOGIN_TYPE) === '2',
    manual: false,
    staleTime: 3 * 1000,
    onSuccess(data: any) {
      if (!currentExchangeSetting && data?.usdExchangeList?.length !== 0) {
        setCurrentExchangeSetting(
          data?.usdExchangeList?.find(
            (item: any) => item.exchange === 'USDT' || item.exchange === 'USD',
          ),
        );
      } else {
        setCurrentExchangeSetting(
          data?.usdExchangeList?.find(
            (item: any) => item.exchange === currentExchangeSetting?.exchange,
          ),
        );
      }

      if (!currentExchangeFunds && data?.usdExchangeList?.length !== 0) {
        setCurrentExchangeFunds(
          data?.usdExchangeList?.find(
            (item: any) => item.exchange === 'USDT' || item.exchange === 'USD',
          ),
        );
      } else {
        if (!currentExchangeSetting) {
          setCurrentExchangeFunds(data?.usdExchangeList[0]);
        }
      }
      setExchangeList(data);
    },
  });


  // 基础转换 将当前货币单位转换为USD
  const baseCurrentExchang = (val: number) => {
    if (!val) return 0;
    // return val;
    return val;
  };

  // 通过coinName获取指定的汇率信息
  const getExchange = (currency = currentExchangeFunds?.exchange) => {
    if (!currency || !currency?.trim) return currentExchangeFunds;
    let cur = currency;
    if (currency === 'USD') {
      // cur = 'USDT';
    }
    if (cur && exchange && exchange?.usdExchangeList) {
      const findCurrency = exchange?.usdExchangeList?.find(
        (item: any) =>
          item?.exchange?.trim()?.toUpperCase() === cur?.trim()?.toUpperCase(),
      );
      if (findCurrency) return findCurrency;
    }
    return false;
  };

  // 获取指定的币的单位
  const getExchangeUnit = (currency: string) => {
    let target = currency;
    if (target === 'USD') {
      target = 'USDT';
    }
    const findCurrency = exchange?.usdExchangeList?.find(
      (item: any) => item.exchange === target,
    );
    if (!findCurrency) return currency;
    return findCurrency?.sign;
  };

  function maskPrice(
    price: any,
    customShow = false,
    defaultVal?: string,
  ): string {
    if (price === undefined) return '';
    if (!isShow && !customShow) {
      return '*'.repeat(6);
    }
    if (+price?.toString() === 0) {
      return defaultVal ?? '';
    }
    return price;
  }

  // 拼接符号
  const joinSymbol = ({
                        price, // 需要转换的值
                        currency, // 指定币种
                        isJoin = true, // 是否需要拼接
                        isShowCoinName = false, // 是否拼接币种名称
                      }: joinProps) => {
    if (!currentExchangeFunds) return price;
    // 判空
    if (price === undefined || price === null) return 0;
    // 是否拼接符号
    if (!isJoin) return price;
    // 汇率列表中查找符号
    let cur = getExchange(currency);
    // 如果汇率列表中找不到符号 就返回价格＋传入的币种名
    if (!cur) return price + ' ' + currency;
    // 是否拼接币种名称（拼接币种名称就不拼接符号）
    if (isShowCoinName) return price + ` ${cur?.exchange}`;
    return cur?.position === 1 ? price + cur?.sign : cur?.sign + ' ' + price;
  };

  // 资金汇率转换
  //--val: 需要转换的值
  //--currency: 需要转换的币种
  //--showMask: 是否跟随显示隐藏
  //--isJoin: 是否拼接符号
  //--isShowCoinName: 是否拼接币种名称
  //--padZeroes: 是否填充0
  const exchangeConverFunds = ({
                                 price = 0,
                                 currency = currentExchangeFunds?.exchange,
                                 showMask = true,
                                 isJoin = false,
                                 isShowCoinName = false,
                                 padZeroes = false,
                                 len,
                                 // defaultVal = '--',
                                 defaultVal = '0',
                               }: exchangeConverProps) => {
    let cur = getExchange(currency);

    return (
      joinSymbol({
        price: maskPrice(
          RenderUtil.FormatAmount(
            baseCurrentExchang(price) * (cur?.price ?? 0),
            len ?? cur?.priceAccuracy ?? 2,
            padZeroes,
          )?.toString(),
          !showMask,
          defaultVal,
        ),
        currency: cur?.exchange ?? '',
        isJoin: isJoin,
      }) + (isShowCoinName ? ` ${cur?.exchange ?? ''}` : '')
    );
  };

  // 设置页面转换（转换为n法币）
  //--val: 需要转换的值
  //--showMask: 是否跟随显示隐藏
  //--isJoin: 是否拼接符号
  //--isShowCoinName: 是否拼接币种名称
  //--padZeroes: 是否填充0
  const exchangeSetConvert = ({
                                price = 0,
                                showMask = true,
                                isJoin = false,
                                isShowCoinName = false,
                                padZeroes = false,
                                len,
                                // defaultVal = '--',
                                defaultVal = '0',
                              }: exchangeConverProps) => {

    let cur = getExchange(currentExchangeSetting?.exchange ?? 'USDT');

    return (
      joinSymbol({
        price: maskPrice(
          RenderUtil.FormatAmount(
            baseCurrentExchang(price) * (cur?.price ?? 0),
            len ?? cur?.priceAccuracy ?? 2,
            padZeroes,
          )?.toString(),
          !showMask,
          defaultVal,
        ),
        currency: cur?.exchange ?? '',
        isJoin: isJoin,
      }) + (isShowCoinName ? ` ${cur?.exchange ?? ''}` : '')
    );
  };


  // 转换为 资金约等于汇率值 当前单位转换为USD 转换为设置页设置的汇率
  const exchangeConversionSetting = (val: number) => {
    return baseCurrentExchang(val) * currentExchangeSetting?.price;
  };

  //查找选定的币种给出多少USD计算出对应币种的金额
  const findExchangeToMoney = (val: number, type: any, isMax = true) => {
    const findCurrency = exchange?.usdExchangeList?.find(
      (item: any) => item.exchange === type,
    );
    if (!findCurrency) return val;
    if (isMax) {
      return findCurrency?.price * val - findCurrency?.price;
    } else {
      return findCurrency?.price * val + findCurrency?.price;
    }
  };

  const getMoneyLength = (targetCoinName?: string) => {
    return getExchange(targetCoinName)?.priceAccuracy ?? 2;
  };

  return {
    exchange, // 后端返回的数据
    setCurrentExchangeFunds, //资金页面选择
    setCurrentExchangeSetting, //汇率设置页面页面选择
    exchangeConversionSetting,
    currentExchangeSetting,
    currentExchangeFunds,
    joinSymbol,
    maskPrice,
    setIsShow,
    isShow,
    findExchangeToMoney,
    getMoneyLength,
    exchangeConverFunds,
    getExchangeData,
    getExchangeUnit,
    exchangeSetConvert,
  };
};
