import { BusEnums } from '@/enums/busEnum';
import {
  CustomerServiceTypeEnum,
  ImMessageStatusEnum,
  ImMessageTypeEnum,
  SEND_MESSAGE_STATUS,
} from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { toast as layoutToast } from '@/layouts/components/ToastMessage';
import { basicRoutes } from '@/router/basicRoutes';
import { imGroupListApi, refreshTokenApi } from '@/services/api/requestApi';
import HttpSetting from '@/setting/httpSetting';
import eventBus from '@/utils/evevtBus';
import { toJsonData } from '@/utils/socket';
import { history, useModel } from '@umijs/max';
import { useUpdateEffect, useWebSocket } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { decodeHTMLEntitiesOptimized, findJsonValue } from '@/utils';
import { IndexedDBHelper } from '@/utils/IndexedDBHelper';
import { deepClone } from '@lordicon/helpers';
import { useReq } from '@/services/net/request';

interface SendMessageProps {
  type:
    | ImMessageTypeEnum.TEXT
    | ImMessageTypeEnum.IMAGE
    | ImMessageTypeEnum.ORDER
    | any;
  data: any;
}

// 定时检查心跳重连Im时间
export const IM_HEART_BEAT_RECONNECT_INTERVAL = 20000;

// 重连次数
export const IM_MAX_RECONNECT_COUNT = 5;
export const chatDB = new IndexedDBHelper('ChatDB', 'Messages', 1);

export default () => {
  const { socketId } = useModel('socket');
  const { t } = useTranslation();
  const [imGroupList, setImGroupList] = useState([]);
  // 聊过的群聊列表
  const { runAsync: imGroupListReq,loading: imGroupListLoading } = useReq(imGroupListApi, {
    manual: true,
    loadingDefault:false,
    onSuccess: (data: any) => {
      setImGroupList(data);
    },
  });
  // 初始化
  useEffect(() => {
    if (imGroupList.length === 0) {
      imGroupListReq();
    }
  }, []);

  // 未读
  const [customerServiceMessageUNREAD, setCustomerServiceMessageUNREAD] =
    useLocalStorage({
      key: CacheEnum.APP_CUSTOMER_SERVICE_UNREAD, // 全部
      defaultValue: false,
    });
  // 匿名客服未读
  const [anonymousServiceMessageUNREAD, setAnonymousServiceMessageUNREAD] =
    useLocalStorage({
      key: CacheEnum.APP_ANONYMOUS_UNREAD, // 全部
      defaultValue: false,
    });
  // 充值客服未读
  const [c2cServiceMessageUNREAD, setC2cServiceMessageUNREAD] =
    useLocalStorage({
      key: CacheEnum.APP_C2C_UNREAD, // 全部
      defaultValue: {},
    });

  // 所有客服的未读总和
  const unReadCount = useMemo(() => {
    return imGroupList?.reduce?.((acc: any, cur = {} as any) => {
      if (cur?.type === 5) {
        return acc + (customerServiceMessageUNREAD === false ? cur?.unreadCount : +customerServiceMessageUNREAD);
      }
      return acc + (c2cServiceMessageUNREAD?.[cur?.groupSign] === undefined ? cur?.unreadCount : c2cServiceMessageUNREAD?.[cur?.groupSign]);
    }, 0);
  }, [imGroupList, customerServiceMessageUNREAD, c2cServiceMessageUNREAD]);


  // 获取客服类型
  const getCustomerServiceType = (groupType: CustomerServiceTypeEnum) => {
    if (groupType === CustomerServiceTypeEnum.TEMPORARY_CUSTOMER_SERVICE) {
      return t('临时客服');
    }
    if (groupType === CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE) {
      return t('平台客服');
    }
    if (groupType === CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE) {
      return t('C2C客服');
    }
    if (groupType === CustomerServiceTypeEnum.BACKGROUND_RECHARGE) {
      return t('后台充值客服');
    }
    if (groupType === CustomerServiceTypeEnum.BANK_CARD) {
      return t('银行卡客服');
    }
    return t('虚拟币客服');
  };
  // 获取客服头像
  const getCustomerServiceAvatar = (id: string) => {
    const findItem = imGroupList?.find(
      (item: any) => item?.id === id,
    ) || {};
    if (findItem?.type === CustomerServiceTypeEnum.C2C_CUSTOMER_SERVICE) {
      return findJsonValue('businessAvatar', findItem);
    }
    if (findItem?.type === CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE) {
      return require('@/assets/img/im/officialImAvatar.png');
    }

    return findItem?.imgUrl;
  };

  // 普通客服消息
  const [lastMessage, setLastMessage] = useState<any>(false);

  // 匿名客服消息
  const [anonymousLastMsg, setAnonymousLastMsg] = useState<any>(false);

  // 充值客服最后一条消息
  const [rechargeServiceMessage, setRechargeServiceMessageUNREAD] = useState<any>({});


  //socket获取的消息列表
  const [imMessage, setImMessage] = useState<any[]>([]);
  useEffect(() => {
    chatDB.getItems().then((res) => {
      setImMessage(deepClone(res));
    }).catch(err => {
    });
  }, []);

  // 已读的消息id
  const [readMessageId, setReadMessageId] = useLocalStorage({
    key: CacheEnum.APP_IM_READ_MESSAGE_ID,
    defaultValue: [],
  });
  //当前socket推送的消息
  const [currentSocketMessage, setCurrentMessage] = useState<any>({});
  const whiteList = basicRoutes.map((item) => item.path);
  //im socket创建和绑定事件

  //是否已经 连接
  const [isSub, setIsSub] = useState<boolean>(false);

  //最后收到消息的时间
  const [lastMessageTime, setLastMessageTime] = useState(Date.now());

  // 刷新token并且重连
  const refreshTokenAndReconnect = () => {
    const localToken = localStorage.getItem(CacheEnum.REFRESHTOKEN);
    const refreshToken = localToken ? JSON.parse(localToken) : '';
    refreshTokenApi({ refreshToken, noMsg: true }).then((res: any) => {
      const { code, data } = res || {};
      const { accessToken, refreshToken } = data || {};
      if (code === 0) {
        if (accessToken) {
          localStorage.setItem(
            CacheEnum.AUTHORIZATION,
            JSON.stringify(accessToken),
          );
          eventBus.emit(BusEnums.RECONNECT_SOCKET, accessToken);
        }
        if (refreshToken) {
          localStorage.setItem(
            CacheEnum.REFRESHTOKEN,
            JSON.stringify(refreshToken),
          );
        }
        window?.FlutterTokens?.postMessage(JSON.stringify(data));
      } else {
        // if (!window.location.href.includes(PageEnum.LOGIN)) {
        //   if (window.location.pathname === '/') {
        //     localStorage.removeItem(CacheEnum.AUTHORIZATION);
        //   } else {
        //     localStorage.removeItem(CacheEnum.AUTHORIZATION);
        //     localStorage.removeItem(CacheEnum.REFRESHTOKEN);
        //     // 重定向到登录页面
        //     if (
        //       window.location.pathname !== '/pc_home' &&
        //       window.location.pathname !== '/'
        //     ) {
        //       toast.error(t('登录过期,请重新登录'));
        //       eventBus.emit(BusEnums.CLOSE_ALL_SOCKET);
        //       history.push(PageEnum.LOGIN);
        //     }
        //   }
        // }
      }
    });
  };
  const { language } = useModel('language');

  useUpdateEffect(() => {
    sendImMessage({
      type: 'language',
      data: language,
    });
  }, [language]);
  // 最新消息
  const [latestNews, setNewLatestNews] = useState({});
  const SocketUrl =
    localStorage.getItem('window.imSoketUrl') || HttpSetting.IM_WSS_URL;

  // 修改最后一条消息公共逻辑
  const updateLastMessage = (data: any, payload: any) => {
    if (![ImMessageTypeEnum.JOIN_MESSAGES, ImMessageTypeEnum.FINISH_MESSAGES]?.includes(payload?.type)) {
      if (data?.groupType === CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE) {
        setLastMessage((prev: any) => {
          // 对比一下offset
          if (!prev || prev?.offset <= data?.offset) {
            return data;
          }
          return prev;
        });
      } else if (data?.groupType === CustomerServiceTypeEnum.TEMPORARY_CUSTOMER_SERVICE) {
        setAnonymousLastMsg((prev: any) => {
          // 对比一下offset
          if (!prev || prev?.offset <= data?.offset) {
            return data;
          }
          return prev;
        });
      } else {
        setRechargeServiceMessageUNREAD((prev: any) => {
          if (!prev?.[data?.channelId] || prev?.[data?.channelId]?.offset <= data?.offset) {
            return {
              ...prev,
              [data?.channelId]: data,
            };
          }
          return prev;
        });
      }
    }

  };
  //连接核心代码
  const { readyState, disconnect, connect, sendMessage } = useWebSocket(
    SocketUrl + socketId,
    {
      manual: true, //手动连接
      reconnectLimit: 9999,
      onOpen: (e) => {
        // 连接成功发送当前国际化
        sendImMessage({
          type: 'language',
          data: language,
        });
        //连接成功后面让 重新订阅下
        setIsSub(true);
        //连接成功后查看列表中是否有未发送成功的消息，全部重新发送
        const sendNoSuccessSendMsg = imMessage
          ?.filter?.(
            (item: any) => {
              const payload = toJsonData(item?.payload);
              if (payload?.data) {
                const data = toJsonData(payload?.data);
                if (data?.imgType === 'base64' || data?.url?.length > 500) return false;
              }
              return (+item?.showDirection === 0 && +item?.sendStatus !== SEND_MESSAGE_STATUS.SUCCEED && !item?.id);
            },
          )
          ?.map?.((item: any) => toJsonData(item?.payload));
        setTimeout(() => {
          sendNoSuccessSendMsg?.forEach?.((sendMsg: any) => {
            sendImMessage({
              type: 'SEND_MESSAGE',
              data: sendMsg,
            });
          });
        }, 1000);
      },
      onClose() {
        setImMessage((prevState: any) => {
          return prevState.map((item: any) => {
            if (item?.sendStatus === SEND_MESSAGE_STATUS.FAIL) {
              return {
                ...item,
                sendStatus: SEND_MESSAGE_STATUS.FAIL,
              };
            }
            return item;
          });
        });
        setIsSub(false);
      },
      onMessage: (msg) => {
        setLastMessageTime(Date.now());
        const strArr = ['ping', 'close', 'pong', 'p'];
        if (typeof msg?.data === 'string' && !strArr.includes(msg?.data)) {
          try {
            const res = toJsonData(msg?.data);
            // 刷新token-重新连接token
            if (
              (msg?.data === 'Invalid token' ||
                res?.type === 'TOKEN_INVALID') && !whiteList.includes(location.pathname)
            ) {
              refreshTokenAndReconnect();
              return;
            }
            let data: any = toJsonData(res?.data);
            if (res?.type === 'CONNECT_SUCCESS') {
              // 连接成功发送当前国际化
              setTimeout(() => {
                sendImMessage({
                  type: 'language',
                  data: language,
                });
              }, 200);
            }
            if (res?.type === 'GROUP_LIST') {
              setImGroupList(data);
            }
            if (res?.type === 'MESSAGE_PUSH_COMPLETED') {
              eventBus.emit(BusEnums.MESSAGE_PUSH_FINISH);
            }
            //未读socket
            if (res?.type === 'CUSTOMER_UNREAD_COUNT') {
              // 临时客服
              if (data.groupType === CustomerServiceTypeEnum.TEMPORARY_CUSTOMER_SERVICE) {
                setAnonymousServiceMessageUNREAD(data.number);
                return
              }
              // 默认客服
              if (!data?.channelId || data?.channelId === 'DEFAULT') {
                setCustomerServiceMessageUNREAD(data.number);
              } else {
                // 充值未读数
                setC2cServiceMessageUNREAD((prev: any) => {
                  return {
                    ...prev,
                    [data?.channelId]: data?.number,
                  };
                });
              }
            }
            //消息socket
            if (res?.type === 'MESSAGE') {
              const payload = toJsonData(data?.payload);
              const payloadData = toJsonData(payload?.data);
              // 修改最后一条消息
              updateLastMessage(data, payload);
              if (
                location.pathname !== PageEnum.ONLINESUPPORT &&
                !whiteList.includes(location.pathname)
              ) {
                const isRight = data?.showDirection === 0;
                if (!isRight) {
                  let content: any = null;
                  const p = payloadData?.content ? payloadData?.content : payload;
                  if (p.type === ImMessageTypeEnum.TEXT) {
                    content = `${decodeHTMLEntitiesOptimized((typeof p?.data === 'string' ? toJsonData(p?.data) : p?.data)?.text)}`;
                  } else if (p.type === ImMessageTypeEnum.ORDER) {
                    content = `${t('客服：[订单]')}`;
                  } else if (p.type === ImMessageTypeEnum.IMAGE) {
                    content = `${t('客服：[图片]')}`;
                  } else if (p.type === ImMessageTypeEnum.JOIN_MESSAGES) {
                    content = t('{{name}}已加入', {
                      name: payloadData?.name,
                    });
                  } else if (p.type === ImMessageTypeEnum.FINISH_MESSAGES) {
                    content = payloadData?.finishWay === 'auto'
                      ? t('系统已自动结束工单')
                      : t('{{name}}已结束工单', {
                        name: payloadData?.name,
                      });
                  }
                  if (content && data?.status !== ImMessageStatusEnum.WITHDRAW_MESSAGE) {
                    let socketUNREAD:any = false
                    if(data?.groupType === CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE){
                      socketUNREAD = customerServiceMessageUNREAD+1
                    }else if(data?.groupType === CustomerServiceTypeEnum.TEMPORARY_CUSTOMER_SERVICE){
                      socketUNREAD =  anonymousServiceMessageUNREAD+1
                    }else{
                      socketUNREAD = (c2cServiceMessageUNREAD[data?.channelId] ?? 0) + 1
                    }
                    layoutToast.open({
                      title: getCustomerServiceType(data?.groupType),
                      content,
                      icon: getCustomerServiceAvatar(data?.groupId),
                      unreadCount: socketUNREAD, // 未读数量+1 先推送
                      autoCloseSeconds: CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE, // 5 秒后自动消失
                      onClick: () => {
                        setNewLatestNews(data);
                      },
                    });
                  }
                }
              }
              if (+data?.status === ImMessageStatusEnum.NORMAL_MESSAGE) {
                const isRight = data?.showDirection === 0;
                setImMessage((old: any) => {
                  // 是否有相同的的uuid
                  const hasUuid =
                    toJsonData(payload?.data)?.uuid &&
                    old?.find(
                      (item: any) =>
                        toJsonData(toJsonData(item?.payload)?.data)?.uuid ===
                        toJsonData(payload?.data)?.uuid,
                    );
                  // 是否有相同的id
                  const hasId = old?.find((item: any) => item.id === data.id);
                  // 没有相同uuid可能是两种情况(1、中台生成的;2、多台设备登录同一个账号发送消息)
                  if ((!hasUuid && (!hasId || !data?.id)) || (!isRight && !hasId)) {
                    return [
                      ...old,
                      { ...data, sendStatus: SEND_MESSAGE_STATUS.SUCCEED },
                    ];
                  }
                  // 找到具有相同uuid但没有id的项，用data替换该项
                  return old.map((item: any) => {
                    const oldItemPayload = toJsonData(item.payload);
                    if (
                      toJsonData(oldItemPayload.data)?.uuid ===
                      toJsonData(payload.data)?.uuid
                    ) {
                      return {
                        ...data,
                        sendStatus: SEND_MESSAGE_STATUS.SUCCEED,
                      };
                    }
                    return item;
                  });
                });
              }
              //修改消息
              if (+data?.status === ImMessageStatusEnum.EDIT_MESSAGE) {
                setImMessage((old: any) => {
                  return old.map((item: any) => {
                    if (item.id !== data.id) return item;
                    return {
                      ...item,
                      status: ImMessageStatusEnum.EDIT_MESSAGE,
                      payload: data?.payload,
                      sendStatus: SEND_MESSAGE_STATUS.SUCCEED,
                    };
                  });
                });
                setCurrentMessage(data);
                // 重新设置最后一条消息状态
                updateLastMessage({
                  ...data,
                  sendStatus: SEND_MESSAGE_STATUS.SUCCEED,
                }, payload);
              }
              //撤回消息
              if (+data?.status === ImMessageStatusEnum.WITHDRAW_MESSAGE) {
                setImMessage((old: any) => {
                  return old.map((item: any) => {
                    if (item.id !== data.id) return item;
                    return {
                      ...item,
                      status: ImMessageStatusEnum.WITHDRAW_MESSAGE,
                      sendStatus: SEND_MESSAGE_STATUS.SUCCEED,
                    };
                  });
                });
                setCurrentMessage(data);
                // 重新设置最后一条消息状态
                updateLastMessage({
                  ...data,
                  status: ImMessageStatusEnum.WITHDRAW_MESSAGE,
                  sendStatus: SEND_MESSAGE_STATUS.SUCCEED,
                }, payload);
              }
            }
          } finally {
          }
        }
      },
    },
  );

  // 关闭连接
  const disconnectSocket = () => {
    disconnect?.();
  };

  // 开始连接
  const connectSocket = () => {
    if (!socketId) return;
    disconnectSocket?.();
    connect();
  };

  // 设置定时器检查消息间隔
  useEffect(() => {
    const checkMessageInterval = setInterval(() => {
      try {
        if (sendMessage) {
          sendMessage('ping');
        }
      } catch (e) {
      }
      if (Date.now() - lastMessageTime > 600000) {
        setIsSub(false);
      }
    }, 6000); // 每6秒检查一次

    return () => clearInterval(checkMessageInterval); // 清理定时器
  }, [lastMessageTime, readyState, disconnect, connect]);

  /**---------当前socket发送消息方法----------------**/
  const sendImMessage = (data: SendMessageProps) => {
    try {
      sendMessage(JSON.stringify(data));
    } catch (e: any) {
      console.log(e, '====');
    }
  };
  // 当 socketId 更改时，重新连接 WebSocket
  useEffect(() => {
    if (socketId) {
      try {
        if (readyState === 1) {
          if (disconnect) disconnect();
        }
        if (connect && socketId) connect();
      } catch (e) {
      }
    }
  }, [socketId]);


  // 通道id,发送信息等缓存信息
  const [, setImParmaData] = useLocalStorage({
    key: CacheEnum.APP_IM_DATA,
    defaultValue: [],
  });
  /**
   * @description 跳转普通客服

   */
  const gotoCustomerService = () => {
    setImParmaData({});
    history.push(PageEnum.ONLINESUPPORT);
  };

  /**
   * @description 跳转C2C客服
   */
  const gotoC2CCustomerService = (data?: any) => {
    // 跳转在线客服页面
    setImParmaData(data ?? {});
    history.push(PageEnum.ONLINESUPPORT, data);
  };



  const { isPc } = useModel('system');
  /**
   * @description 跳转全局客服
   */
  const gotoGlobalCustomerService = () => {
    if (isPc) {
      gotoCustomerService();
      return;
    }
    const nonPlatformCustomerService = imGroupList?.filter(
      (item: any) => item?.type !== CustomerServiceTypeEnum.COMMON_CUSTOMER_SERVICE,
    );

    setImParmaData({});
    if (nonPlatformCustomerService?.length > 0) {
      history.push(PageEnum.SERVICE_LIST);
    } else {
      gotoCustomerService();
    }
  };


  return {
    sendImMessage,
    imMessage,
    customerServiceMessageUNREAD,
    currentSocketMessage,
    setImMessage,
    imSocketState: readyState,
    connect: connectSocket,
    isSub,
    disconnectSocket,
    readMessageId,
    setReadMessageId,
    c2cServiceMessageUNREAD,
    latestNews,
    lastMessage,
    rechargeServiceMessage,
    gotoCustomerService,
    gotoC2CCustomerService,
    imGroupListReq,
    imGroupList,
    gotoGlobalCustomerService,
    setImGroupList,
    unReadCount,
    anonymousLastMsg,
    anonymousServiceMessageUNREAD,
    imGroupListLoading
  };
};
